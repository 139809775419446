import { Form, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { error as errorAlert, infoAlert, successAlert, warningAlert } from '../../components/toastr';
import { SubmitButton, CancelButton } from '../../components/btns';
import { DateSelector } from '../../components/datetimepicker';
import moment from 'moment';
import { getEvent, getEvents } from '../../resources/api/events';
import SelectInput from '../../components/select';
import cur from '../../components/currency';
import { createPayment, updatePayment } from '../../resources/api/payments';
import { useNavigate } from 'react-router-dom';
import { assortedPaymentMethods, paymentMethods } from '../../assets/constants';
import { UCWords } from '../../components/resources';
import { getAccounts } from '../../resources/api/accounts';
import { useMemo } from 'react';

const { Control, Control: { Feedback }, Label } = Form;

/**
 * handle page for creating payment
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('../../resources/api/payments').PaymentObject} props.details
 * @param {(details: import('../../resources/api/payments').PaymentObject) => void} props.onSubmit
 */
const PaymentForm = ({ details: paymentObject, action, onSubmit }) => {


    const [details, setDetails] = useState(paymentObject);
    const [prods, setProds] = useState([]);

    const [events, setEvents] = useState([]);
    const [accounts, setAccounts] = useState([]);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    const changeProdAmount = (amount, idx) => {
        const _prods = [...prods];

        let _prod = _prods[idx];
        _prod = { ..._prod, amount, hasChanged: 1 };
        _prods.splice(idx, 1, _prod);
        setProds(_prods);
    }

    const displayAccounts = useMemo(() => {

        const _types = assortedPaymentMethods[details.method] || [];

        return accounts.filter(a => _types.indexOf(a.account_type) !== -1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details.method, JSON.stringify(accounts)])

    useEffect(() => {
        infoAlert("Loading accounts...");

        getAccounts()
            .then(({ accounts }) => {
                if (accounts.length === 0)
                    warningAlert("No accounts available. Please add an account first.");
                else
                    successAlert("Loaded all accounts.");

                setAccounts(accounts);
            })
            .catch(errorAlert)
    }, [])

    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        let promise;

        if (action === "create") {
            promise = createPayment({
                ...details,
                items: prods.filter(p => !!parseInt(p.amount))
            });
        } else {
            promise = updatePayment({
                ...details,
                new_items: prods.filter(p => (!p.id && !!parseInt(p.amount))),
                updated_items: prods.filter(p => (!!p.id && p.hasChanged)),
                deleted_items: prods.filter(p => (!!p.id && !parseInt(p.amount))).map(p => p.id)
            }, details.id);
        }

        promise
            .then(({ payment, message }) => {
                successAlert(message);
                onSubmit(payment);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    useEffect(() => {
        getEvents(1)
            .then(({ events }) => {
                setEvents(
                    events
                        // .filter(e => (e.balance > 0))
                        .map(e => ({
                            ...e,
                            title: e.reference,
                            description: `${e.client} - ${moment(e.event_date).format("ddd DD MMMM YYYY")}`
                        })));
            })
            .catch(e => errorAlert(e));
    }, [])

    useEffect(() => {

        if (!details.event_id) {
            setProds([]);
            return;
        }

        getEvent(details.event_id, ['products'])
            .then(({ event }) => {

                let _products = event.products.map(p => ({
                    event_product_id: p.id,
                    product: p.product,
                    total_amount: p.total_amount,
                    balance: p.balance,
                    amount: ""
                }));

                // for edit actions and items passed are available i.e payment has some items before.
                if (details.items.length > 0) {
                    _products = _products.map(p => {

                        let _p = details.items.find(i => (i.event_product_id === p.event_product_id));

                        if (!_p) return p;

                        return ({ ...p, id: _p.id, amount: _p.amount, balance: (p.balance + _p.amount) });
                    })
                }

                setProds(_products);
            })
            .catch(e => errorAlert(`Could not get products for the selected event. ${e}`));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details.event_id])

    useEffect(() => {

        if (!details.event_id || events.length === 0) {
            setDetails(d => ({ ...d, client_id: "" }));
            return;
        }

        setDetails(d => ({ ...d, client_id: events.find(e => e.id === details.event_id)?.client_id || "" }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(events), details.event_id])


    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Details</h4>
            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Event</Label>
                    <SelectInput
                        value={[details.event_id]}
                        placeholder='-'
                        onChange={([id]) => setDetails(d => ({
                            ...d,
                            event_id: id || ""
                        }))}
                        required
                        maxItems={1}
                        options={events}
                        errorText="An event must be chosen inorder to get the list of products to pay against."
                    />
                </Col>
            </Row>
            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Payment Date</Label>
                    <DateSelector
                        value={details.payment_date}
                        onChange={val => setDetails(d => ({ ...d, payment_date: val }))}
                        placeholder="-"
                        required={true}
                        // minDate={moment().subtract(1, "month").format("YYYY-MM-DD")}
                        minDate="2021-01-01"
                    />
                    <Feedback type="invalid">
                        The payment date should be defined.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Paid By</Label>
                    <Control
                        value={details.payee}
                        onChange={e => setDetails({ ...details, payee: e.currentTarget.value })}
                        placeholder="-"
                        required={true}
                    />
                    <Feedback type="invalid">
                        A valid name must be provided
                    </Feedback>
                </Col>
            </Row>
            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Payment Method</Label>
                    <Form.Select
                        as="select"
                        value={details.method}
                        onChange={e => setDetails({ ...details, method: e.currentTarget.value, account_id: "" })}
                        required
                    >
                        <option value="">-</option>
                        {paymentMethods.map(c => <option key={c} value={c}>{UCWords(c).replace(/-/g, " ")}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        You must select a method for payment for this expense.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Payment Account</Label>
                    <Form.Select
                        as="select"
                        value={details.account_id}
                        onChange={e => setDetails({ ...details, account_id: e.currentTarget.value })}
                        required
                    >
                        <option value="">-</option>
                        {displayAccounts.map(c => <option key={c.id} value={c.id}>{c.title}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        You must select the account to which payment is being made.
                    </Feedback>
                    {!details.method &&
                        <Form.Text muted>
                            First select a payment method.
                        </Form.Text>
                    }
                </Col>
            </Row>

            <h4 className="form-section-label">Products</h4>

            {prods.map((p, idx) => (
                <Row key={idx} className="my-3 my-sm-1 border-bottom pb-3 g-2">
                    <Col md={5} sm={12}>
                        <Label className="form-field-title">Product</Label>
                        <Control
                            readOnly
                            value={p.product}
                        />

                    </Col>
                    <Col md={3} sm={6}>
                        <Label className="form-field-title">Balance</Label>
                        <Control
                            readOnly
                            value={cur(p.balance, 0).format()}
                        />

                    </Col>
                    <Col md={4} sm={6}>
                        <Label className="form-field-title">Amount Paid</Label>
                        <Control
                            type="number"
                            value={p.amount}
                            min={500}
                            max={p.balance}
                            onChange={e => changeProdAmount(e.currentTarget.value, idx)}
                            placeholder="Amount"
                            step={0.05}
                        />
                        <Control.Feedback type="invalid">
                            Amount must be less than {cur(p.balance, 0).format()} but more than 500
                        </Control.Feedback>

                    </Col>
                </Row>

            ))}


            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Payment" : "Create Payment"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting} onClick={() => navigate(-1)}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form>
    )
}


export default PaymentForm;