import { APIURL, getData, postData } from "../fetch";

/**
 * @typedef {{
*  id: string 
*  contact_id: string 
*  permit_no: string
*  year_started: number 
*  login_code: string
*  years_of_experience: number
*  permit_expiry: string
*  display_name: string
*  other_names: string
*  last_name: string
*  email: string
*  telephone: string
* }} DriverObject
*/


/**
 * 
 * @param {DriverObject} data 
 * @returns {Promise<{code: number, message: string, driver: DriverObject}>}
 */
const createDriver = (data) => {
    return postData({
        url: `${APIURL}/drivers`,
        data
    })
}

/**
 * 
 * @param {DriverObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, driver: DriverObject}>}
 */
const updateDriver = (data, id) => {
    return postData({
        url: `${APIURL}/drivers/${id}`,
        data
    })
}


/**
 * Update driver details
 * @param {StaffObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, driver: StaffObject}>}
 */
const updateDriverContact = (data, id) => {
    return postData({
        url: `${APIURL}/drivers/${id}/contact`,
        data
    })
}


/**
 * 
 * @param {DriverObject} data 
 * @returns {Promise<{code: number, message: string, drivers: DriverObject[]}>}
 */
const getDrivers = () => getData({ url: `${APIURL}/drivers` })

/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, driver: DriverObject}>}
 */
const getDriver = (id, includes = null) => {

    const obj = { url: `${APIURL}/drivers/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}



export {
    createDriver,
    getDriver,
    getDrivers,
    updateDriver,
    updateDriverContact
}