import { APIURL, postData, deleleData, getData } from "../fetch";

/**
 * 
 * @typedef {{
 * bills: import("./bills").BillObject[]
 * payments: import("./bill-payments").PaymentObject[]
 * expenses: import("./expenses").ExpenseObject[]
 * }} StatementObject 
 */


/**
 * 
 * @typedef {{
 * id: string,
 * code: string
 * title: string,
 * address: string
 * category: string
 * payables: number
 * bills: import("./bills").BillObject[]
 * date_added: string
 * added_by: string
 * contact_persons: ContactPersonObject[]
 * }} VendorObject 
 */


/**
 * 
 * @typedef {{
 *  id: string 
 *  vendor_id: string
 *  contact_id: string
 *  display_name: string 
 *  other_names: string 
 *  last_name: string 
 *  email: string
 *  telephone: string
 *  position: string
 * }} ContactPersonObject
 */



/**
 * 
 * @param {VendorObject} data 
 * @returns {Promise<{code: number, message: string, vendor: VendorObject}>}
 */
const createVendor = (data) => {
    return postData({
        url: `${APIURL}/vendors`,
        data
    })
}

/**
 * 
 * @param {VendorObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, vendor: VendorObject}>}
 */
const updateVendor = (data, id) => {
    return postData({
        url: `${APIURL}/vendors/${id}`,
        data
    })
}

/**
 * 
 * @param {VendorObject} data 
 * @returns {Promise<{code: number, message: string, vendors: VendorObject[]}>}
 */
const getVendors = () => getData({ url: `${APIURL}/vendors` })


/**
 * 
 * @param {VendorObject} data 
 * @returns {Promise<{code: number, message: string, vendors: VendorObject[]}>}
 */
const getDebtors = () => getData({ url: `${APIURL}/vendors/debtors` })

/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, vendor: VendorObject}>}
 */
const getVendor = (id, includes = null) => {

    const obj = { url: `${APIURL}/vendors/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {ContactPersonObject} data 
 * @returns {Promise<{code: number, message: string, contact_person: ContactPersonObject}>}
 */
const createVendorContact = (data) => {
    return postData({
        url: `${APIURL}/vendor-contacts`,
        data
    })
}

/**
 * 
 * @param {ContactPersonObject} data 
 * @returns {Promise<{code: number, message: string, contact_persons: ContactPersonObject[]}>}
 */
const getVendorContacts = id => getData({ url: `${APIURL}/vendor-contacts?vendor_id=${id}` })

/**
 * 
 * @param {string} id 
 * @returns {Promise<{code: number, message: string, statement: StatementObject}>}
 */
const getVendorStatement = id => getData({ url: `${APIURL}/vendors/${id}/statement` })

/**
 * 
 * @param {ContactPersonObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, contact_person: ContactPersonObject}>}
 */
const updateVendorContact = (data, id) => {
    return postData({
        url: `${APIURL}/vendor-contacts/${id}`,
        data
    })
}

/**
 * 
 * @param {ContactPersonObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, contact_person: ContactPersonObject}>}
 */
const updateContactPosition = (data, id) => {
    return postData({
        url: `${APIURL}/vendor-contacts/${id}/position`,
        data
    })
}

/**
 * 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, contact_person: ContactPersonObject}>}
 */
const deleteVendorContact = id => deleleData({ url: `${APIURL}/vendor-contacts/${id}` })


const printStatement = (data) => postData({
    url: `${APIURL}/vendors/statement`,
    data
});



/**
 * Delete a vendor
 * @param {string} id ID of the event
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteVendor = id => deleleData({ url: `${APIURL}/vendors/${id}` });


export {
    createVendor,
    getVendor,
    getVendors,
    updateVendor,
    createVendorContact,
    updateVendorContact,
    deleteVendorContact,
    getVendorContacts,
    getVendorStatement,
    printStatement,
    getDebtors,
    deleteVendor,
    updateContactPosition
}