import { Form, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import { errorAlert, successAlert } from './../../components/toastr';
import { SubmitButton, CancelButton } from './../../components/btns';
import { useNavigate } from 'react-router-dom';
import { createVehicle, updateVehicle } from '../../resources/api/vehicles';
import { DateSelector } from '../../components/datetimepicker';

const { Control, Control: { Feedback }, Label } = Form;

/**
 * handle page for creating trip
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('../../resources/api/vehicles').VehicleObject} props.details
 * @param {(details: import('../../resources/api/vehicles').VehicleObject) => void} props.onSubmit
 */
const VehicleForm = ({ details: VehicleObject, action, onSubmit }) => {


    const [details, setDetails] = useState(VehicleObject);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        let _data = { ...details, isExternal: details.isExternal ? 1 : 0 };

        const promise = (action === "edit") ? updateVehicle(_data, details.id) : createVehicle(_data);

        promise
            .then(({ vehicle, message }) => {
                successAlert(message);
                onSubmit(vehicle);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Details</h4>
            <Row className='g-1'>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Reg No</Label>
                    <Control
                        value={details.reg_no}
                        minLength={8}
                        maxLength={60}
                        onChange={e => setDetails({ ...details, reg_no: e.currentTarget.value })}
                        placeholder="UAZ 230B"
                        pattern="^[A-Z]{3}\s[0-9]{3}[A-Z]{1}$"
                        required={true}
                    />
                    <Feedback type="invalid">
                        Registration number must be valid
                    </Feedback>
                </Col>
                <Col sm={6} className='my-1'>
                    <Label className="form-field-title">Ownership</Label>
                    {details.isExternal &&
                        <Control
                            value={details.owning_company}
                            onChange={e => setDetails({ ...details, owning_company: e.currentTarget.value })}
                            required={details.isExternal}
                        />
                    }
                    <Form.Check
                        className=""
                        type="switch"
                        id="custom-switch"
                        checked={!details.isExternal}
                        onChange={() => setDetails(d => ({ ...d, isExternal: !d.isExternal }))}
                        label={details.isExternal ? "Vehicle is rented" : "Vehicle is owned by us."}
                    />
                </Col>
            </Row>
            <Row className='g-1'>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Manufacturer</Label>
                    <Control
                        value={details.manufacturer}
                        onChange={e => setDetails({ ...details, manufacturer: e.currentTarget.value })}
                        required={true}
                    />
                    <Feedback type="invalid">
                        This field is required
                    </Feedback>
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Model</Label>
                    <Control
                        value={details.model}
                        onChange={e => setDetails({ ...details, model: e.currentTarget.value })}
                        required={true}
                    />
                    <Feedback type="invalid">
                        This field is required
                    </Feedback>
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Year of Manufacture</Label>
                    <Control
                        value={details.manufacture_year}
                        onChange={e => setDetails({ ...details, manufacture_year: e.currentTarget.value })}
                        required={true}
                    />
                    <Feedback type="invalid">
                        This field is required
                    </Feedback>
                </Col>
            </Row>
            <Row className='g-1'>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Date of Purchase</Label>
                    <DateSelector
                        value={details.purchase_date}
                        onChange={purchase_date => setDetails(d => ({ ...d, purchase_date }))}
                        required={!details.isExternal}
                        maxDate={0}
                    />
                    <Feedback type="invalid">
                        This field is required
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Purchase Price</Label>
                    <Control
                        type="number"
                        step={500}
                        value={details.purchase_price}
                        onChange={e => setDetails({ ...details, purchase_price: e.currentTarget.value })}
                        required={!details.isExternal}
                    />
                    <Feedback type="invalid">
                        Required & must be a multiple of 500.
                    </Feedback>
                </Col>
            </Row>

            <Row className='g-1'>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Vehicle" : "Create Vehicle"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting} onClick={() => navigate(-1)}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form >
    )
}


export default VehicleForm;