import { APIURL, getData, postData } from "../fetch";

/**
 * 
 * @typedef {{
 * id: string,
 * reg_no: string,
 * manufacturer: string 
 * model: string
 * manufacture_year: number
 * purchase_date: string
 * purchase_price: string
 * driver_name: string
 * isExternal: bool 
 * owning_company: number 
 * date_added: string
 * added_by: string
 * }} VehicleObject 
 */

/**
 * 
 * @param {VehicleObject} data 
 * @returns {Promise<{code: number, message: string, vehicle: VehicleObject}>}
 */
const createVehicle = (data) => {
    return postData({
        url: `${APIURL}/vehicles`,
        data
    })
}

/**
 * 
 * @param {VehicleObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, vehicle: VehicleObject}>}
 */
const updateVehicle = (data, id) => {
    return postData({
        url: `${APIURL}/vehicles/${id}`,
        data
    })
}

/**
 * 
 * @returns {Promise<{code: number, message: string, vehicles: VehicleObject[]}>}
 */
const getVehicles = () => getData({ url: `${APIURL}/vehicles` })

/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, vehicle: VehicleObject}>}
 */
const getVehicle = (id, includes = null) => {

    const obj = { url: `${APIURL}/vehicles/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


export {
    createVehicle,
    getVehicle,
    getVehicles,
    updateVehicle
}