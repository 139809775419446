import { APIURL, getData, postData } from "../fetch";

/**
 * 
 * @typedef {{
 * id: string,
 * title: string,
 * telephone: string
 * email: string 
 * no_events: number 
 * total_revenue: number 
 * unpaid_revenue: number 
 * contact_person: string 
 * date_added: string
 * added_by: string
 * events: import("./events").EventObject
 * }} ClientObject 
 */

/**
 * 
 * @param {ClientObject} data 
 * @returns {Promise<{code: number, message: string, client: ClientObject}>}
 */
const createClient = (data) => {
    return postData({
        url: `${APIURL}/clients`,
        data
    })
}

/**
 * 
 * @param {ClientObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, client: ClientObject}>}
 */
const updateClient = (data, id) => {
    return postData({
        url: `${APIURL}/clients/${id}`,
        data
    })
}

/**
 * 
 * @param {ClientObject} data 
 * @returns {Promise<{code: number, message: string, clients: ClientObject[]}>}
 */
const getClients = () => getData({ url: `${APIURL}/clients` })

/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, client: ClientObject}>}
 */
const getClient = (id, includes = null) => {

    const obj = { url: `${APIURL}/clients/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @returns {Promise<{code: number, message: string, statement: StatementObject}>}
 */
const getClientStatement = id => getData({ url: `${APIURL}/clients/${id}/statement` })
/**
 * 
 * @param {string} id 
 * @returns {Promise<{code: number, message: string, items: {}[]}}>}
 */
const generateStatement = id => getData({ url: `${APIURL}/clients/${id}/generate-statement` })


const printStatement = (data) => postData({
    url: `${APIURL}/clients/statement`,
    data
});


export {
    createClient,
    getClient,
    getClients,
    updateClient,
    getClientStatement,
    generateStatement,
    printStatement
    
}