import { useState, useEffect } from 'react';

/**
 * Returns the screen type;
 * @returns {"mobile" | "desktop"}
 */
const useScreenType = () => {

    const [type, setType] = useState('desktop');

    useEffect(() => {
        const changeWidth = () => {
            const width = window.innerWidth;
            setType(width > 767 ? 'desktop' : 'mobile')
        };
        changeWidth();
        window.addEventListener('resize', changeWidth);
        return () => window.removeEventListener('resize', changeWidth);
    })

    return type;
}

/**
 * Returns the screen type;
 * @returns {boolean}
 */
const useVisibility = () => {

    const [type, setType] = useState(false);

    useEffect(() => {
        const isVisible = () => {
            if (document.visibilityState === "visible") setType(true);
            else setType(false);
        };
        isVisible();
        document.addEventListener('visibilitychange', isVisible);
        return () => window.removeEventListener('resize', isVisible);
    })

    return type;
}


/**
 * 
 * @param {{[any: string]: string}[]} items 
 * @param {number} [loadStep] 
 * @param {"reverse" | "normal"} order 
 * @return {[display_items, loadMore: () => void, showMore: boolean]}
 */
const useLoadMore = (items, loadStep = 3, order = "reverse") => {

    const [display_no, setDisplayNo] = useState(0);
    const [display_items, setDisplayItems] = useState([]);

    useEffect(() => {
        setDisplayNo(items.length < loadStep ? items.length : loadStep);
    }, [items.length, loadStep])

    useEffect(() => {

        const __items = [];

        if (order === "reverse") {

            for (let i = (items.length - display_no); i < items.length; i++) {
                __items.push(items[i]);
            }
        } else {
            for (let i = 0; i < display_no; i++) {
                __items.push(items[i]);
            }
        }

        setDisplayItems(__items);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display_no, order])

    return ([
        display_items,
        () => setDisplayNo(d => ((d + loadStep) > items.length) ? items.length : (d + loadStep)),
        (display_no < items.length)
    ])

}


export {
    useScreenType,
    useLoadMore,
    useVisibility
};