import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Nav from '../../components/breadcrumb';
import { PageLoading } from '../../components/loading';
import PageHeader, { Title } from './../../components/pageheader';
import EventForm from './event-form';

const eventObject = {
    category: "", client: "", location: "", event_date: "", colour: "", end_date: ""
}

const NewEvent = () => {

    const nav_items = [
        { title: 'Events', href: '/app/events' },
        { title: 'New Event' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        navigate(`/app/events/${details.id}`);
    }

    const [searchParams] = useSearchParams();
    const [details, setDetails] = useState(eventObject);
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        let event_date = searchParams.get('date') || "", client_id = searchParams.get('client') || "";
        const _details = { ...details, event_date, client_id };

        setDetails(_details);
        setLoaded(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!loaded) return <PageLoading />


    return (
        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <EventForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/events").EventObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import("../../resources/api/events").EventObject>>} props.setDetails 
 */
const EditEvent = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Events', href: '/app/events' },
        { title: details.reference, href: `/app/events/${details.id}` },
        { title: 'Edit Event Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/events/${details.id}`);
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>{details.reference}</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <EventForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewEvent, EditEvent };