import moment from 'moment';
import { useState, useEffect, useContext } from 'react';
import { errorAlert, infoAlert, successAlert } from './../../components/toastr';
import { Accordion, Badge, Button, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { payrollTypes } from '../../assets/constants';
import Nav from '../../components/breadcrumb';
import cur from '../../components/currency';
import PageHeader, { ActionBar, ActionButton, ButtonToolbar, Title } from '../../components/pageheader';
import { groupBy, sortFunction } from '../../components/resources';
import { getInitiatedPayments, processPayments } from '../../resources/api/payroll';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import { APIURL } from '../../resources/fetch';


/**
 * handle for /app/expenses/:expenseid
 * @param {Object} props
 * 
 */
const ApprovePayroll = () => {

    const nav_items = [
        { title: 'Payroll', href: '/app/payroll' },
        { title: "Approve" }
    ];

    const { profile } = useContext(AppUserContext);

    const [workers, setWorkers] = useState([]);
    const [changed, setChanged] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (profile.permission_level > 2) {
            return errorAlert("You do not have permission to process payroll");
        }

        infoAlert("Loading payments for approval...");
        getInitiatedPayments()
            .then(({ payments }) => {
                let workers = groupBy(payments.filter(r => !r.isProcessed), "staff_id"),
                    items = [];

                if (payments.length === 0) infoAlert("There are no payments for approval.", "Nothing to Approve");

                for (let id in workers) {
                    items.push({
                        id: id,
                        display_name: workers[id][0].display_name,
                        total_amount: workers[id].reduce((p, c) => p + parseFloat(c.amount), 0),
                        items: workers[id].sort((a, b) => sortFunction(a, b, "date_added", "asc")),
                        allApproved: false
                    });
                }
                setWorkers(items.sort((a, b) => sortFunction(a, b, "display_name")));
            })
    }, [profile.permission_level])

    const approveWorker = id => {
        const _workers = [...workers],
            _wi = _workers.findIndex(w => w.id === id),
            _w = _workers[_wi];

        _workers[_wi] = {
            ..._w,
            items: _w.items.map(i => ({ ...i, isProcessed: true })),
            allApproved: true
        };

        setWorkers(_workers);
        if (!changed) setChanged(true);
    }

    const approveItem = (worker_id, item_id) => {
        const _workers = [...workers],
            _wi = _workers.findIndex(w => w.id === worker_id),
            _w = _workers[_wi];


        let allApproved = true;

        _workers[_wi] = {
            ..._w,
            items: _w.items.map(i => {

                let approved = (i.id === item_id) ? true : i.isProcessed;
                if (allApproved) allApproved = approved;

                return { ...i, isProcessed: approved }
            }),
            allApproved
        };

        setWorkers(_workers);
        if (!changed) setChanged(true);
    }

    const resetStatus = () => {

        setWorkers([...workers].map(w => ({
            ...w,
            items: w.items.map(i => ({ ...i, isProcessed: false })),
            allApproved: false
        })));

        setChanged(false);

    }

    const approveAllWorkers = () => {

        setWorkers([...workers].map(w => ({
            ...w,
            items: w.items.map(i => ({ ...i, isProcessed: true })),
            allApproved: true
        })));

        setChanged(true);

    }

    const handleSaveChanges = () => {
        let ids = [];

        workers
            .forEach(w => w.items
                .forEach(i => {
                    if (i.isProcessed) ids.push(i.id)
                }))

        infoAlert("Approving payments ...");

        processPayments(ids)
            .then(({ message }) => {
                successAlert(message);
                navigate("/app/payroll");
            })
            .catch(e => errorAlert(e))

    }

    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to approve payroll payments.</ErrorLoading>
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>
                    Approve Payments
                </Title>
                <ActionBar>
                    <ButtonToolbar>
                        <ActionButton title="Download PDF"
                            href={`${APIURL}/payroll-payments/unapproved/pdf`}
                            isExternalLink
                        >
                            <i className="fas fa-file-pdf" />
                        </ActionButton>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <ActionButton onClick={approveAllWorkers}>
                            <i className='fas fa-check me-1' />Approve All
                        </ActionButton>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-900">
                <Accordion flush>
                    {
                        workers.map(_w => (
                            <Accordion.Item key={_w.id} eventKey={_w.id}>
                                <Accordion.Header>
                                    <h5 className="w-100 fw-normal">{_w.display_name}</h5>
                                    <span className="me-2">
                                        {cur(_w.total_amount, 0).format()}
                                        {_w.allApproved && <>
                                            <br />
                                            <Badge pill bg="success">approved</Badge>
                                        </>}
                                    </span>
                                </Accordion.Header>
                                <Accordion.Body>

                                    <Table hover responsive="md" style={{ minWidth: "500px" }}>

                                        <thead>
                                            <tr>
                                                <th>Reference</th>
                                                <th>Type</th>
                                                <th>Date</th>
                                                <th className="text-end">Amount</th>
                                                <th>...</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {_w.items.map(w => (
                                                <tr>
                                                    <td>
                                                        <Link to={`/app/staff-payments/${w.payroll_payment_id}`}>{w.reference}</Link>
                                                    </td>
                                                    <td>{payrollTypes[w.payroll_type].title}</td>
                                                    <td>{moment(w.date_added).format("DD MMM YYYY")}</td>
                                                    <td className="text-end">{cur(w.amount).format()}</td>
                                                    <td>
                                                        {!w.isProcessed &&
                                                            <Button size="sm" variant="link" onClick={() => approveItem(_w.id, w.id)}>
                                                                <i className='fas fa-check-circle text-success' />
                                                            </Button>}

                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>

                                    <div className='text-end'>
                                        {!_w.allApproved &&
                                            <Button
                                                onClick={(() => approveWorker(_w.id))}
                                                size="sm"
                                                variant="link"
                                                className="rounded-pill text-success"
                                            >
                                                <i className='fas fa-check me-1' />
                                                Approve All
                                            </Button>}

                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                        ))
                    }


                </Accordion>

                {changed &&
                    <div className='mt-3 mt-sm-5 text-end'>
                        <Button
                            variant="outline-success"
                            className="rounded-pill m-1"
                            onClick={handleSaveChanges}
                        >
                            <i className='fas fa-check-circle me-2' />
                            Save Changes
                        </Button>
                        <Button
                            variant="outline-danger"
                            className="rounded-pill m-1"
                            onClick={resetStatus}
                        >
                            <i className='fas fa-exclamation-circle me-2' />
                            Reset
                        </Button>
                    </div>
                }

            </div>
        </>
    )
}




export default ApprovePayroll;