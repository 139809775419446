import moment from "moment";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Table, Row, Col, Accordion } from "react-bootstrap";
import { Routes, Route, Link } from "react-router-dom";
import { AppUserContext } from "../../App";
import Nav from '../../components/breadcrumb';
import cur from "../../components/currency";
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { sortFunction, UCWords } from "../../components/resources";
import { getLabour } from "../../resources/api/events";
import { EditLabour, NewLabour } from "./labour-manipulation";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/events").EventObject} props.event_details
 */
const LabourDetails = ({ event_details }) => {

    const nav_items = [
        { title: 'Events', href: "/app/events" },
        { title: event_details.reference, href: `/app/events/${event_details.id}` },
        { title: "Labour" }
    ]

    const [payments, setDetails] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setLoaded(false);
        setError(null);

        getLabour(event_details.id)
            .then(({ payments }) => {
                payments.sort((a, b) => sortFunction(a, b, 'date_added'));
                setDetails(payments);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [event_details.id])

    if (!isLoaded) {
        return <PageLoading>Loading event labour details...</PageLoading>
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>



    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>{event_details.reference} - Labour</Title>
                <ActionBar>

                    <ButtonToolbar>
                        <Btn href={`/app/events/${event_details.id}/labour/new`} title="New Schedule">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-900">
                {(payments.length === 0) && <h5>No labour details have been input yet</h5>}
                <Accordion>
                    {payments.map((e, i) => (
                        <Accordion.Item eventKey={i} key={i}>
                            <Accordion.Header>
                                <h5 className="w-100">
                                    {UCWords(e.activity)}
                                    {!e.workers.map(w => (w.isPaid || w.isApproved)).some(f => (f === true)) &&
                                        <Link to={`/app/events/${event_details.id}/labour/${e.id}/edit`}>
                                            <i className="fas fa-pencil-alt mx-2" />
                                        </Link>
                                    }
                                </h5>
                                <span className="me-2">{cur(e.total_amount, 0).format()}</span>
                            </Accordion.Header>
                            <Accordion.Body>

                                <Row className="g-1">
                                    <Col sm={6} md={4} lg={3}>
                                        <small className="fw-bold">Date</small>
                                        <p>{moment(e.activity_date).format("DD MMMM YYYY")}</p>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <small className="fw-bold">Base Rate</small>
                                        <p>{cur(e.base_rate, 0).format()}</p>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <small className="fw-bold">Overtime Rate</small>
                                        <p>{cur(e.overtime_rate, 0).format()} per hr</p>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <small className="fw-bold"> Climbing Rate</small>
                                        <p>{cur(e.risk_factor, 0).format()}</p>
                                    </Col>
                                </Row>

                                <Table hover responsive="md">

                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th className="text-center">Overtime</th>
                                            <th className="text-center" style={{ whiteSpace: "nowrap" }}>Is Climbing</th>
                                            <th className="text-end">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {e.workers.map((w, iw) => (
                                            <tr key={iw}>
                                                <td>{w.display_name}</td>
                                                <td className="text-center">{w.overtime_hrs}</td>
                                                <td className="text-center">
                                                    {w.isRisk ?
                                                        <i className="fas fa-check-circle text-danger" /> :
                                                        <i className="fas fa-times-circle text-success" />
                                                    }

                                                </td>
                                                <td className="text-end">{cur(w.amount, 0).format()}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>

                    ))}
                </Accordion>
            </div>

        </>
    )

}




const EventLabour = ({ details: event_details }) => {


    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to edit/view events' labour.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/:labourid/edit" element={<EditLabour event_details={event_details} />} />
            <Route path="/new" element={<NewLabour event_details={event_details} />} />
            <Route path="/" element={<LabourDetails event_details={event_details} />} />
        </Routes>
    )

}


export default EventLabour;