import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Form, Table, Card, Row, Col } from 'react-bootstrap';
import { SortButton } from '../../components/btns';
import { useState, useEffect } from 'react';
import PagePagination, { usePagination } from '../../components/pagination';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { UCWords, sortFunction } from '../../components/resources';
import { useScreenType } from '../../components/custom-hooks';
import { ErrorLoading, PageLoading } from '../../components/loading';
import { getMaintenances } from '../../resources/api/maintenance';
import cur from '../../components/currency';
/**
 * View table items on screens larger than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/maintenance').MaintenanceObject[]
 * currentPage: number
 * noPageItems: number
 * }} param0 
 */
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems }) => {
    return (
        <Table responsive hover style={{ minWidth: '700px' }}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Ref <SortButton field="reference" {...srt} handleSort={handleSort} /></th>
                    <th>Vehicle <SortButton field="reg_no" {...srt} handleSort={handleSort} /></th>
                    <th>Type <SortButton field="type" {...srt} handleSort={handleSort} /></th>
                    <th>Date <SortButton field="maintenance_date" {...srt} handleSort={handleSort} /></th>
                    <th style={{ textAlign: 'right' }}>Amount <SortButton field="total_cost" {...srt} handleSort={handleSort} /></th>
                    <th>Signed On <SortButton field="signoff_date" {...srt} handleSort={handleSort} /></th>
                    <th>Paid<SortButton field="isPaid" {...srt} handleSort={handleSort} /></th>
                </tr>

            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td><Link to={`/app/maintenance/${e.id}`}>{e.reference}</Link></td>
                        <td><Link to={`/app/vehicles/${e.vehicle_id}`}>{e.reg_no}</Link></td>
                        <td>{UCWords(e.type)}</td>
                        <td>{moment(e.maintenance_date).format("DD MMM YYYY")}</td>
                        <td style={{ textAlign: 'right' }}>{cur(e.total_cost, 0).format()}</td>
                        <td>{!!e.signoff_date ? moment(e.signoff_date).format("DD MMM YYYY") : "n/a"}</td>
                        <td>{e.isPaid ? <Link to={`/app/expenses/${e.expense_id}`}>Expense</Link> : "No"}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

/**
 * view items on screens smaller than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/maintenance').MaintenanceObject[]
 * }} param0 
 */
const ViewMobile = ({ srt, handleSort, items }) => {
    return (
        <div className="my-3">
            <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Reference <SortButton field="reference" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Vehicle <SortButton field="reg_no" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Date <SortButton field="maintenance_date" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Cost <SortButton field="total_cost" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Date Signed <SortButton field="signoff_date" {...srt} handleSort={handleSort} />
                </span>
            </div>
            {items.map((e, i) => (
                <Card className="my-2 shadow-sm" key={i}>
                    <Card.Body className="px-3">
                        <div className="d-flex justify-content-between mb-3">
                            <Link
                                to={`/app/maintenance/${e.id}`}
                                className="lead text-secondary font-weight-normal stretched-link"
                            >
                                {e.reference}
                            </Link>
                            <span className="text-muted">{UCWords(e.type)} | {moment(e.maintenance_date).format("DD MMM YYYY")}</span>
                        </div>

                        <div>
                            <span className="lead d-block">
                                <Link to={`/app/vehicles/${e.vehicle_id}`}>{e.reg_no}</Link>
                            </span>
                            <h4 className="h4">{cur(e.total_cost, 0).format()}</h4>
                            <span className="d-block">
                                {!!e.signoff_date ? <>Approved on {moment(e.signoff_date).format("DD MMM YYYY")}</> : "Not yet signed off"}
                            </span>
                            <span className="d-block">
                                {e.isPaid ? <Link to={`/app/expenses/${e.expense_id}`}>View Expense</Link> : "Not yet paid"}
                            </span>

                        </div>
                    </Card.Body>
                </Card>
            ))}
        </div>
    )
}


const ViewMaintenances = () => {

    const navItems = [{
        title: 'Maintenance'
    }];

    const [srt, setSrt] = useState({
        currentField: 'maintenance_date',
        currentOrder: 'desc'
    });

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'reference' });


    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    const screenType = useScreenType();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);



    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {

        let view_items = [...orginalItems];

        const v = search.val, f = search.field;
        if (v.length < 2 || f === "")
            view_items = [...view_items.filter(i => i[f].toLowerCase().indexOf(v.toLowerCase()) !== -1)];


        return setItems(view_items);
    }, [search.val, search.field, orginalItems]);


    /**
     * get the items
     */
    useEffect(() => {

        getMaintenances(true)
            .then(({ maintenances }) => {
                // expenses = expenses.map(e => ({ ...e, type: e.isBill ? "bill" : "expense" }));
                setOriginalItems(maintenances);
                setItems(maintenances);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [])


    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => sortFunction(a, b, srt.currentField, srt.currentOrder)

        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }


    if (!loaded) return <PageLoading>Loading all maintenance...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;


    return (
        <>
            <Nav items={navItems} />

            <PageHeader maxWidth="1000">
                <Title>Maintenance</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn href="/app/maintenance/new" title="New Record">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>

                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <Row xs={{ cols: "auto" }} className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0">
                    <Col>

                        <Form.Control placeholder="Search For" size="sm"
                            value={search.val}
                            onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                        />
                    </Col>
                    <Col>
                        <span className="mx-1">in</span>
                    </Col>
                    <Col>
                        <Form.Select as="select" size="sm"
                            value={search.field}
                            onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                        >
                            <option value="reference">Reference</option>
                            <option value="reg_no">Vehicle</option>
                            <option value="maintenance_date">Date</option>
                            <option value="type">Type</option>
                        </Form.Select>
                    </Col>

                </Row>

                {screenType === 'desktop' ?
                    <ViewDesktop srt={srt} handleSort={handleSort} items={displayItems} noPageItems={noPageItems} currentPage={PAGE} /> :
                    <ViewMobile srt={srt} handleSort={handleSort} items={displayItems} />
                }

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                        <Col className="me-2 text-nowrap">No Items:</Col>
                        <Col>
                            <Form.Select
                                value={noPageItems}
                                onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                                size="sm"
                                style={{ maxWidth: '100px' }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </Col>

                        <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                    </Row>
                    <PagePagination
                        page={PAGE}
                        no_pages={NOPAGES}
                        setPage={setPage}
                    />
                </div>
            </div>

        </>
    )
}


export default ViewMaintenances;