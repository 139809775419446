import { Form, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import { DateSelector } from '../../components/datetimepicker';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import moment from 'moment';
import { SubmitButton, CancelButton } from '../../components/btns';
import { BillItems } from '../../components/finances';
// import File from './../../components/files';
import SelectInput from '../../components/select';
import { useEffect } from 'react';
import { getVendors } from '../../resources/api/vendors';
import FileInput from '../../components/files';
import { createOrder, updateOrder } from '../../resources/api/orders';


const { Control: { Feedback }, Label } = Form;


/**
 * 
 * @param {Object} props
 * @param {"create"|"edit"} props.action 
 * @param {import('../../resources/api/orders').OrderObject} props.details 
 * @param {(details: import('../../resources/api/orders').OrderObject) => void} props.onSubmit 
 */
const OrderForm = ({ action = "create", details: orderObject, onSubmit }) => {

    const [details, setDetails] = useState(orderObject);
    const [items, setItems] = useState(orderObject.items || []);
    const [deleted_items, setDeletedItems] = useState([]);

    const [vendors, setVendors] = useState([]);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);


    useEffect(() => {

        infoAlert("Loading the vendor list...");

        getVendors()
            .then(({ vendors }) => {
                setVendors(vendors.map(c => ({ ...c, description: c.address })));
            })
            .catch(e => errorAlert(e))
    }, []);


    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        const data = {
            ...details,
            isTaxInclusive: details.isTaxInclusive ? 1 : 0,
            tax_percent: details.tax_percent || 0
        }

        let promise;

        if (action === "create") {
            data.items = items;
            promise = createOrder(data);
        } else {
            promise = updateOrder({
                ...data,
                new_items: items.filter(i => i.isNew),
                updated_items: items.filter(i => (!!i.id && i.hasChanged)),
                deleted_items
            }, data.id)
        }

        promise
            .then(({ order, message }) => {
                successAlert(message);
                onSubmit(order);
            })
            .catch(e => {
                setSubmitting(false);
                errorAlert(e);
            })

    }


    return (

        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label mt-5">Details</h4>

            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Vendor</Label>
                    {(action === "create") ?
                        <SelectInput
                            value={[details.vendor_id]}
                            onChange={([vid]) => setDetails(d => ({ ...d, vendor_id: vid || "" }))}
                            options={vendors}
                            placeholder='-'
                            errorText='Required'
                            maxItems={1}
                            required

                        />
                        :
                        <>
                            <Form.Control readOnly value={details.vendor.title} />
                            <Form.Text muted>You cannot edit the vendor</Form.Text>
                        </>
                    }

                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Date</Label>
                    <DateSelector
                        minDate={moment().add(-1, 'months').format('YYYY-MM-DD')}
                        maxDate={0}
                        value={details.order_date}
                        onChange={val => setDetails(d => ({ ...d, order_date: val }))}
                        required
                    />
                    <Feedback type="invalid">
                        You must select an expense date.
                    </Feedback>
                </Col>
            </Row>

            <h4 className="form-section-label mt-5">Items</h4>
            <Form.Check
                className=""
                type="switch"
                id="custom-switch"
                checked={details.isTaxInclusive}
                onChange={() => setDetails(d => ({ ...d, isTaxInclusive: !d.isTaxInclusive }))}
                label={details.isTaxInclusive ? "Prices are tax inclusive." : "Prices are not tax inclusive."}
            />

            <BillItems
                expenseitems={{ items, setItems }}
                deleteditems={{ deleted_items, setDeletedItems }}
                isTaxInclusive={details.isTaxInclusive}
            />



            <h4 className="form-section-label mt-5">Other Details</h4>


            <Row>
                <Col sm={6} className="my-2">
                    <Label className="form-field-title">Upload Quotation</Label>
                    <FileInput
                        maxFiles={1}
                        onChange={([fileid]) => setDetails(d => ({ ...d, quotation_file_id: fileid || "" }))}
                        value={[details.quotation_file_id]}
                        options={[]}
                        placeholder='-'
                        errorText='required'
                        // required={action === "create"}
                        allowUpload
                    />
                </Col>
            </Row>

            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        Submit
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>

        </Form >
    )
}


export default OrderForm;