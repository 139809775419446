
import { useState } from "react";
import { Button } from "react-bootstrap";
import { errorAlert, infoAlert, successAlert } from "../components/toastr";
import { urlB64ToUint8Array } from "../components/resources";
import { createSubscription, getPublicKey } from "../resources/api/pushsubscriptions";


const RequestPermission = ({ setRequestPermission }) => {

    const [isProcessing, setProcessing] = useState(false);

    const handleClick = async () => {

        setProcessing(true);



        // const result = await Notification.requestPermission();

        Notification.requestPermission((result) => {
            if (result !== 'granted') {
                infoAlert("Maybe next time. You can always change this in settings.");
                return setRequestPermission(true);
            }

            getPublicKey()
                .then(({ key: public_key }) => {

                    navigator.serviceWorker.getRegistration()
                        .then(registration => {
                            if (!registration) {
                                errorAlert("Oops. Something went wrong.");
                                return setRequestPermission(true);
                            }

                            registration.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: urlB64ToUint8Array(public_key)
                            })
                                .then(subscription => {

                                    const data = subscription.toJSON();
                                    const { endpoint } = data;
                                    const key = data.keys.p256dh;
                                    const token = data.keys.auth;

                                    createSubscription({
                                        endpoint,
                                        key,
                                        token
                                    })
                                        .then(({ message, subscription }) => {
                                            successAlert(message);
                                            window.localStorage.setItem('pushsubscriptionid', subscription.id);

                                        }).catch(e => errorAlert(`Failed to create the subscription. Error: ${e}`))
                                        .finally(() => setRequestPermission(true));
                                })
                        })
                })
                .catch(e => errorAlert(`Oops. Failed to get the public key. Error: ${e}`))
                .finally(() => setRequestPermission(true));



        })




    }

    const handleCancel = () => {
        window.localStorage.setItem('lastPushPrompted', Date.now());
        return setRequestPermission(true);
    }


    return (
        <div className="text-center max-500 py-5">

            <img src="/logo/logo512.png" id="logo" alt="Events UG Logo" style={{ height: "140px" }} />

            <h4 className="mt-3 mt-sm-5">Opt In for Push Notifications</h4>
            <p>
                We're excited to keep you in the loop with updates and important announcements from Events Management App. To ensure you don't miss out on anything essential, we'd like to send you notifications.
            </p>
            <h6>How It Works</h6>
            <p>
                We'll only send notifications that are relevant and valuable to you e.g., when there is a change invoice status, a new payment or change in job status.
            </p>
            <h6>Your Privacy Matters</h6>
            <p>
                We respect your privacy and won't spam you with unnecessary notifications. Your data is safe with us.
            </p>
            <h6>Ready to Stay Connected?</h6>
            <p>
                Tap "Allow" to start receiving notifications and make the most out of your Events Management App experience.
            </p>

            <div>
                <Button
                    className="m-1"
                    variant="danger"
                    disabled={isProcessing}
                    onClick={handleCancel}
                >
                    <i className="fas fa-times-circle me-2" />
                    Reject
                </Button>
                <Button className="m-1" variant="success" disabled={isProcessing} onClick={handleClick}>
                    <i className="fas fa-thumbs-up me-2" />
                    Allow
                </Button>
            </div>


        </div>
    )

}

export default RequestPermission;