import { useEffect, useState } from "react";
import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { getClientStatement } from "../../resources/api/clients";
import { ErrorLoading, PageLoading } from "../../components/loading";
import { sortFunction } from "../../components/resources";
import { Table } from "react-bootstrap";
import moment from "moment";
import cur from "../../components/currency";
import { Link } from "react-router-dom";

/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/clients").ClientObject} props.details
 */
const ViewStatement = ({ details }) => {

    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [totals, setTotals] = useState([]);

    useEffect(() => {

        setLoaded(false);
        setError();

        getClientStatement(details.id)
            .then(({ statement: { events, payments } }) => {
                const transactions = [
                    ...events.map(i => ({
                        id: i.id,
                        type: 'events',
                        reference: i.reference,
                        description: `${i.location} | ${i.category}`,
                        date: i.event_date,
                        debit: i.amount,
                        credit: 0
                    })),
                    ...payments.map(p => ({
                        id: p.id,
                        type: 'payments',
                        reference: p.reference,
                        description: p.method,
                        date: p.payment_date,
                        credit: p.total_amount,
                        debit: 0
                    }))
                ].sort((a, b) => sortFunction(a, b, 'date', "asc"));

                let running_balance = 0;

                setTransactions(transactions.map(t => ({ ...t, running_balance: (running_balance += (t.debit - t.credit)) })))

                setTotals(({
                    credit: transactions.reduce((p, c) => c.credit + p, 0),
                    debit: transactions.reduce((p, c) => c.debit + p, 0),
                }))
            })
            .catch(setError)
            .finally(() => setLoaded(true))

    }, [details.id])

    const nav_items = [
        { title: 'Clients', href: "/app/clients" },
        { title: details.title, href: `/app/clients/${details.id}` },
        { title: "Statement" }
    ]


    if (!isLoaded) return <PageLoading>Loading statement details...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.title} - Statement</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn href="/app/clients/new" title="New Client">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">

                <Table borderless size="sm" hover striped>
                    <colgroup>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "45%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "10%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Reference</th>
                            <th>Description</th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map(t => (
                            <tr key={t.id}>
                                <td>{moment(t.date).format('DD MMM YYYY')}</td>
                                <td><Link to={`/app/${t.type}/${t.id}`}>{t.reference}</Link></td>
                                <td>{t.description}</td>
                                <td>{!!t.debit && <>{cur(t.debit, 0).format()}</>}</td>
                                <td style={{ color: "var(--bs-danger)" }}>{!!t.credit && <>{cur(t.credit, 0).format()}</>}</td>
                                <td style={{ color: (t.running_balance < 0) ? "var(--bs-danger)" : "inherit" }}>{cur(t.running_balance, 0).format()}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={3} />
                            <th>{cur(totals.debit, 0).format()}</th>
                            <th>{cur(totals.credit, 0).format()}</th>
                            {/* <th /> */}
                            <th>{cur(totals.debit - totals.credit, 0).format()}</th>
                        </tr>
                    </tfoot>
                </Table>

            </div>

        </>
    )

}



export default ViewStatement;