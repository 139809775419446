import { APIURL, getData, postData } from "../fetch";


/**
 * @typedef {{
 *  id: string 
 *  expense_id: string
 *  unit_price: number
 *  quantity: number
 *  title: string
 *  tax_percent: number
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string,
 * }} ExpenseItemObject
 */

/**
 * @typedef {{
 *  id: string 
 *  reference: string
 *  user_reference: string
 *  vendor: string
 *  isBill: boolean
 *  expense_date: string
 *  due_date: string
 *  category: string
 *  method: string
 *  account_id: string 
 *  account: string 
 *  title: string
 *  description: string
 *  amount: number 
 *  tax_percent: number 
 *  pretax_amount: number 
 *  tax_amount: number 
 *  total_amount: number 
 *  isTaxInclusive: boolean 
 *  isBillable: boolean
 *  isItemized: boolean 
 *  receipt_file_id: string
 *  client_id: string
 *  markup: number
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string 
 *  status: string,
 *  wf_status: string
 *  vendor_id: string
 *  vendor_old: string
 *  items: ExpenseItemObject[]
 * }} ExpenseObject
 */


/**
 * 
 * @param {ExpenseObject} data 
 * @returns {Promise<{code: number, message: string, expense: ExpenseObject}>}
 */
const createExpense = data => {
    return postData({
        url: `${APIURL}/expenses`,
        data
    })
}

/**
 * 
 * @param {ExpenseObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, expense: ExpenseObject}>}
 */
const updateExpense = (data, id) => {
    return postData({
        url: `${APIURL}/expenses/${id}`,
        data
    })
}

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, expenses: ExpenseObject[]}>}
 */
const getExpenses = (getAll = false, conditions = null) => {

    const obj = { url: `${APIURL}/expenses` }

    if (getAll) obj.data = { expanded: 1 }
    if (conditions) obj.data = { ...(obj.data || {}), ...conditions }

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, expense: ExpenseObject}>}
 */
const getExpense = (id, includes = null) => {

    const obj = { url: `${APIURL}/expenses/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteExpense = id => getData({ url: `${APIURL}/expenses/${id}`, method: "DELETE" })


export {
    createExpense,
    getExpense,
    getExpenses,
    updateExpense,
    deleteExpense
}