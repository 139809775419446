import { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppUserContext } from '../App';
import { errorAlert } from '../components/toastr';
import { logoutUser } from '../resources/api/auth';

/**
 * 
 * @param {{
 * title: string
 * link: string
 * active: boolean
 * }} param0
 */
const NavItem = ({ title, link, children, active }) => (
    <li className={"nav-item" + (active ? " active" : "")}>
        <Link to={link} className="nav-link" title={title}>
            {children}
            <span className="nav-text">{title}</span>
        </Link>
    </li>
)

const MobileNavItem = ({ to, active, children }) => (
    <Link className={"flex-fill text-center nav-link" + (active ? " active" : "")} to={to}>
        {children}
    </Link>
)

/**
 * menu of the app
 */
const Menu = ({ logoutUser: signOut }) => {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isMenuExpanded, setMenuExpanded] = useState(true);
    const [active, setActive] = useState('');

    const { profile } = useContext(AppUserContext);
    const navigate = useNavigate();


    const location = useLocation();

    const handleClick = e => {
        e.preventDefault();
        setMenuOpen(d => !d);
    }

    const handleExpand = e => {
        e.preventDefault();
        setMenuExpanded(d => !d);
    }

    const handleLogout = (e) => {
        e.preventDefault();
        logoutUser()
            .then(() => {
                signOut();
                navigate("/");
            })
            .catch((err) => errorAlert(err))
    }

    /**
     * change the status of the side nav whenever toggled. 
     */
    useEffect(() => {
        if (!isMenuExpanded) {
            document.body.classList.add('sidenav-toggled')
        }
        else document.body.classList.remove('sidenav-toggled');
    }, [isMenuExpanded])

    /**
     * close the nav whenver a navigation takes place.
     */
    useEffect(() => {
        setActive(location.pathname.split('/')[2] || '');
        setMenuOpen(false);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [location.pathname])

    /**
     * addition class when toggled. 
     */
    const additionalClass = isMenuOpen ? ' toggled' : '';

    const imgsrc = profile.image ?
        "" :
        `https://eu.ui-avatars.com/api/?name=${profile.other_names}+${profile.last_name}&size=128`

    return (
        <div>
            <nav className="nav nav-pills flex-row fixed-bottom menubar">
                <MobileNavItem active={(active === 'dashboard') || !active} to="/app">
                    <i className="fas fa-home fa-fw"></i>
                </MobileNavItem>
                <MobileNavItem active={active === 'clients'} to="/app/clients">
                    <i className="fas fa-users fa-fw"></i>
                </MobileNavItem>
                <MobileNavItem active={active === 'events'} to="/app/events">
                    <i className="far fa-calendar-alt fa-fw"></i>
                </MobileNavItem>
                {/* <MobileNavItem active={active === 'products'} to="/app/products">
                    <i className="fas fa-box-open fa-fw"></i>
                </MobileNavItem> */}
                <MobileNavItem active={active === 'payments'} to="/app/payments">
                    <i className="fas fa-credit-card fa-fw"></i>
                </MobileNavItem>
                <a className="flex-fill text-center nav-link text-white" href="#." id="toggleMenu" onClick={handleClick}>
                    <i className="fas fa-bars fa-fw"></i>
                </a>
            </nav>

            <nav className={`vertical-nav ${additionalClass}`} id="sidebar">

                <div className="d-flex profile text-light align-items-center" style={{ backgroundColor: "rgba(255,255,255,0.2)" }}>
                    <img src={imgsrc} alt="..." width="65" className="rounded-circle img-thumbnail shadow-sm" />
                    <div className="ms-3 media-body">
                        <h4 className="m-0 font-weight-bold">{profile.display_name}</h4>
                        {/* <p className="font-weight-light mb-0">{profile.job_title}</p> */}
                    </div>
                </div>

                <ul className="nav link-container d-flex flex-column mb-0">

                    <NavItem link="/app"
                        title="Dashboard"
                        active={['dashboard', ''].indexOf(active) !== -1}
                    >
                        <i className="fas fa-home fa-fw" />
                    </NavItem>


                    <div className="my-1 my-md-3" />
                    <h6 className='mx-2 mb-1'>Sales</h6>

                    <NavItem link="/app/clients"
                        title="Clients"
                        active={active === 'clients'}
                    >
                        <i className="fas fa-users fa-fw" />
                    </NavItem>

                    <NavItem link="/app/events"
                        title="Events"
                        active={active === 'events'}
                    >
                        <i className="far fa-calendar-alt fa-fw" />
                    </NavItem>

                    <NavItem link="/app/payments"
                        title="Payments"
                        active={active === 'payments'}
                    >
                        <i className="fas fa-credit-card fa-fw" />
                    </NavItem>


                    <div className="my-1 my-md-3" />
                    <h6 className='mx-2 mb-1'>Purchases</h6>

                    <NavItem link="/app/vendors"
                        title="Vendors"
                        active={active === 'vendors'}
                    >
                        <i className="fas fa-helmet-safety fa-fw" />
                    </NavItem>

                    <NavItem link="/app/expenses"
                        title="Expenses"
                        active={active === 'expenses'}
                    >
                        <i className="fas fa-coins fa-fw" />
                    </NavItem>

                    <NavItem link="/app/purchase-orders"
                        title="Purchase Orders"
                        active={active === 'purchase-orders'}
                    >
                        <i className="fas fa-shop fa-fw" />
                    </NavItem>

                    <NavItem link="/app/bills"
                        title="Bills"
                        active={active === 'bills'}
                    >
                        <i className="fas fa-file-invoice-dollar fa-fw" />
                    </NavItem>

                    <NavItem link="/app/bill-payments"
                        title="Payments Made"
                        active={active === 'bill-payments'}
                    >
                        <i className="fas fa-receipt fa-fw" />
                    </NavItem>

                    <div className="my-1 my-md-3" />
                    <h6 className='mx-2 mb-1'>Finance</h6>

                    <NavItem link="/app/requisitions"
                        title="Requisitions"
                        active={active === 'requisitions'}
                    >
                        <i className="fas fa-hand-holding-usd fa-fw" />
                    </NavItem>
                    
                    <NavItem link="/app/client-vendors"
                        title="Client Vendors"
                        active={active === 'client-vendors'}
                    >
                        <i className="far fa-building fa-fw" />
                    </NavItem>

                    <NavItem link="/app/staff-payments"
                        title="Staff Payments"
                        active={active === 'staff-payments'}
                    >
                        <i className="fas fa-money-bill-wave fa-fw" />
                    </NavItem>

                    <NavItem link="/app/payroll"
                        title="Payroll"
                        active={active === 'payroll'}
                    >
                        <i className="fas fa-wallet fa-fw" />
                    </NavItem>

                    <NavItem link="/app/accounts"
                        title="Accounts"
                        active={active === 'accounts'}
                    >
                        <i className="fas fa-vault fa-fw" />
                    </NavItem>

                    <div className="my-1 my-md-3" />
                    <h6 className='mx-2 mb-1'>Operations</h6>

                    <NavItem link="/app/vehicles"
                        title="Vehicles"
                        active={active === 'vehicles'}
                    >
                        <i className="fas fa-car fa-fw" />
                    </NavItem>


                    <NavItem link="/app/drivers"
                        title="Drivers"
                        active={active === 'drivers'}
                    >
                        <i className="fas fa-id-card fa-fw" />
                    </NavItem>

                    <NavItem link="/app/schedules"
                        title="Schedules"
                        active={active === 'schedules'}
                    >
                        <i className="fas fa-calendar fa-fw" />
                    </NavItem>

                    <NavItem link="/app/maintenance"
                        title="Maintenance"
                        active={active === 'maintenance'}
                    >
                        <i className="fas fa-screwdriver-wrench fa-fw" />
                    </NavItem>

                    <div className="my-1 my-md-3" />
                    <h6 className='mx-2 mb-1'>Admin</h6>

                    <NavItem link="/app/users"
                        title="Users"
                        active={active === 'users'}
                    >
                        <i className="fas fa-users fa-fw" />
                    </NavItem>

                    <NavItem link="/app/me"
                        title="My Profile"
                        active={active === 'me'}
                    >
                        <i className="fas fa-user-cog fa-fw" />
                    </NavItem>

                    <li className="nav-item">
                        <a href="#." onClick={handleLogout} className="nav-link" title="Log Out">
                            <i className="fas fa-sign-out-alt fa-fw" />
                            <span className="nav-text">Log Out</span>
                        </a>
                    </li>

                </ul>

                <div className="nav-toggler">
                    <a href="#." className="nav-link w-100 text-center px-0 text-light" id="sidenavToggler" onClick={handleExpand}>
                        <i className="fas fa-arrow-circle-left fa-fw fa-2x"></i>
                    </a>
                </div>
            </nav>
        </div>
    )
}

export default Menu;