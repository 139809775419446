
import { APIURL, deleleData as deleteData, getData, postData } from "../fetch";


/**
 * @typedef {{
 *  id: string 
 *  order_id: string
 *  category: string
 *  unit_price: number
 *  quantity: number
 *  notes: string
 *  tax_percent: number
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string,
 * }} OrderItemObject
 */

/**
 * @typedef {{
 *  id: string 
 *  reference: string
 *  order_no: string
 *  vendor_id: string
 *  order_date: string
 *  pretax_amount: number 
 *  tax_amount: number 
 *  total_amount: number 
 *  isTaxInclusive: boolean 
 *  quotation_file_id: string
 *  status: string
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string 
 *  items: OrderItemObject[]
 *  vendor: string|import("./vendors").VendorObject
 * }} OrderObject
 */



/**
 * 
 * @param {OrderObject} data 
 * @returns {Promise<{code: number, message: string, order: OrderObject}>}
 */
const createOrder = data => {
    return postData({
        url: `${APIURL}/purchase-orders`,
        data
    })
}

/**
 * 
 * @param {OrderObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, order: OrderObject}>}
 */
const updateOrder = (data, id) => {
    return postData({
        url: `${APIURL}/purchase-orders/${id}`,
        data
    })
}

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, orders: OrderObject[]}>}
 */
const getOrders = (getAll = false, conditions = null) => {

    const obj = { url: `${APIURL}/purchase-orders`, data: {} }

    if (getAll) obj.data.expanded = 1
    if (conditions) obj.data = { ...obj.data, ...conditions };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, order: OrderObject}>}
 */
const getOrder = (id, includes = null) => {

    const obj = { url: `${APIURL}/purchase-orders/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteOrder = id => deleteData({ url: `${APIURL}/purchase-orders/${id}` })


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string, order: OrderObject}>}
 */
const changeOrderStatus = (id, status) => postData({ url: `${APIURL}/purchase-orders/${id}/status/${status}` })

export {
    createOrder,
    getOrder,
    getOrders,
    updateOrder,
    deleteOrder,
    changeOrderStatus
}