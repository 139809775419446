import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Table } from 'react-bootstrap';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import currency from '../../components/currency';
import style from './orders.scss';
import { useContext, useEffect, useState } from 'react';
// import { deleteDocument, getExpense, uploadDocument } from '../../resources/api/orders';
import { ErrorLoading, PageLoading } from '../../components/loading';
import { EditOrder } from './order-manipulation';
import moment from "moment";
import { UCWords } from '../../components/resources';
import cur from '../../components/currency';
// import { fileIcons } from '../../assets/constants';
// import { APIURL } from '../../resources/fetch';
import { changeOrderStatus, getOrder } from '../../resources/api/orders';
import { ConfirmAction } from '../../components/prompt';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { AppUserContext } from '../../App';
import { APIURL } from '../../resources/fetch';


/**
 * handle for /app/orders/:orderid
 * @param {Object} props
 * @param {import('../../resources/api/orders').OrderObject} props.details
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/orders').OrderObject>>} props.setDetails
 * 
 */
const ViewOrder = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Purchase Orders', href: '/app/purchase-orders' },
        { title: details.order_no }
    ];


    const { profile } = useContext(AppUserContext);

    const handleStatusChange = status => {
        infoAlert("Changing Order Status...");
        changeOrderStatus(details.id, status)
            .then(({ order, message }) => {
                setDetails(d => ({ ...d, ...order }));
                successAlert(message);
            })
            .catch(errorAlert)
    }


    // const handleDocUpload = (document) => setDetails(d => ({ ...d, documents: d.documents.concat(document) }))

    // const handleDocDelete = id => setDetails(d => ({ ...d, documents: d.documents.filter(dd => dd.id !== id) }));

    const style1 = { width: '150px', display: 'inline-block', fontWeight: 'normal' };

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>
                    {details.order_no} ({details.reference})
                </Title>
                <ActionBar>

                    <ButtonToolbar>
                        <Btn href={`${APIURL}/purchase-orders/${details.id}/pdf`} title="Print Order" isExternalLink>
                            <i className="far fa-file-pdf" />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        {(details.status === "open") &&
                            <>
                                <ConfirmAction
                                    as={Btn}
                                    title="Mark as Sent"
                                    confirmationText="Are you sure you want to mark this order as sent?"
                                    handleAction={() => handleStatusChange('sent')}
                                >
                                    <i className="far fa-paper-plane" />
                                </ConfirmAction>
                            </>
                        }
                        {(
                            (details.status === "sent")
                            ||
                            ((details.status === "cancelled") && (profile.permission_level === 1))

                        ) &&
                            <ConfirmAction
                                as={Btn}
                                title="Mark as Open"
                                confirmationText="Are you sure you want to mark this order as open?"
                                handleAction={() => handleStatusChange('open')}
                            >
                                <i className="fas fa-arrow-rotate-left" />
                            </ConfirmAction>
                        }
                        {(['open', 'sent'].indexOf(details.status) !== -1) &&
                            <>
                                <Btn href={`/app/bills/new?purchase_order=${details.id}`} title="Convert to Bill">
                                    <i className="fas fa-file-invoice-dollar" />
                                </Btn>
                                <ConfirmAction
                                    as={Btn}
                                    title="Cancel Order"
                                    confirmationText="Are you sure you want to cancel this order?"
                                    handleAction={() => handleStatusChange('cancelled')}
                                >
                                    <i className="fas fa-times-circle" />
                                </ConfirmAction>
                            </>
                        }
                    </ButtonToolbar>
                    <ButtonToolbar>

                        {(details.status === "open") &&
                            <Btn href={`/app/purchase-orders/${details.id}/edit`} title="Edit Order">
                                <i className="fas fa-pencil-alt" />
                            </Btn>
                        }

                        <Btn href="/app/orders/new" title="New Order">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader >

            <div className="max-800 p-1 orders">

                <div className="text-end">
                    REFERENCE: <span style={style1}>{details.reference}</span> <br />
                    ORDER DATE: <span style={style1}>{moment(details.order_date).format("DD MMMM YYYY")}</span> <br />
                    STATUS: <span style={style1}>{UCWords(details.status.replace(/-/g, " "))}</span> <br />
                </div>

                {/* <div className="mt-5 mb-2">
                    <h5>Paid By</h5>
                    <span className="lead">Maurice Ntege</span> <br />
                    morsnegs@hotmail.com
                </div> */}

                <div className="my-3">
                    <span style={{ height: '100px' }} className="px-3 px-sm-4 d-inline-flex align-items-center justify-content-end bg-teal text-white font-weight-bold rounded">
                        UGX <span style={{ fontSize: '2rem' }} className="d-inline-block ms-2">{cur(details.total_amount).format()}</span>
                    </span>
                </div>

                <Table size="sm" borderless style={{ maxWidth: '500px' }}>
                    <colgroup>
                        <col span="1" style={{ width: "50%" }} />
                        <col span="1" style={{ width: "50%" }} />
                    </colgroup>

                    <tbody>
                        {details.tax_amount > 0 &&
                            <>
                                <tr>
                                    <td>Pretax Amount</td>
                                    <td>UGX {currency(details.pretax_amount).format()}</td>
                                </tr>
                                <tr>
                                    <td>Tax</td>
                                    <td>UGX {currency(details.tax_amount).format()}</td>
                                </tr>
                            </>

                        }
                        <tr>
                            <td>Vendor</td>
                            <td><Link to={`/app/vendors/${details.vendor_id}`}>{details.vendor.title}</Link></td>
                        </tr>
                        <tr>
                            <td>Prices Tax Inclusive?</td>
                            <td>{details.isTaxInclusive ? "Yes" : "No"}</td>
                        </tr>
                        {!!details.quotation_file_id &&
                            <tr>
                                <td>Quotation</td>
                                <td><a href={`${APIURL}/files/${details.quotation_file_id}/download`} target='__blank'>Download</a></td>
                            </tr>
                        }

                    </tbody>
                </Table>

                {(details.items.length > 0) && <OrderItems items={details.items} />}


            </div>
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/orders').OrderItemObject[]} props.items 
 * @returns 
 */
const OrderItems = ({ items }) => (
    <div className={`mt-3 mt-sm-4 mb-2 ${style}`}>
        <h5>Items</h5>

        <Table responsive style={{ minWidth: '600px' }} hover className="items-table">
            <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "35%", textAlign: 'center' }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "22%" }} />
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Item</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Tax</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                {items.map((i, idx) => (
                    <tr key={i.id}>
                        <td>{idx + 1}</td>
                        <td>
                            {UCWords(i.category.replace(/-/g, " "))}
                            <p className='text-muted small my-0' style={{ lineHeight: 1 }} >{i.notes}</p>
                        </td>
                        <td>{i.quantity}</td>
                        <td>{cur(i.unit_price, 0).format()}</td>
                        <td>{i.tax_percent}%</td>
                        <td>{cur(i.quantity * i.unit_price, 0).format()}</td>
                    </tr>
                ))}
            </tbody>

        </Table>

    </div>
)



const OrderDetails = () => {

    const { orderid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setError(null);
        setLoaded(false);

        getOrder(orderid, ['vendor', 'items'])
            .then(({ order }) => {
                setDetails(order);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [orderid])

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading order details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/edit" element={<EditOrder details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewOrder details={details} setDetails={setDetails} />} />
        </Routes>
    )

}

export default OrderDetails;