import { Form, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import { errorAlert, successAlert } from './../../components/toastr';
import { SubmitButton, CancelButton } from './../../components/btns';
import { createClient, updateClient } from '../../resources/api/clients';
import { useNavigate } from 'react-router-dom';

const { Control, Control: { Feedback }, Label } = Form;

/**
 * handle page for creating trip
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('../../resources/api/clients').ClientObject} props.details
 * @param {(details: import('../../resources/api/clients').ClientObject) => void} props.onSubmit
 */
const ClientForm = ({ details: clientObject, action, onSubmit }) => {


    const [details, setDetails] = useState(clientObject);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        const promise = (action === "edit") ? updateClient(details, details.id) : createClient(details);

        promise
            .then(({ client, message }) => {
                successAlert(message);
                onSubmit(client);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Details</h4>
            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Title</Label>
                    <Control
                        value={details.title}
                        minLength={8}
                        maxLength={60}
                        onChange={e => setDetails({ ...details, title: e.currentTarget.value })}
                        placeholder="e.g., United Nations"
                        required={true}
                    />
                    <Feedback type="invalid">
                        The title should have a minimum of 8 and maximum of 60 characters.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Contact Person</Label>
                    <Control
                        value={details.contact_person}
                        minLength={3}
                        maxLength={60}
                        onChange={e => setDetails({ ...details, contact_person: e.currentTarget.value })}
                        placeholder="e.g., Peter Samuel"
                        required={true}
                    />
                    <Feedback type="invalid">
                        The contact person's name should have a minimum of 3 and maximum of 60 characters.
                    </Feedback>
                </Col>
            </Row>
            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Email</Label>
                    <Control
                        value={details.email}
                        type="email"
                        onChange={e => setDetails({ ...details, email: e.currentTarget.value })}
                        placeholder="e.g., the contact email address"
                        required={!details.telephone}
                    />
                    <Feedback type="invalid">
                        Either an email or telephone must be provided.
                        Fill in a valid email address
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Telephone</Label>
                    <Control
                        value={details.telephone}
                        type="tel"
                        pattern="^\+?[1-9]{1}[0-9]{0,2}(-|\s)?([0-9]{2,4}(-|\s)?)+$"
                        onChange={e => setDetails({ ...details, telephone: e.currentTarget.value })}
                        placeholder="e.g +256-7898-478987"
                        required={!details.email}
                    />
                    <Feedback type="invalid">
                        Either an email or telephone must be provided.
                        Should start with the country code and can only have dashes(-) or spaces.
                    </Feedback>
                </Col>
            </Row>

            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Client" : "Create Client"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting} onClick={() => navigate(-1)}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form>
    )
}


export default ClientForm;