import { ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Nav from '../../components/breadcrumb';
import PageHeader, { Title } from '../../components/pageheader';


/**
 * handle for /app/expenses/:expenseid
 * @param {Object} props
 * 
 */
const ViewStats = () => {

    const nav_items = [
        { title: 'Payroll', href: '/app/payroll' },
        { title: "Action" }
    ];


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>
                    Payroll
                </Title>

            </PageHeader>

            <div className='max-900'>
                <h5>Choose an action below to continue</h5>

                <div className='text-center text-sm-start mt-4'>
                    <ButtonGroup vertical size="lg">
                        <Link className='btn btn-lg btn-outline-secondary' to="/app/payroll/staff">
                            <i className='fas fa-users me-2' />Manage Staff
                        </Link>

                        <Link className='btn btn-lg btn-outline-success' to="/app/payroll/approve">
                            <i className='fas fa-check-circle me-2' />Approve Payments
                        </Link>


                        <Link className='btn btn-lg btn-outline-danger' to="/app/payroll/process">
                            <i className='fas fa-hand-holding-usd me-2' />Process Payments
                        </Link>

                        <Link className='btn btn-lg btn-outline-secondary' to="/app/payroll/expenses">
                            <i className='fas fa-eye me-2' />View Payroll Expenses
                        </Link>

                    </ButtonGroup>
                </div>
            </div>
        </>
    )
}




export default ViewStats;