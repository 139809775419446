import Nav from '../../components/breadcrumb';
import PageHeader, {
    Title,
    ActionBar,
    ActionButton as Btn,
    ButtonToolbar
} from '../../components/pageheader';
import { Form, Table, Card, Row, Col, Modal, Button, FormLabel, Dropdown } from 'react-bootstrap';
import { SortButton } from '../../components/btns';
import { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PagePagination, { usePagination } from '../../components/pagination';
import { Link } from 'react-router-dom';
import { useScreenType } from '../../components/custom-hooks';
import { errorAlert, info, infoAlert } from '../../components/toastr';
import currency, { UGX } from '../../components/currency';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import bootstrapPlugin from '@fullcalendar/bootstrap' // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { getEventProducts, getEvents } from '../../resources/api/events';
import { ErrorLoading, PageLoading } from '../../components/loading';
import { EventColourMaping } from '../../assets/constants';
import { DateSelector } from '../../components/datetimepicker';
import { createFile } from '../../components/exceller';
import { AppUserContext } from '../../App';
import { UCWords, sortFunction } from '../../components/resources';





/**
 * View table items on screens larger than md
 * @param {Object} props
 * @param {{currentField: string, currentOrder: "asc" | "desc"}} props.srt
 * @param {(field: string) => void} props.handleSort
 * @param {import('../../resources/api/events').EventObject[]} props.items
 * @param {number} props.currentPage
 * @param {number} props.noPageItems
*/
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems }) => {

    const { profile } = useContext(AppUserContext);

    const El = ({ field, children, ...props }) => <th {...props}>{children} <SortButton field={field} {...srt} handleSort={handleSort} /></th>;

    return (
        <Table responsive hover style={{ minWidth: '700px' }}>
            {/* <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "10%" }} />
                {(profile.permission_level < 4) && <>
                    <col span="1" style={{ width: "15%" }} />
                    <col span="1" style={{ width: "15%" }} />
                </>}

            </colgroup> */}
            <thead>
                <tr>
                    <th>#</th>
                    <El field="reference">Ref</El>
                    <El field="client">Client</El>
                    <El field="event_date">Date</El>
                    <El field="location">Location</El>
                    <El className="text-center" field="no_products">No Prod</El>
                    {(profile.permission_level < 4) && <>
                        <El className="text-end" field="amount">Amount</El>
                        <El className="text-end" field="balance">Balance</El>
                    </>}
                    <El className="text-center" field="status">Status</El>

                </tr>

            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td>
                            <Link to={`/app/events/${e.id}`}>
                                {e.reference}
                            </Link>
                        </td>
                        <td>{<Link to={`/app/clients/${e.client_id}`}>{e.client}</Link>}</td>
                        <td>{moment(e.event_date).format("D MMM YYYY")}</td>
                        <td>{e.location}</td>
                        <td className="text-center">{e.no_products}</td>
                        {(profile.permission_level < 4) && <>
                            <td className="text-end">{currency(e.amount, 0).format()}</td>
                            <td className="text-end">{currency(e.balance, 0).format()}</td>
                        </>}
                        <td>{UCWords(e.status)}</td>

                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

/**
 * view items on screens smaller than md
 * @param {Object} props
 * @param {{currentField: string, currentOrder: "asc" | "desc"}} props.srt
 * @param {(field: string) => void} props.handleSort
 * @param {import('../../resources/api/events').EventObject[]} props.items
 */
const ViewMobile = ({ srt, handleSort, items }) => {


    const { profile } = useContext(AppUserContext);

    const El = ({ field, children }) => (
        <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
            {children} <SortButton field={field} {...srt} handleSort={handleSort} />
        </span>
    )
    return (
        <div className="my-3">
            <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
                <El field="reference">Reference</El>
                <El field="client">Client</El>
                <El field="event_date">Date</El>
                <El field="location">Location</El>
                <El field="no_products">No Products</El>
                <El field="amount">Amount</El>
                <El field="balance">Balance</El>
                <El field="status">Status</El>
            </div>
            {items.map((e, i) => (
                <Card className="my-2 shadow-sm" key={i}>
                    <Card.Body className="px-3">
                        <div className="d-flex justify-content-between mb-2">
                            <span className="text-secondary font-weight-normal">{moment(e.event_date).format("ddd DD MMM YYYY")}</span>
                            <span className="text-muted font-weight-normal">{UGX(e.balance).format()}</span>
                        </div>

                        <div className="lead mb-2">
                            <Link to={`/app/events/${e.id}`}>{e.reference}</Link>
                        </div>
                        <Row>
                            <Col xs={4} className="small">
                                CLIENT
                            </Col>
                            <Col xs={8}>
                                <Link to={`/app/clients/${e.client_id}`}>{e.client}</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="small">
                                LOCATION
                            </Col>
                            <Col xs={8}>
                                {e.location}
                            </Col>
                        </Row>

                        {(profile.permission_level < 4) &&
                            <>
                                <Row>
                                    <Col xs={4} className="small">
                                        AMOUNT
                                    </Col>
                                    <Col xs={8}>
                                        {UGX(e.amount).format()}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={4} className="small">
                                        BALANCE
                                    </Col>
                                    <Col xs={8}>
                                        {UGX(e.balance).format()}
                                    </Col>
                                </Row>
                            </>
                        }
                        <div className="d-flex justify-content-between mt-3">
                            <small className="text-muted text-uppercase">
                                {e.category} | {UCWords(e.status)}
                            </small>
                            <span
                                className="text-secondary font-weight-normal"
                            >
                                {e.no_products} products
                            </span>
                        </div>
                    </Card.Body>
                </Card>
            ))}
        </div>
    )
}


const TableView = ({ events }) => {

    const [srt, setSrt] = useState({
        currentField: 'event_date',
        currentOrder: 'asc'
    });

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'reference' });

    const screenType = useScreenType();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);


    useEffect(() => {
        setOriginalItems(events);
        setItems(events);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events.length])

    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {
        const v = search.val, f = search.field;
        if (v.length < 2 || f === "") return setItems(orginalItems);
        setItems([...orginalItems.filter(i => i[f].toLowerCase().indexOf(v.toLowerCase()) !== -1)]);
    }, [search.val, search.field, orginalItems]);




    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => sortFunction(a, b, srt.currentField, srt.currentOrder);

        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }

    return (

        <div className="max-1000">
            <Row xs={{ cols: "auto" }} className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0">
                <Col>

                    <Form.Control placeholder="Search For" size="sm"
                        value={search.val}
                        onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                    />
                </Col>
                <Col>
                    <span className="mx-1">in</span>
                </Col>
                <Col>
                    <Form.Select
                        size="sm"
                        value={search.field}
                        onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                    >
                        <option value="reference">Reference</option>
                        <option value="client">Client</option>
                        <option value="location">Location</option>
                        <option value="status">Status</option>
                    </Form.Select>
                </Col>


            </Row>

            {screenType === 'desktop' ?
                <ViewDesktop srt={srt} handleSort={handleSort} items={displayItems} noPageItems={noPageItems} currentPage={PAGE} /> :
                <ViewMobile srt={srt} handleSort={handleSort} items={displayItems} />
            }

            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                    <Col className="me-2 text-nowrap">No Items:</Col>
                    <Col>
                        <Form.Select
                            value={noPageItems}
                            onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                            size="sm"
                            style={{ maxWidth: '100px' }}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </Form.Select>
                    </Col>

                    <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                </Row>
                <PagePagination
                    page={PAGE}
                    no_pages={NOPAGES}
                    setPage={setPage}
                />
            </div>
        </div>
    )
}

const CalendarView = ({ events }) => {


    const { profile } = useContext(AppUserContext);

    const [show, setShow] = useState(false);
    const [item, setShowItem] = useState({});
    const calendarRef = useRef();
    const navigate = useNavigate();

    const screenType = useScreenType();

    const onClickEvent = ({ event: { id } }) => {
        const item = events.find(e => e.id === id);
        setShowItem(item);
        setShow(true);

    }

    const onDateClick = ({ dateStr }) => {

        const api = calendarRef.current.getApi(),
            _date = moment(dateStr).format('dddd Do MMMM YYYY');
        api.gotoDate(dateStr);
        api.changeView('listDay');

        info(
            `Click here if you want to create an event on ${_date}`,
            "",
            {
                onclick: () => navigate(`/app/events/new?date=${dateStr}`)
            })
    }


    return (
        <div className="max-1000">
            <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, bootstrapPlugin, listPlugin, interactionPlugin]}
                themeSystem="bootstrap"
                initialView="dayGridMonth"
                firstDay={1}
                displayEventEnd={true}
                eventDataTransform={e => {
                    if (e.allDay) {
                        e.end = moment(e.end).add(23, 'hour').format("YYYY-MM-DD");
                    }
                    return e;
                }}
                // nextDayThreshold="00:00:01"
                events={events.map(e => {

                    // if(e.event_date !== e.end_date) {
                    //     e.end_date = moment(e.end_date).subtract(1, "d").add(1, "d").format("YYYY-MM-DD");
                    //     console.log(`${e.event_date} - ${e.end_date}`);
                    // }

                    return {
                        ...e,
                        start: e.event_date,
                        end: e.end_date + " 12:00:00",
                        allDay: true,
                        title: `${e.client} - ${e.reference}`,
                        classNames: EventColourMaping[e.colour]
                    }
                })}
                headerToolbar={
                    {
                        start: 'title', // will normally be on the left. if RTL, will be on the right
                        center: screenType === "desktop" ? 'dayGridMonth,listMonth' : "dayGridMonth",
                        end: 'today prev,next' // will normally be on the right. if RTL, will be on the left
                    }
                }
                height={screenType === 'desktop' ? null : "80vh"}
                eventClick={onClickEvent}
                dateClick={onDateClick}
                eventOrder="-amount,title"
            />

            <Modal show={show} centered>
                <Modal.Header className="bg-secondary text-light">
                    <Modal.Title>{item.reference}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className="my-1">
                        <Col sm={3} className="text-secondary text-uppercase small">
                            Client
                        </Col>
                        <Col sm={8}>
                            <Link to={`/app/clients/${item.client_id}`}>{item.client}</Link>
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col sm={3} className="text-secondary text-uppercase small">
                            Date
                        </Col>
                        <Col sm={8}>
                            {moment(item.event_date).format("dddd DD MMMM YYYY")}
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col sm={3} className="text-secondary text-uppercase small">
                            Location
                        </Col>
                        <Col sm={8}>
                            {item.location}
                        </Col>
                    </Row>

                    {(profile.permission_level < 4) &&
                        <>
                            <Row className="my-1">
                                <Col sm={3} className="text-secondary text-uppercase small">
                                    Total
                                </Col>
                                <Col sm={8}>
                                    {UGX(item.amount).format()}
                                </Col>
                            </Row>
                            <Row className="my-1">
                                <Col sm={3} className="text-secondary text-uppercase small">
                                    Balance
                                </Col>
                                <Col sm={8}>
                                    {UGX(item.balance).format()}
                                </Col>
                            </Row>
                        </>
                    }
                    <Row className="my-1">
                        <Col sm={3} className="text-secondary text-uppercase small">
                            Status
                        </Col>
                        <Col sm={8}>
                            {UCWords(item.status || '')}
                        </Col>
                    </Row>

                </Modal.Body>

                <Modal.Footer className="py-1">
                    <Link to={`/app/payments/new?event=${item.id}`} className="text-secondary btn btn-sm btn-link">
                        <i className="fas fa-credit-card me-1" />Add Payment
                    </Link>
                    <Link to={`/app/events/${item.id}/edit`} className="text-danger btn btn-sm btn-link">
                        <i className="fas fa-pencil-alt me-1" />Edit
                    </Link>
                    <Link to={`/app/events/${item.id}`} className="text-primary btn btn-sm btn-link">
                        <i className="fas fa-info-circle me-1" />More Info
                    </Link>
                    <Button variant="link" size="sm" className="text-dark" onClick={() => setShow(false)}>
                        <i className="fas fa-times-circle me-1" />Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

/**
 * module that handles view of clients
 * match path /app/events
 */
const ViewEvents = ({ view = "table" }) => {

    const navItems = [{ title: 'Events' }];

    const [events, setEvents] = useState([]);
    const [calEvents, setCalEvents] = useState([]);


    const [jobs, setJobs] = useState([]);
    const [job_dates, setJobDates] = useState([]);


    const [error, setError] = useState();
    const [loaded, setLoaded] = useState();

    const [searchParams] = useSearchParams();

    const [dateShow, setDateShow] = useState(false);
    const [dateValues, setDateValues] = useState({ start: "", end: "" })


    const [event_view, setView] = useState("all");



    /**
     * get the items
     */
    useEffect(() => {

        getEvents(true)
            .then(({ events, event_dates }) => {
                setJobs(events);
                setJobDates(event_dates);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))
    }, [])


    useEffect(() => {

        const _view = searchParams.get("view") || "all";
        setView(_view);

    }, [searchParams])


    useEffect(() => {

        let _jobs = [...jobs], _job_dates = [...job_dates], jobids;
        let datenow = moment().format("YYYY-MM-DD");

        switch (event_view) {
            case "unconfirmed":
                _jobs = _jobs.filter(i => (['unconfirmed'].indexOf(i.status) !== -1));

                jobids = _jobs.map(j => j.id);
                _job_dates = _job_dates.filter(d => (jobids.indexOf(d.event_id) !== -1));
                break;
            case "future":
                _jobs = _jobs.filter(i => i.event_date >= datenow);
                _job_dates = _job_dates.filter(i => i.start >= datenow);
                break;
            case "past":
                _jobs = _jobs.filter(i => i.event_date < datenow);
                _job_dates = _job_dates.filter(i => i.start < datenow);
                break;
            case "confirmed":
                _jobs = _jobs.filter(i => (['confirmed'].indexOf(i.status) !== -1));
                jobids = _jobs.map(j => j.id);
                _job_dates = _job_dates.filter(d => jobids.indexOf(d.event_id) !== -1);
                break;
            case "cancelled":
                _jobs = _jobs.filter(i => (['cancelled'].indexOf(i.status) !== -1));
                jobids = _jobs.map(j => j.id);
                _job_dates = _job_dates.filter(d => jobids.indexOf(d.event_id) !== -1);
                break;

            case "default":
                _jobs = _jobs.filter(i => (['cancelled'].indexOf(i.status) === -1));
                jobids = _jobs.map(j => j.id);
                _job_dates = _job_dates.filter(d => jobids.indexOf(d.event_id) !== -1);
                break;

            default:
        }

        setEvents(_jobs);
        setCalEvents([..._jobs, ..._job_dates.map(e => ({
            ...e,
            __id: e.id,
            id: e.event_id,
            event_date: e.start,
            end_date: e.end,
            amount: parseFloat(e.amount)
        }))]);


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event_view, JSON.stringify(jobs), JSON.stringify(job_dates)]);



    const onDownloadClick = () => {

        if (dateValues.end <= dateValues.start) {
            return errorAlert("End Date must be after start date");
        }

        setDateShow(false);

        infoAlert("Fetching products");

        getEventProducts(dateValues.start, dateValues.end)
            .then(({ products }) => {
                const headers = {
                    "event_reference": "Reference",
                    "event_date": "Event Date",
                    "client_name": "Client",
                    "location": "Event Location",
                    "product": "Product",
                    "dimensions": "Dimensions",
                    "qty": "Quantity"
                };

                const keys = Object.keys(headers);


                let items = {
                    products: []
                };

                items.products.push(keys.map(e => headers[e]));

                products.forEach(i => {
                    items.products.push(keys.map(e => (e === "event_date") ? moment(i[e]).format("dddd DD MMM YYYY") : i[e]));
                })

                createFile(items, "Events UG Products.xlsx", "Weekly Products");
            })
            .catch(e => errorAlert(e))
    }



    const views = {
        all: "All",
        unconfirmed: "Unconfirmed",
        future: "Future",
        past: "Past",
        confirmed: "Confirmed",
        cancelled: "Cancelled"
    }


    if (!loaded) return <PageLoading>Loading the events ...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <>
            <Nav items={navItems} />

            <PageHeader maxWidth="1000">
                <Title>Events</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn onClick={() => setDateShow(true)}>
                            <i className="fas fa-download me-1" />Download Products
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        {view === "calendar" ?
                            <Btn href="/app/events/table">
                                <i className="fas fa-table me-1" />Table View
                            </Btn>
                            :
                            <Btn href="/app/events/calendar">
                                <i className="far fa-calendar-alt me-1" />Calendar View
                            </Btn>
                        }


                        <Btn href="/app/events/new">
                            <i className="fas fa-plus-circle me-1" />New Event
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>



            <div className='max-1000 mb-2'>

                <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-basic">
                        View: <strong>{views[event_view]}</strong>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {Object.keys(views).map(v => <Dropdown.Item as={Link} key={v} to={`/app/events/${view}?view=${v}`}>{views[v]}</Dropdown.Item>)}
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            {view === "calendar" ?
                <CalendarView events={calEvents} /> :
                <TableView events={events} />
            }

            <Modal show={dateShow} centered>
                <Modal.Header className="bg-secondary text-light">
                    <Modal.Title>Weekly Products</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Row>
                        <Col md={6}>
                            <FormLabel>Start Date</FormLabel>
                            <DateSelector
                                minDate={false}
                                value={dateValues.start}
                                onChange={date => setDateValues(d => ({ ...d, start: date }))}
                            />
                        </Col>
                        <Col md={6}>
                            <FormLabel>End Date</FormLabel>
                            <DateSelector
                                value={dateValues.end}
                                onChange={date => setDateValues(d => ({ ...d, end: date }))}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-3 mt-md-4 text-end' >
                            <Button size="sm" className='rounded-pill mx-1' onClick={onDownloadClick}>Download</Button>
                            <Button size="sm" className='rounded-pill ms-1' onClick={() => setDateShow(false)} variant="danger">Close</Button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>



        </>
    )
}


export { CalendarView as EventCalendar };

export default ViewEvents;
