import { Modal, Button, Form, ListGroup, InputGroup as Group } from 'react-bootstrap';
import { useState, useEffect, createElement } from 'react';
import { sortFunction } from './resources';
import { error as errorAlert } from './toastr';

const itemStyle = {
    whiteSpace: 'nowrap',
    padding: '.05rem .6rem',
    margin: '.25rem .25rem',
    display: 'inline-block',
    backgroundColor: 'var(--bs-secondary)',
    fontSize: '.8rem',
    borderRadius: '2rem'
}

/**
 * 
 * @typedef {{id: string, title: string, description: ?string, [any: string]: any}} ItemObject
 */

/**
 * @param {Object} props
 * @param {React.ReactNode} props.as
 * @param {string} props.variant
 * @param {string} props.className
 * @param {?number} props.maxItems
 * @param {(ids: string[]) => void} props.onSelect
 * @param {ItemObject[]} props.items
 * @param {string} props.sortBy
 */
const Select = ({ as = Button, variant = "secondary", size, className = "", children, maxItems = 1, items, onSelect, sortBy = null, initialShow = false, ...props }) => {


    const [show, setShow] = useState(initialShow);
    const { Control } = Form;

    const [displayItems, setDisplayItems] = useState([]);
    const [selected, setSelected] = useState([]);

    const [search, setSearch] = useState('');

    /**
     * on change of the length of the contacts, resort them.
     */
    useEffect(() => {

        const srtFn = (a, b) => sortFunction(a, b, (sortBy || 'title'));
        const its = [...items.sort(srtFn)];

        if (search.length < 2) return setDisplayItems(its);
        setDisplayItems([...its.filter(i => i.title.toLowerCase().indexOf(search.toLowerCase()) !== -1)]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, JSON.stringify(items)])

    /**
     * when hiding the modal, set selected to zero 
     * so that when someone comes back they can come back to no previously selected values
     */
    useEffect(() => {
        if (!show) setSelected([]);
    }, [show])


    /**
     * A a file to the list of seelcted files.
     * @param {string} id - ID of the file to add
     */
    const handleClick = id => {

        if (selected.indexOf(id) === -1) {

            if (selected.length >= (maxItems || selected.length + 1))
                return errorAlert("You have selected the maximum number of items you are permitted to choose.");

            setSelected([...selected, id]);
        } else {
            setSelected([...selected.filter(i => i !== id)]);
        }

    }

    const submitItems = () => {
        onSelect(selected);
        setShow(false);
    }

    /**
     * Remove file id from list of selected items
     * @param {React.MouseEvent} e 
     * @param {string} id 
     */
    const removeItem = (e, id) => {
        e.preventDefault();
        setSelected(s => ([...s.filter(i => i !== id)]))
    }

    const handleOpen = e => {
        e.preventDefault();
        setShow(true);
    }

    return (
        <>
            {
                createElement(
                    as,
                    { className, ...props, variant, size, onClick: handleOpen },
                    children
                )
            }

            <Modal show={show} centered animation={false} scrollable backdrop="static">
                <Modal.Header className="pt-3 justify-content-between align-items-sm-center border-bottom-0 flex-column flex-sm-row">
                    <div>
                        {selected.length} item(s) selected
                    </div>
                    <Form>
                        <Control size="sm"
                            placeholder="Search for an item"
                            value={search}
                            onChange={e => setSearch(e.currentTarget.value)}
                        />
                    </Form>
                </Modal.Header>
                <Modal.Body>

                    <div className="mb-2">
                        {items
                            .filter(f => selected.indexOf(f.id) !== -1)
                            .map(f => (
                                <span style={itemStyle} className="text-white" key={f.id}>
                                    {f.title}
                                    <a href="#." className="ms-2 text-light" onClick={e => removeItem(e, f.id)}>
                                        <i className="fas fa-times" />
                                    </a>
                                </span>
                            ))}
                    </div>

                    <ListGroup>
                        {displayItems.map(f => (
                            <ListGroup.Item key={f.id}
                                action
                                onClick={() => handleClick(f.id)}
                                active={selected.indexOf(f.id) !== -1}
                            >
                                <h5 className="mb-0 font-weight-normal">{f.title}</h5>
                                {f.description && <div className="small text-muted mt-1" style={{ lineHeight: "0.95rem" }}>{f.description}</div>}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <span className="d-inline-block small me-auto text-muted">
                        {items.length} items
                    </span>
                    <Button variant="success" className="px-2 px-sm-3 m-1 rounded-pill" disabled={selected.length === 0} onClick={submitItems}>
                        <i className="fas fa-thumbs-up me-1 me-sm-2" />Choose Selected
                    </Button>
                    <Button variant="secondary" className="rounded-pill px-2 px-sm-3 m-1" onClick={() => setShow(false)} >
                        <i className="fas fa-times-circle me-1 me-sm-2"></i>Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

/**
 * 
 * @param {Object} props
 * @param {string[]} props.value 
 * @param {(ids: string[]) => void} props.onChange
 * @param {string} props.placeholder
 * @param {string} props.errorText
 * @param {number} [props.maxItems = 1]
 * @param {boolean} props.required
 * @param {ItemObject[]} props.options
 * @param {"sm"|"lg"} props.inputSize
 * @param {string} props.sortBy
 */
const SelectInput = ({ value = [], onChange, placeholder, options, errorText, maxItems = 1, required = false, inputSize, sortBy = null }) => {

    value = value.filter(v => v);

    /**
     * Remove a contact from the selected list
     * @param {React.MouseEvent} e 
     * @param {string} item_id 
     */
    const removeItem = (e, item_id) => {
        e.preventDefault();
        onChange(value.filter(i => i !== item_id));
    }

    /**
     * Oon hit of the delete key in the input field then remove the item there.
     * @param {React.KeyboardEvent} e 
     */
    const handleKeyUp = e => {
        let isBackspace = false;

        if ('key' in e) {
            isBackspace = (e.key === 'Backspace');
        } else if ('keyCode' in e) {
            isBackspace = (e.keyCode === 8);
        }

        if (!isBackspace) return;

        onChange("");
    }


    const chosen_item = options.filter(f => value.indexOf(f.id) !== -1);

    if (maxItems === 1) {
        return (
            <Group>
                <Form.Control
                    size={inputSize}
                    placeholder={placeholder || "Search for an item"}
                    value={chosen_item.length > 0 ? chosen_item[0].title : ""}
                    onChange={() => null}
                    onKeyUp={handleKeyUp}
                    required={required}
                />
                <Select
                    as={Button}
                    size="sm"
                    variant="link"
                    items={options.filter(i => value.indexOf(i.id) === -1)}
                    maxItems={1}
                    onSelect={ids => onChange(ids)}
                    sortBy={sortBy}
                >
                    <i className="fas fa-search text-secondary" />
                </Select>
                {required &&
                    <Form.Control.Feedback type="invalid">
                        {errorText || "A contact must be selected"}
                    </Form.Control.Feedback>}
            </Group>
        )
    }

    return (
        <>
            <div className="d-sm-flex justify-content-between align-items-center">
                <div>
                    {value.length > 0 ? chosen_item.map(f => (
                        <span style={itemStyle} className="text-white" key={f.id}>
                            {f.title}
                            <a href="#." className="ms-2 text-light" onClick={e => removeItem(e, f.id)}>
                                <i className="fas fa-times" />
                            </a>
                        </span>
                    )) : "No items selected"}
                </div>
                <div>

                    {(!maxItems || (value.length < maxItems)) &&
                        <Select
                            as={Button}
                            size="sm"
                            variant="link"
                            className="text-nowrap"
                            items={options.filter(i => value.indexOf(i.id) === -1)}
                            maxItems={maxItems ? maxItems - value.length : false}
                            onSelect={ids => onChange([...value, ...ids])}
                            sortBy={sortBy}
                        >
                            <i className="fas fa-search me-1" />Search
                        </Select>
                    }

                </div>
            </div>

            {required &&
                <div>
                    <Form.Control value={value.join(",")} onChange={() => null} required className="d-none" />
                    <Form.Control.Feedback type="invalid">
                        {errorText || "At least one item must be selected."}
                    </Form.Control.Feedback>
                </div>
            }
        </>
    )
}

export { Select };

export default SelectInput;