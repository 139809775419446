
import { Routes, Route } from 'react-router-dom';
// import { useContext } from "react";
// import { AppUserContext } from "../../App";
// import { ErrorLoading } from "../../components/loading";
import { NewSchedule } from './schedule-manipulation';
import ViewSchedules from './view-schedules';
import ScheduleDetails from './schedule-details';

const Schedules = () => {

    // const { profile } = useContext(AppUserContext);

    // if (profile.permission_level > 3) {
    //     return <ErrorLoading>You do not have the permission to access clients.</ErrorLoading>
    // }

    return (
        <Routes>
            <Route path="/" element={<ViewSchedules />} />
            <Route path="/new" element={<NewSchedule />} />
            <Route path="/:scheduleid/*" element={<ScheduleDetails />} />
        </Routes>
    )
}

export default Schedules;