import ViewClients from "./view-clients";
import { Routes, Route } from 'react-router-dom';
import { NewClient } from "./client-manipulation";
import ClientDetails from "./client-details";
import { useContext } from "react";
import { AppUserContext } from "../../App";
import { ErrorLoading } from "../../components/loading";

const Clients = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 3) {
        return <ErrorLoading>You do not have the permission to access clients.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/" element={<ViewClients />} />
            <Route path="/new" element={<NewClient />} />
            <Route path="/:clientid/*" element={<ClientDetails />} />
        </Routes>
    )
}

export default Clients;