
import { useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import Nav from '../../components/breadcrumb';
import PageHeader, { Title } from '../../components/pageheader';
import { DateSelector } from "../../components/datetimepicker";
import { printStatement } from "../../resources/api/clients";
import { errorAlert, infoAlert } from "../../components/toastr";
import cur from "../../components/currency";
import moment from "moment";
import { APIURL } from "../../resources/fetch";


const { Check, Label, Control } = Form;

/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/clients").ClientObject} props.details
 */
const ClientStatement = ({ details }) => {

    const nav_items = [
        { title: 'Clients', href: "/app/clients" },
        { title: details.title, href: `/app/clients/${details.id}` },
        { title: 'Generate Statement' }
    ]

    const [date_range, setDateRange] = useState({ from: "", to: "" })
    const [checked, setChecked] = useState({ executed: false, printZero: true, printAll: false });

    const [items, setItems] = useState([...details.events]);
    const [deleted_items, setDeletedItems] = useState([]);




    const onReset = () => {
        setItems([...details.events]);
        setDateRange({ from: "", to: "" });
        setChecked({ executed: false, printZero: true, printAll: false });
        setDeletedItems([]);
    }

    const onFilter = () => {
        let _items = [...details.events].filter(i => (deleted_items.indexOf(i.long_id) === -1)),
            today = moment().format("YYYY-MM-DD");

        if (!!date_range.from)
            _items = _items.filter(i => i.event_date >= date_range.from);


        if (!!date_range.to)
            _items = _items.filter(i => i.event_date <= date_range.to);

        if (!checked.printZero)
            _items = _items.filter(i => (i.balance > 0));

        if (checked.executed)
            _items = _items.filter(i => i.event_date <= today);

        setItems(_items);
    }

    const updateComment = (text, id) => {
        let _items = [...items],
            idx = _items.findIndex(i => i.id === id),
            _item = _items[idx];

        _item.comments = text;
        _items.splice(idx, 1, _item);
        setItems(_items);
    }

    const onGenerateClick = () => {

        let data = items.map(i => ({ id: i.id, comments: i.comments || "" }));

        infoAlert("Printing statement ...");

        printStatement(data)
            .then(() => {
                let _link = `${APIURL}/clients/${details.id}/statement/pdf`;

                if (checked.printAll) _link += `?printAll=1`;

                window.open(_link);
            })
            .catch(errorAlert)
    }

    const onDeleteClick = id => {

        setDeletedItems(i => i.concat(id));
        setItems(_items => _items.filter(i => i.id !== id));
    }



    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>Generate {details.title}'s statement</Title>

            </PageHeader>

            <div className="max-1000">

                <Row xs={{ cols: "auto" }} className="align-items-center flex-column flex-sm-row mb-3 g-2">
                    <Col>
                        <Label className="form-field-label">From Date</Label>
                        <DateSelector
                            size="sm"
                            value={date_range.from}
                            onChange={d => setDateRange(dates => ({ ...dates, from: d }))}
                        />
                    </Col>
                    <Col className="border-start">
                        <Label className="form-field-label">To Date</Label>
                        <DateSelector
                            size="sm"
                            value={date_range.to}
                            onChange={d => setDateRange(dates => ({ ...dates, to: d }))}
                        />
                    </Col>
                    <Col className="border-start">
                        <Check
                            size="sm"
                            type="checkbox"
                            id={`includeZero`}
                            label={`Include zero-balance events`}
                            checked={checked.printZero}
                            onChange={() => setChecked(c => ({ ...c, printZero: !c.printZero }))}
                        />
                        <Check
                            size="sm"
                            type="checkbox"
                            id={`executedonly`}
                            label={`Jobs must be executed`}
                            checked={checked.executed}
                            onChange={() => setChecked(c => ({ ...c, executed: !c.executed }))}
                        />
                    </Col>
                </Row>
                <Row className="g-1">
                    <Col>
                        <Button className="rounded-pill mx-1" variant="danger" size="sm" onClick={onReset}>
                            <i className="fas fa-undo me-2" />
                            Reset
                        </Button>
                        <Button className="rounded-pill mx-1" variant="success" size="sm" onClick={onFilter}>
                            <i className="fas fa-search me-2" />
                            Run Filter
                        </Button>
                    </Col>
                </Row>

                <Table className="mt-3 mt-sm-5" responsive>
                    <colgroup>
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "30%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Location</th>
                            <th>Date</th>
                            <th className="text-end">Total Amount</th>
                            <th className="text-end">Due</th>
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(item => (
                            <tr key={item.id}>
                                <td>
                                    {item.location} <br />
                                    <Button variant="link" className="rounded-circle text-danger px-0" size="sm" onClick={() => onDeleteClick(item.id)}>
                                        <i className="fas fa-trash-alt me-1" />Delete
                                    </Button>
                                </td>
                                <td>{moment(item.event_date).format("DD MMMM YYYY")}</td>
                                <td className="text-end">{cur(item.amount, 0).format()}</td>
                                <td className="text-end">{cur(item.balance, 0).format()}</td>
                                <td>
                                    <Control as="textarea" size="sm" value={item.comments} onChange={e => updateComment(e.currentTarget.value, item.id)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th></th>
                            <th className="text-end">
                                {
                                    cur(
                                        items.reduce((p, c) => (p + (c.amount)), 0),
                                        0
                                    ).format()
                                }
                            </th>
                            <th className="text-end">
                                {
                                    cur(
                                        items.reduce((p, c) => (p + (c.balance)), 0),
                                        0
                                    ).format()
                                }
                            </th>
                            <th></th>

                        </tr>
                    </tfoot>

                </Table>

                <Row className="g-1">
                    <Col>
                        <Check
                            size="sm"
                            type="checkbox"
                            id={`printAll`}
                            label={`Include invoices in statements`}
                            checked={checked.printAll}
                            onChange={() => setChecked(c => ({ ...c, printAll: !c.printAll }))}
                        /></Col>
                </Row>



                <Row className="g-1">
                    <Col>
                        <Button className="rounded-pill" variant="secondary" onClick={onGenerateClick}>
                            <i className="fas fa-file-pdf me-2" />
                            Generate Report
                        </Button>
                    </Col>
                </Row>
            </div>

        </>
    )

}




export default ClientStatement;