import { createElement } from 'react';
// import { SortButton } from './btns';

/**
 * 
 * @param {{
 * maxLength: number,
 * muted: boolean
 * className: string
 * text: string
 * as: React.ReactElement|React.ReactHTMLElement
 * }} props 
 */
const Text = ({ maxLength = null, muted = false, className = "", as = "span", text, ...props }) => {

    if (maxLength && (text.length > maxLength)) {
        let pos = text.indexOf(" ", maxLength);

        if (pos !== -1) {
            text = text.substring(0, pos) + "...";
        }
    }

    if (muted) className += " text-muted";

    return createElement(as, { className, ...props }, text);
}


// const SortableTitle = ()


export { Text };