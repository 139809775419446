import { createContext, useEffect, useCallback, useState } from "react";
import {
    Routes,
    Route,
    BrowserRouter as Router
} from "react-router-dom";
import Login from "./home/login";
import EventsApp from "./app/app";
import { infoAlert } from "./components/toastr";
import { PageLoading } from "./components/loading";
import { getAuthStatus } from "./resources/api/auth";
import ActivateAccount from "./home/activate";

/**
 * Context containing information about the user profile including, app accesses etc.
 * @type {React.Context<{profile: import("./resources/api/auth").AuthObject, isLoggedIn: boolean}>}
 */

const AppUserContext = createContext();



/**
 * Main App
 */
const App = () => {


    const [isLoggedIn, setLoggedIn] = useState(false);
    const [profile, setProfile] = useState({});
    const [loaded, setLoaded] = useState(false);

    const loginUser = useCallback((details) => {
        setProfile({ ...details });
        setLoggedIn(true);
    }, [])

    const logoutUser = useCallback(() => {
        setProfile({});
        setLoggedIn(false);
    }, [])

    useEffect(() => {
        getAuthStatus()
            .then(({ user_details }) => {
                setProfile({ ...user_details });
                setLoggedIn(true)
            })
            .catch(() => {
                infoAlert("Please log in to continue");
                setLoggedIn(false);
            })
            .finally(() => setLoaded(true))
    }, [])

    useEffect(() => {
        let fontSize = parseInt(window.localStorage.getItem('_events_FS')) || 16;

        if (fontSize !== 16) {
            document.getElementsByTagName('html')[0].style.fontSize = `${fontSize}px`;
            document.getElementsByTagName('body')[0].style.fontSize = `${fontSize}px`;
        }

    }, [])

    if (!loaded) return <PageLoading>Loading your app</PageLoading>;

    return (
        <AppUserContext.Provider value={{ profile, isLoggedIn }} >
            <Router>
                <Routes>
                    <Route
                        path="/app/*"
                        element={<EventsApp logoutUser={logoutUser} />}
                    />
                    <Route
                        path="/activate"
                        element={<ActivateAccount loginUser={loginUser} />}
                    />
                    <Route
                        path="/"
                        element={<Login loginUser={loginUser} />}
                    />
                </Routes>
            </Router>
        </AppUserContext.Provider>
    );
}

export { AppUserContext };
export default App;