import moment from "moment";
import { useEffect, useState } from "react";
import { Tab, Table, Tabs } from "react-bootstrap";
import { Routes, Route, useParams } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import cur from "../../components/currency";
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title } from '../../components/pageheader';
import { sortFunction, UCWords } from "../../components/resources";
import { getStaff } from "../../resources/api/payroll";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/payroll").StaffObject} props.details
 */
const ViewDetails = ({ details }) => {

    const nav_items = [
        { title: 'Payroll', href: "/app/payroll" },
        { title: 'Staff', href: "/app/payroll/staff" },
        { title: details.display_name }
    ]

    // const [payments, setPayments] = useState([]);

    const [key, setKey] = useState('cashflow');


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.display_name}</Title>
            </PageHeader>

            <div className="max-1000">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >

                    <Tab eventKey="cashflow" title="Cashflow">

                        <Table style={{ maxWidth: "500px" }} hover size="sm">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.payments.map(p => (
                                    <tr key={p.id} >
                                        <td>{moment(p.date_added).format("DD MMM YYYY")}</td>
                                        <td>{cur(p.cash_amount, 0).format()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey="salary" title="Salary, Wages & Allowances">

                        <Table style={{ maxWidth: "700px" }} hover size="sm">
                            <thead>
                                <tr >
                                    <th>Date</th>
                                    <th>Category</th>
                                    <th className="text-end">Cash Amount</th>
                                    <th className="text-end">Advance Amount</th>
                                    <th className="text-end">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.payments
                                    .filter(p => (p.expense_type !== "advances"))
                                    .map(p => (
                                        <tr key={p.id} >
                                            <td>{moment(p.date_added).format("DD MMM YYYY")}</td>
                                            <td>{UCWords(p.expense_type.replace(/-/g, " & "))}</td>
                                            <td className="text-end">{cur(p.cash_amount, 0).format()}</td>
                                            <td className="text-end">{cur(p.advance_amount, 0).format()}</td>
                                            <td className="text-end">{cur(p.amount, 0).format()}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey="advance" title="Advance"> <Table style={{ maxWidth: "500px" }} hover size="sm">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th className="text-end">Credit</th>
                                <th className="text-end">Debit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {details.payments
                                .filter(p => ((p.expense_type === "advances") || (p.advance_amount !== 0)))
                                .map(p => (
                                    <tr key={p.id} >
                                        <td>{moment(p.date_added).format("DD MMM YYYY")}</td>
                                        <td className="text-end">{p.expense_type === "advances" ? cur(p.amount, 0).format() : 0}</td>
                                        <td className="text-end">{cur(p.advance_amount, 0).format()}</td>
                                    </tr>
                                ))}
                        </tbody>
                        {/* <tfoot>
                            <tr>
                                <th></th>
                                <th className="text-end">650,000</th>
                                <th className="text-end">200,000</th>
                            </tr>

                        </tfoot> */}
                    </Table>
                    </Tab>

                </Tabs>

            </div>

        </>
    )

}


const StaffDetails = () => {

    const { staffid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setLoaded(false);
        setError();

        getStaff(staffid, ['payments'])
            .then(({ staff }) => {
                staff.payments = staff.payments.sort((a, b) => sortFunction(a, b, "date_added"));
                setDetails(staff);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [staffid])


    if (!isLoaded) return <PageLoading>Loading payment details...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>

    return (
        <Routes>
            <Route path="/" element={<ViewDetails details={details} />} />
        </Routes>
    )

}


export default StaffDetails;