import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Table } from 'react-bootstrap';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import currency from '../../components/currency';
import style from './expenses.scss';
import { useEffect, useState } from 'react';
import { getExpense } from '../../resources/api/expenses';
import { ErrorLoading, PageLoading } from '../../components/loading';
import { EditExpense } from './expense-manipulation';
import moment from "moment";
import { UCWords } from '../../components/resources';
import cur from '../../components/currency';
import { nonChoiceExpenseCategories } from '../../assets/constants';


/**
 * handle for /app/expenses/:expenseid
 * @param {Object} props
 * @param {import('../../resources/api/expenses').ExpenseObject} props.details
 * 
 */
const ViewExpense = ({ details }) => {

    const nav_items = [
        { title: 'Expenses', href: '/app/expenses' },
        { title: details.reference }
    ];

    const style1 = { width: '150px', display: 'inline-block', fontWeight: 'normal' };

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>
                    {details.reference} {!!details.user_reference && <>({details.user_reference})</>}
                </Title>
                <ActionBar>
                    {/* <ButtonToolbar>
                        <Btn title="Receipt">
                            <i className="fas fa-file-pdf" />
                        </Btn>
                        <Btn title="Create Invoice">
                            <i className="fas fa-file-invoice" />
                        </Btn>
                    </ButtonToolbar> */}
                    {(details.status === "unbilled") &&
                        <ButtonToolbar>
                            <Btn title="Create Invoice">
                                <i className="fas fa-file-invoice" />
                            </Btn>
                        </ButtonToolbar>
                    }

                    <ButtonToolbar>
                        {((details.status !== "invoiced") && (nonChoiceExpenseCategories.indexOf(details.category) === -1) && (!details.fromRequisition)) &&
                            <Btn href={`/app/expenses/${details.id}/edit`} title="Edit Expense">
                                <i className="fas fa-pencil-alt" />
                            </Btn>}
                        <Btn href="/app/expenses/new" title="New Expense">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-800 p-1 expenses">

                <div className="text-end">
                    TYPE: <span style={style1}>{details.isBill ? "Bill" : "Expense"}</span> <br />
                    REFERENCE: <span style={style1}>{details.reference}</span> <br />
                    {!!details.user_reference && <>
                        USER/VENDOR REF: <span style={style1}>{details.user_reference}</span> <br />
                    </>}

                    EXPENSE DATE: <span style={style1}>{moment(details.expense_date).format("DD MMMM YYYY")}</span> <br />
                    STATUS: <span style={style1}>{UCWords(details.status.replace(/-/g, " "))}</span><br />
                </div>

                {/* <div className="mt-5 mb-2">
                    <h5>Paid By</h5>
                    <span className="lead">Maurice Ntege</span> <br />
                    morsnegs@hotmail.com
                </div> */}

                <div className="my-3">
                    <span style={{ height: '100px' }} className="px-3 px-sm-4 d-inline-flex align-items-center justify-content-end bg-teal text-white font-weight-bold rounded">
                        UGX <span style={{ fontSize: '2rem' }} className="d-inline-block ms-2">{cur(details.total_amount).format()}</span>
                    </span>
                </div>

                <h6>Description</h6>
                <p>{details.description || "none"}</p>

                <h6>Other Details</h6>
                <Table size="sm" borderless style={{ maxWidth: '500px' }}>
                    <colgroup>
                        <col span="1" style={{ width: "50%" }} />
                        <col span="1" style={{ width: "50%" }} />
                    </colgroup>

                    <tbody>
                        {details.tax_amount > 0 &&
                            <>
                                <tr>
                                    <td>Pretax Amount</td>
                                    <td>UGX {currency(details.pretax_amount).format()}</td>
                                </tr>
                                <tr>
                                    <td>Tax</td>
                                    <td>UGX {currency(details.tax_amount).format()}</td>
                                </tr>
                            </>

                        }
                        <tr>
                            <td>Category</td>
                            <td>{UCWords(details.category.replace(/-/g, " "))}</td>
                        </tr>

                        <tr>
                            <td>Payment Method</td>
                            <td>{<Link to={`/app/accounts/${details.account_id}`}>{details.account.title}</Link>} via {UCWords(details.method.replace(/-/g, " "))}</td>
                        </tr>
                        <tr>
                            <td>Prices Tax Inclusive?</td>
                            <td>{details.isTaxInclusive ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                            <td>Is Billable?</td>
                            <td>{details.isBillable ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                            <td>From Requisition?</td>
                            <td>{details.fromRequisition ? "Yes" : "No"}</td>
                        </tr>
                    </tbody>
                </Table>

                {(details.items.length > 0) && <ExpenseItems items={details.items} />}

                {(nonChoiceExpenseCategories.indexOf(details.category) !== -1) &&
                    <p className='lead text-info fw-bold'>To view the breakdown go to payroll.</p>}



                {/* <div className="mt-5 mb-2">
                    <h5>Notes</h5>
                    <p>{faker.lorem.sentences(5)}</p>
                </div> */}

            </div>
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/expenses').ExpensetItemObject[]} props.items 
 * @returns 
 */
const ExpenseItems = ({ items }) => (
    <div className={`mt-3 mt-sm-4 mb-2 ${style}`}>
        <h5>Items</h5>

        <Table responsive style={{ minWidth: '600px' }} hover className="items-table">
            <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "35%", textAlign: 'center' }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "22%" }} />
                <col span="1" style={{ width: "15%" }} />
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Item</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th>Tax</th>
                </tr>
            </thead>
            <tbody>
                {items.map((i, idx) => (
                    <tr key={i.id}>
                        <td>{idx + 1}</td>
                        <td>{i.title}</td>
                        <td>{i.quantity}</td>
                        <td>{cur(i.unit_price).format()}</td>
                        <td>{cur(i.total_amount).format()}</td>
                        <td>{i.tax_percent}%</td>
                    </tr>
                ))}
            </tbody>

        </Table>

    </div>
)

const ExpenseDetails = () => {

    const { expenseid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setError(null);
        setLoaded(false);

        getExpense(expenseid, ['account', 'items', 'account'])
            .then(({ expense }) => {
                setDetails(expense);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [expenseid])

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading expense details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/edit" element={<EditExpense details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewExpense details={details} />} />
        </Routes>
    )

}

export default ExpenseDetails;