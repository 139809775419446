import fetchData, { APIURL, getData, postData } from "../fetch";


/**
 * @typedef {{
 *  id: string 
 *  vehicle_id: string
 *  bill_date: string
 *  unit: string
 *  no_units: number
 *  unit_cost: number
 *  total_cost: number
 *  signoff_date: ?string
 *  signed_off_by: ?string
 *  isPaid: boolean
 *  expense_id: ?string
 *  vehicle: import("./vehicles").VehicleObject
 *  expense: ?import("./expenses").ExpenseObject
 *  signed_off_contact: ?import("./auth").AuthObject
 *  reg_no: string
 *  manufacturer: string
 * }} HireObject
 */


/**
 * 
 * @param {HireObject} data 
 * @returns {Promise<{code: number, message: string, hire: HireObject}>}
 */
const createHire = data => postData({
    url: `${APIURL}/vehicle-hires`,
    data
})

/**
 * 
 * @param {HireObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, hire: HireObject}>}
 */
const updateHire = (data, id) => postData({
    url: `${APIURL}/vehicle-hires/${id}`,
    data
})

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, hires: HireObject[]}>}
 */
const getHires = (conditions = null, getAll = false) => {

    const obj = { url: `${APIURL}/vehicle-hires`, data: {} }

    if (conditions) obj.data = conditions;
    if (getAll) obj.data.expanded = 1;

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, hire: HireObject}>}
 */
const getHire = (id, includes = null) => {

    const obj = { url: `${APIURL}/vehicle-hires/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteHire = id => fetchData({ url: `${APIURL}/vehicle-hires/${id}`, method: "DELETE" })



/**
 * 
 * @param {string} id 
 * @returns {Promise<{code: number, message: string, hire: HireObject}>}
 */
const signoffHire = (id) => postData({ url: `${APIURL}/vehicle-hires/${id}/signoff` })

/**
 * 
 * @param {{account_id: string, method: string}} data 
 * @param {string} id 
 * @returns {Promise<{code: number, message: string, hire: HireObject}>}
 */
const processHire = (data, id) => postData({ url: `${APIURL}/vehicle-hires/${id}/process`, data })



export {
    createHire,
    getHire,
    getHires,
    updateHire,
    deleteHire,
    signoffHire,
    processHire,
}