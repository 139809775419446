import { Form, Row, Col, Accordion, ListGroup } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { errorAlert, infoAlert, successAlert, warningAlert } from '../../components/toastr';
import { SubmitButton, CancelButton } from '../../components/btns';
import { useNavigate } from 'react-router-dom';
import { getVehicles } from '../../resources/api/vehicles';
import { DateSelector, TimeSelector } from '../../components/datetimepicker';
import { Select } from '../../components/select';
import { getEvent } from '../../resources/api/events';
import moment from 'moment';
import { sortFunction } from '../../components/resources';
import { createSchedule, getEvents, updateSchedule } from '../../resources/api/schedules';

const { Control, Control: { Feedback }, Label } = Form;

/**
 * handle page for creating trip
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('../../resources/api/vehicles').ScheduleObject} props.details
 * @param {(details: import('../../resources/api/vehicles').ScheduleObject) => void} props.onSubmit
 */
const ScheduleForm = ({ details: ScheduleObject, action, onSubmit }) => {

    const [details, setDetails] = useState(ScheduleObject);
    const [items, setItems] = useState(ScheduleObject.items || []);

    const [vehicles, setVehicles] = useState([]);
    const [events, setEvents] = useState([]);
    const [products, setProducts] = useState({});

    const [deleted_items, setDeleted] = useState({ events: [], vehicles: [], products: [] });
    const [selected, setSelected] = useState({ events: [], products: [], vehicles: [] });

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getVehicles()
            .then(({ vehicles }) => {
                setVehicles(vehicles);
                successAlert("Loaded vehicles");
            })
            .catch(e => errorAlert(`Could not load vehicles, ${e}`))


        let d = moment(),
            d1 = d.format("YYYY-MM-DD"),
            d2 = d.add(14, "d").format("YYYY-MM-DD");

        getEvents(d1, d2)
            .then(({ events }) => {
                events
                    .sort((a, b) => sortFunction(a, b, "event_date"));
                setEvents(events
                    .map(e => ({
                        ...e,
                        title: `${e.client} - ${e.reference}`,
                        description: `${moment(e.event_date).format("DD MMM YYYY")} | ${e.location}`
                    })));
            })
            .catch(e => errorAlert(`Could not load events. Error ${e}`))
    }, [])

    const handleVehicleAdd = (ids) => {

        const new_ids = ids.filter(vid => (selected.vehicles.indexOf(vid) === -1));

        if (new_ids.length === 0) {
            errorAlert("All vehicles have already been added.");
            return;
        } else if (new_ids.length < ids.length) {
            warningAlert("Some vehicles have already been added.");
        }

        setSelected(sel => ({ ...sel, vehicles: [...sel.vehicles, ...new_ids] }));

        ids = new_ids;

        const _vehicles = vehicles
            .filter(i => (ids.indexOf(i.id) !== -1))
            .map(item => ({
                vehicle_id: item.id,
                reg_no: item.reg_no,
                events: []
            }));



        setItems(_items => ([..._items, ..._vehicles]))
    }

    const handleEventAdd = (vehicle_pos, event_ids) => {


        let _items = [...items],
            _item = _items[vehicle_pos];

        const _events = events
            .filter(i => (event_ids.indexOf(i.id) !== -1))
            .map(item => ({
                event_id: item.id,
                location: item.location,
                title: item.title,
                eta: "",
                action: "delivery",
                products: [],
                isNew: true
            }));

        _item.events = [..._item.events, ..._events];
        _items.splice(vehicle_pos, 1, _item);
        setItems(_items);


        //Load the event products

        const promises = event_ids.map(id => getEvent(id, ["products"]));

        infoAlert("Loading the event products. Please hold...");

        Promise.all(promises)
            .then((products) => {


                const _products = {};

                events
                    .filter(i => (event_ids.indexOf(i.id) !== -1))
                    .forEach(item => {

                        _products[item.id] = products[event_ids.findIndex(id => id === item.id)]
                            .event.products
                            .map(e => ({ ...e, title: `${e.product} (${e.dimensions})` }));
                    });

                console.log(_products);
                setProducts(p => ({ ...p, ..._products }))

                // .map(p => ({
                //     event_product_id: p.id,
                //     product: p.product,
                //     max_quantity: p.qty,
                //     quantity: ""
                // }));





                successAlert("Event products loaded.");

            })
            .catch(e => errorAlert(`Unable load products. ${e}`));



    }


    const handleProductAdd = (vehicle_pos, event_pos, event_id, product_ids) => {




        let _items = [...items],
            _item = _items[vehicle_pos],
            _eproducts = _item.events[event_pos].products;

        const _products = products[event_id]
            .filter(i => (product_ids.indexOf(i.id) !== -1))
            .map(p => ({
                event_product_id: p.id,
                product: `${p.product} (${p.dimensions})`,
                max_quantity: p.qty,
                quantity: "",
                isNew: true
            }));

        _item.events[event_pos].products = [..._eproducts, ..._products];
        _items.splice(vehicle_pos, 1, _item);
        setItems(_items);
    }

    const setETA = (v_pos, e_pos, time) => {
        let _items = [...items],
            _item = _items[v_pos];

        _item.events[e_pos].eta = time;
        _item.events[e_pos].hasChanged = true;
        _item.events.sort((a, b) => sortFunction(a, b, "eta"));
        _items.splice(v_pos, 1, _item);
        setItems(_items);
    }

    const setAction = (v_pos, e_pos, action) => {
        let _items = [...items],
            _item = _items[v_pos];

        _item.events[e_pos].action = action;
        _item.events[e_pos].hasChanged = true;
        _items.splice(v_pos, 1, _item);
        setItems(_items);
    }

    const setQuantity = (v_pos, e_pos, prod_pos, qty) => {
        let _items = [...items],
            _item = _items[v_pos];

        _item.events[e_pos].products[prod_pos].quantity = qty;
        _item.events[e_pos].products[prod_pos].hasChanged = true;

        _items.splice(v_pos, 1, _item);
        setItems(_items);
    }

    const deleteVehicle = (v_pos, e_pos) => {

        let _items = [...items];

        const [v] = _items.splice(v_pos, 1);
        setSelected(sel => ({ ...sel, vehicles: sel.vehicles.filter(vid => (vid !== v[0].vehicle_id)) }));

        v.events.forEach(e => {
            if (!!e.id) setDeleted(d => ({ ...d, events: d.events.concat(e.id) }));

            e.products.forEach(p => {
                if (!!p.id) setDeleted(d => ({ ...d, products: d.products.concat(p.id) }));;
            })

        })

        setItems(_items);
    }

    const deleteEvent = (v_pos, e_pos) => {

        let _items = [...items],
            _item = _items[v_pos];

        const [e] = _item.events.splice(e_pos, 1);
        setSelected(sel => ({ ...sel, events: sel.events.filter(vid => (vid !== e[0].event_id)) }));


        if (!!e.id) setDeleted(d => ({ ...d, events: d.events.concat(e.id) }));

        console.log(e);

        e.products.forEach(p => {
            if (!!p.id) setDeleted(d => ({ ...d, products: d.products.concat(p.id) }));;
        })


        _items.splice(v_pos, 1, _item);
        setItems(_items);
    }

    const deleteProduct = (v_pos, e_pos, p_pos) => {

        let _items = [...items],
            _item = _items[v_pos];

        const [p] = _item.events[e_pos].products.splice(p_pos, 1);
        setSelected(sel => ({ ...sel, products: sel.products.filter(vid => (vid !== p[0].event_product_id)) }));

        if (!!p.id) setDeleted(d => ({ ...d, products: d.products.concat(p.id) }));;

        _items.splice(v_pos, 1, _item);
        setItems(_items);
    }

    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        let _data;

        if (action === "create") {
            let _items = [];

            items.forEach(i => {

                i.events.forEach(e => {
                    let _item = {
                        vehicle_id: i.vehicle_id,
                        event_id: e.event_id,
                        action: e.action,
                        eta: e.eta,
                        products: e.products.filter(i => !!i.quantity).map(p => ({
                            event_product_id: p.event_product_id,
                            quantity: p.quantity
                        }))
                    };
                    _items.push(_item);
                })
            })

            _data = ({
                ...details,
                items: _items
            })
        } else {

            let updated_events = [], new_events = [], new_products = [], updated_products = [];

            items.forEach(i => {

                i.events.forEach(e => {

                    if (e.isNew) {
                        let _item = {
                            vehicle_id: i.vehicle_id,
                            event_id: e.event_id,
                            action: e.action,
                            eta: e.eta,
                            products: e.products.filter(i => !!i.quantity).map(p => ({
                                event_product_id: p.event_product_id,
                                quantity: p.quantity
                            }))
                        };

                        new_events.push(_item);

                    } else if (!!e.id) {
                        e.products.filter(i => !!i.quantity).forEach(p => {

                            if (p.isNew) {
                                new_products.push({
                                    schedule_item_id: e.id,
                                    event_product_id: p.event_product_id,
                                    quantity: p.quantity
                                })
                            } else if (!!p.id && p.hasChanged) {
                                updated_products.push({
                                    id: p.id,
                                    event_product_id: p.event_product_id,
                                    quantity: p.quantity
                                })
                            }
                        })
                    }

                    if (!!e.id && e.hasChanged) {
                        updated_events.push({
                            id: e.id,
                            vehicle_id: i.vehicle_id,
                            event_id: e.event_id,
                            action: e.action,
                            eta: e.eta
                        })
                    }

                })
            })

            _data = ({
                ...details,
                updated_events,
                new_events,
                new_products,
                updated_products,
                deleted_items
            })

            // console.log(_data);
            // setSubmitting(false);
            // return;

        }



        const promise = (action === "edit") ? updateSchedule(_data, details.id) : createSchedule(_data);

        promise
            .then(({ schedule, message }) => {
                successAlert(message);
                onSubmit(schedule);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Details</h4>
            <Row>
                <Col sm={6}>
                    <Label className="form-field-title">Schedule Date</Label>
                    <DateSelector
                        value={details.schedule_date}
                        onChange={date =>
                            setDetails(d => ({
                                ...d,
                                schedule_date: date
                            }))}
                        // placeholder="when is the event taking place?"
                        required={true}
                        minDate="2021-01-01"

                    />
                    <Feedback type="invalid">
                        A valid date must be provided.
                    </Feedback>
                </Col>
            </Row>
            <h4 className="form-section-label">Schedule</h4>
            {
                items.map((item, id) => (
                    <div key={id} className='my-4 pb-3 border-bottom'>
                        <h5>
                            {item.reg_no}
                            <a className='ms-1 small text-danger' href="#." onClick={e => deleteVehicle(id)}><i className='fas fa-trash-alt' /></a>
                        </h5>

                        <Accordion>
                            {item.events.map((event, e_pos) => (
                                <Accordion.Item key={e_pos} eventKey={e_pos}>
                                    <Accordion.Header>
                                        {event.title} at {event.location} {!!event.eta && <>| ETA: {event.eta}</>}
                                        <a className='ms-2 small text-danger' href="#." onClick={e => deleteEvent(id, e_pos)}><i className='fas fa-trash-alt' /></a>
                                    </Accordion.Header>
                                    <Accordion.Body className='px-1'>
                                        <Row className='g-1'>
                                            <Col sm={4}>
                                                <Label className='form-field-title'>Estimated Time of Arrival</Label>
                                                <TimeSelector
                                                    value={event.eta}
                                                    required
                                                    size="sm"
                                                    onChange={t => setETA(id, e_pos, t)}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                <Label className='form-field-title'>Action</Label>
                                                <Form.Select
                                                    required
                                                    size="sm"
                                                    value={event.action}
                                                    onChange={e => setAction(id, e_pos, e.currentTarget.value)}
                                                >
                                                    <option value="pickup">Pickup</option>
                                                    <option value="delivery">Delivery</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>

                                        <h6 className='mt-2 mt-sm-4'>Products</h6>
                                        <ListGroup variant="flush">
                                            {event.products.map((p, p_id) => (
                                                <ListGroup.Item key={p_id} className='px-1'>
                                                    <Row className='align-items-center g-1'>
                                                        <Col xs={"auto"}>
                                                            {p.product} (Max: {p.max_quantity})
                                                        </Col>
                                                        <Col xs={"auto"}>
                                                            <Control
                                                                size="sm"
                                                                type="number"
                                                                min={1}
                                                                placeholder='quantity'
                                                                max={p.max_quantity}
                                                                value={p.quantity}
                                                                onChange={e => setQuantity(id, e_pos, p_id, e.currentTarget.value)}
                                                            />
                                                        </Col>
                                                        <Col xs={"auto"}>
                                                            <a className='ms-2 small text-danger' href="#." onClick={e => deleteProduct(id, e_pos, p_id)}><i className='fas fa-trash-alt' /></a>
                                                        </Col>

                                                    </Row>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>

                                        <Select
                                            size="sm"
                                            variant="secondary"
                                            className='rounded-pill px-2'
                                            items={products[event.event_id] || []}
                                            onSelect={ids => handleProductAdd(id, e_pos, event.event_id, ids)}
                                            maxItems={null}
                                        >
                                            <i className='fas fa-calendar-plus me-2' />Add Product(s)
                                        </Select>

                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>

                        <Row className='g-1'>
                            <Col sm={4} className='my-2'>
                                <Select
                                    size="sm"
                                    variant="secondary"
                                    className='rounded-pill px-2'
                                    items={events}
                                    onSelect={ids => handleEventAdd(id, ids)}
                                    maxItems={null}
                                >
                                    <i className='fas fa-calendar-plus me-2' />Add Event(s)
                                </Select>
                            </Col>
                        </Row>
                    </div>
                ))
            }
            <Row className='g-1'>
                <Col sm={4} className='my-2'>
                    <Select
                        size="sm"
                        variant="secondary"
                        className='rounded-pill px-2'
                        items={vehicles.map(v => ({ id: v.id, title: v.reg_no }))}
                        onSelect={handleVehicleAdd}
                        maxItems={null}
                    >
                        <i className='fas fa-car me-2' />Add Vehicle(s)
                    </Select>
                </Col>
            </Row>


            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Schedule" : "Create Schedule"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting} onClick={() => navigate(-1)}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form >
    )
}


export default ScheduleForm;