import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn } from '../../components/pageheader';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useEffect, useState, useContext, useMemo } from 'react';
import { ErrorLoading, PageLoading } from '../../components/loading';
import moment from "moment";
import { executePayments, getApprovedRequisitions } from '../../resources/api/requisitions';
import { Link, useNavigate } from 'react-router-dom';
import cur from '../../components/currency';
import { SubmitButton } from '../../components/btns';
import { errorAlert, successAlert } from '../../components/toastr';
import { AppUserContext } from '../../App';
import { getAccounts } from '../../resources/api/accounts';
import { assortedPaymentMethods, paymentMethods } from '../../assets/constants';
import { UCWords } from '../../components/resources';



const ProcessRequisitions = () => {

    const { profile } = useContext(AppUserContext);


    const [requisitions, setRequisitions] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();
    const [isSubmitting, setSubmitting] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState("");
    const [method, setMethod] = useState("");

    const [approved_items, setApprovedItems] = useState([]);

    const navigate = useNavigate();

    const displayAccounts = useMemo(() => {

        const _types = assortedPaymentMethods[method] || [];

        return accounts.filter(a => _types.indexOf(a.account_type) !== -1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [method, JSON.stringify(accounts)])




    useEffect(() => {

        setError(null);
        setLoaded(false);

        if (profile.permission_level > 2) {
            setLoaded(true);
            return;
        }

        getAccounts()
            .then(({ accounts }) => {
                setAccounts(accounts);
                successAlert("Successfully loaded the accounts list");
            })
            .catch(e => errorAlert(e))

        getApprovedRequisitions()
            .then(({ requisitions }) => {
                setRequisitions(requisitions.map(r => ({ ...r, toProcess: false })));
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [profile.permission_level])

    const approveItem = id => setApprovedItems(i => i.concat(id));

    const removeItem = id => setApprovedItems(i => i.filter(i => i !== id));


    // Submit the data backend
    const handleSubmit = () => {

        if (!account) {
            return errorAlert("Please set the account where this expense is coming from");
        }

        const data = {
            account,
            method,
            ids_to_process: approved_items
        }

        setSubmitting(true);

        executePayments(data)
            .then(({ message, reference }) => {
                successAlert(message);
                // infoAlert(`Transaction Reference: ${reference}`)
                navigate(`/app/requisitions/payments/${reference}`);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    const nav_items = [
        { title: 'Requisitions', href: '/app/requisitions' },
        { title: "Process" }
    ];

    //load events details

    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to process requisitions.</ErrorLoading>
    }

    if (!isLoaded) {
        return <PageLoading>Loading requisition details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>Process Requisitions</Title>
                <ActionBar>
                    <Btn href="/app/requisitions/new">
                        <i className="fas fa-plus-circle me-1" />New Requisition
                    </Btn>
                </ActionBar>
            </PageHeader>


            <div className="max-1000">


                <Table responsive hover style={{ maxWidth: '600px' }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Ref</th>
                            <th>User</th>
                            <th>Date</th>
                            <th style={{ textAlign: "right" }}>Amount</th>
                            <th>...</th>
                        </tr>

                    </thead>
                    <tbody>
                        {(requisitions.length === 0) &&
                            <tr>
                                <td colSpan={5} className="text-center">No approved requisitions to display</td>
                            </tr>
                        }
                        {requisitions.map((e, i) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td><Link to={`/app/requisitions/${e.id}`}>{e.reference}</Link></td>
                                <td>{e.display_name}</td>
                                <td>{moment(e.requisition_date).format("DD MMM YYYY")}</td>
                                <td style={{ textAlign: 'right' }}>{cur(e.total_amount).format()}</td>
                                <td>
                                    {(approved_items.indexOf(e.id) !== -1) ?
                                        <Button size="sm" variant="link" onClick={() => removeItem(e.id)}>
                                            <i className='fas fa-times-circle text-danger' />
                                        </Button>
                                        :
                                        <Button size="sm" variant="link" onClick={() => approveItem(e.id)}>
                                            <i className='fas fa-check-circle text-success' />
                                        </Button>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={4} />
                            <th style={{ textAlign: "right" }}>
                                {cur(requisitions.reduce((p, c) => p + c.total_amount, 0)).format()}
                            </th>
                            <th />
                        </tr>

                    </tfoot>
                </Table>

                {(approved_items.length > 0) &&
                    <>
                        <h6 className='mt-4'>{approved_items.length} requisitions to be processed</h6>
                        <Row className='my-2'>
                            <Col sm={4} className="my-2">
                                <Form.Label className="form-field-title">Payment Method</Form.Label>
                                <Form.Select
                                    as="select"
                                    value={method}
                                    onChange={e => setMethod(e.currentTarget.value)}
                                    required
                                >
                                    <option value=""></option>
                                    {paymentMethods.map(c => <option key={c} value={c}>{UCWords(c.replace(/-/g, " "))}</option>)}
                                </Form.Select>
                            </Col>
                            <Col sm={4} className="my-2">
                                <Form.Label className="form-field-title">Payment Account</Form.Label>
                                <Form.Select
                                    as="select"
                                    value={account}
                                    onChange={e => setAccount(e.currentTarget.value)}
                                    required
                                >
                                    <option value=""></option>
                                    {displayAccounts.map(c => <option key={c.id} value={c.id}>{c.title}</option>)}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="my-2">
                                <SubmitButton onClick={handleSubmit} isSubmitting={isSubmitting}>
                                    Submit
                                </SubmitButton>
                            </Col>

                        </Row>
                    </>
                }


            </div>
        </>
    )

}

export default ProcessRequisitions;