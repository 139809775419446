import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { error as errorAlert, successAlert } from './../../components/toastr';
import { SubmitButton, CancelButton } from './../../components/btns';
import { DateSelector } from '../../components/datetimepicker';
import { UCWords } from '../../components/resources';
import { LabourActivities } from '../../assets/constants';
import { PageLoading } from '../../components/loading';
import { Select } from '../../components/select';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import cur from '../../components/currency';
import { getAllStaff } from '../../resources/api/payroll';
import { createLabour, updateLabour } from '../../resources/api/events';

const { Control, Control: { Feedback }, Label } = Form;

const wokerObject = {
    staff_id: "",
    display_name: "",
    overtime_hrs: 0,
    isRisk: false,
    amount: 0
}


/**
 * handle page for creating trip
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('../../resources/api/events').EventObject} props.event_details
 */
const LabourForm = ({ event_details, details: labourObject, action, onSubmit }) => {


    const [details, setDetails] = useState(labourObject);
    const [workers, setWorkers] = useState(labourObject.workers || []);
    const [clients, setClients] = useState([]);

    const [deleted_workers, setDeletedWorkers] = useState([]);

    const [loaded, setLoaded] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    const workers_list = useMemo(() => {
        const _workers = workers.map(i => i.staff_id);
        return clients.filter(c => _workers.indexOf(c.id) === -1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workers.length, clients.length])

    const addWorkers = ids => {
        const _workers = clients
            .filter(w => ids.indexOf(w.id) !== -1)
            .map(w => ({
                ...wokerObject,
                amount: (details.base_rate || 0),
                display_name: w.title,
                staff_id: w.id,
                isNew: 1
            })
            );
        setWorkers([...workers.concat(_workers)])
    }

    const updateWorker = (field, value, idx) => {

        const _workers = [...workers];
        let _w = _workers[idx];
        _w = { ..._w, [field]: value, hasChanged: 1 };

        if (!!details.base_rate) {
            _w.amount =
                parseFloat(details.base_rate) +
                ((parseFloat(details.overtime_rate) || 0) * parseFloat(_w.overtime_hrs)) +
                (_w.isRisk ? parseFloat(details.risk_factor || 0) : 0);
        }

        _workers.splice(idx, 1, _w);
        setWorkers(_workers);
    }

    const deleteWorker = idx => {

        const _workers = [...workers],
            [_w] = _workers.splice(idx, 1);

        if ('id' in _w) {
            setDeletedWorkers(w => w.concat(_w.id));
        }
        setWorkers(_workers);
    }

    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }


        if (workers.length < 1) {
            errorAlert("You must select at a least staff member for this event.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        let promise;

        if (action === "create") {
            promise = createLabour({ ...details, workers, payroll_type: "event-labour", event_id: event_details.id });
        } else {
            promise = updateLabour({
                ...details,
                new_workers: workers.filter(p => !!p.isNew),
                updated_workers: workers.filter(p => (!p.isNew && !!p.hasChanged)),
                deleted_workers
            }, details.id);
        }

        promise
            .then(({ payment, message }) => {
                successAlert(message);
                onSubmit(payment);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    useEffect(() => {
        getAllStaff()
            .then(({ staff }) => {
                setClients(
                    staff.map(c => ({ ...c, title: c.display_name, description: `${c.email} ${c.telephone}` }))
                );
            })
            .catch(e => errorAlert(e))
            .finally(() => setLoaded(true));
    }, []);

    useEffect(() => {

        const _workers = [...workers];
        _workers.map(_w => ({
            ..._w,
            amount: _w.amount =
                parseFloat(details.base_rate) +
                ((parseFloat(details.overtime_rate) || 0) * parseFloat(_w.overtime_hrs)) +
                (_w.isRisk ? parseFloat(details.risk_factor || 0) : 0)
        }))
        setWorkers(_workers);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details.base_rate, details.risk_factor, details.overtime_rate]);


    if (!loaded) return <PageLoading>Loading clients...</PageLoading>;

    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Details</h4>
            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Action/Activity</Label>
                    <Form.Select
                        value={details.activity}
                        onChange={e => setDetails({ ...details, activity: e.currentTarget.value })}
                        required
                    >
                        <option value="">Select an action/activity</option>
                        {LabourActivities.map(t => <option value={t} key={t}>{UCWords(t)}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        Please choose an action
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Activity Date</Label>
                    <DateSelector
                        value={details.activity_date}
                        onChange={date =>
                            setDetails(d => ({
                                ...d,
                                activity_date: date
                            }))}
                        placeholder="when was the work done"
                        required={true}
                        minDate="2021-01-01"

                    />
                    <Feedback type="invalid">
                        A valid date must be provided.
                    </Feedback>
                </Col>
            </Row>
            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Base Rate</Label>
                    <Control
                        value={details.base_rate}
                        type="number"
                        step={1}
                        onChange={e => setDetails({ ...details, base_rate: e.currentTarget.value })}
                        placeholder="40000"
                        required={true}
                    />
                    <Feedback type="invalid">
                        A base rate must be provided
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Overtime Rate (per hour)</Label>
                    <Control
                        value={details.overtime_rate}
                        type="number"
                        step={1}
                        onChange={e => setDetails({ ...details, overtime_rate: e.currentTarget.value })}
                        placeholder="40000"
                        required={true}
                    />
                    <Feedback type="invalid">
                        Overtime rate must be provided
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Climbing Rate</Label>
                    <Control
                        value={details.risk_factor}
                        type="number"
                        step={100}
                        onChange={e => setDetails({ ...details, risk_factor: e.currentTarget.value })}
                        placeholder="2"
                        required={true}
                    />
                    <Feedback type="invalid">
                        Climbing rate must be provided and must be a multiple of 100.
                    </Feedback>
                </Col>
            </Row>


            <h4 className="form-section-label">Workers</h4>
            {workers.length === 0 &&
                <Form.Text as={"div"} className="my-2 lead">
                    No staff added yet. Add a staff to continue.
                </Form.Text>}

            <Row className="d-none d-sm-flex">
                <Col sm={4}>
                    <Label className="form-field-title">Name</Label>
                </Col>
                <Col sm={2}>
                    <Label className="form-field-title">Overtime Hrs</Label>
                </Col>
                <Col sm={3}>
                    <Label className="form-field-title">Climbing</Label>
                </Col>
                <Col sm={3}>
                    <Label className="form-field-title">Amount</Label>
                </Col>
            </Row>

            {workers.map((w, idx) => (
                <div key={idx} className="my-4 my-sm-0 g-2 border-start border-primary ps-1" style={{ borderLeftWidth: '3px' }}>
                    <Row key={idx} className="g-2">
                        <Col sm={4}>
                            <Label className="form-field-title d-sm-none">Name</Label>
                            <Form.Control
                                value={w.display_name}
                                readOnly
                                size="sm"
                            />
                        </Col>
                        <Col sm={2}>
                            <Label className="form-field-title d-sm-none">Overtime Hours</Label>
                            <Form.Control
                                value={w.overtime_hrs}
                                onChange={e => updateWorker("overtime_hrs", e.currentTarget.value, idx)}
                                placeholder="Hours"
                                size="sm"
                                type="number"
                                step={0.5}
                                min={0}
                                required
                            />
                            <Feedback type="invalid">
                                Must be in multiples of 0.5
                            </Feedback>

                        </Col>
                        <Col sm={3}>
                            <Form.Check
                                type="checkbox"
                                checked={w.isRisk}
                                label={w.isRisk ? "Climbs" : "Does not climb"}
                                onChange={() => updateWorker("isRisk", !w.isRisk, idx)}
                                id={`isRisk${idx}`}
                            />
                        </Col>

                        <Col sm={3}>
                            <Label className="form-field-title d-sm-none">Amount</Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    value={cur(w.amount, 0).format()}
                                    readOnly
                                    size="sm"
                                />
                                <Button
                                    variant="outline-danger"
                                    className="border-0"
                                    onClick={() => deleteWorker(idx)}
                                >
                                    <i className="fas fa-times" />
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                </div>
            ))}

            <div className="mt-4">

                <Select
                    className='rounded-pill'
                    size="sm"
                    maxItems={12}
                    onSelect={addWorkers}
                    items={workers_list}
                >
                    <i className="fas fa-plus-circle me-2" />Add Staff
                </Select>
            </div>


            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Schedule" : "Submit Schedule"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting} onClick={() => navigate(-1)}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form>
    )
}


export default LabourForm;