import fetchData, { APIURL, getData, postData } from "../fetch";


/**
 * @typedef {{
 *  id: string 
 *  requisition_id: string
 *  category: string
 *  unit_price: number
 *  quantity: number
 *  notes: string
 *  amount: number
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string,
 * }} RequisitionItemObject
 */

/**
 * @typedef {{
 *  id: string 
 *  reference: string
 *  requisition_date: string
 *  contact_id: string
 *  description: string
 *  total_amount: number
 *  client_id: ?string
 *  event_id: ?string
 *  status: "pending-approval"|"approved"|"processed"
 *  isApproved: boolean
 *  approved_by: ?string
 *  date_approved: ?string
 *  isPaid: boolean
 *  date_paid: ?string
 *  requisition_payment_id: ?string
 *  items: RequisitionItemObject[]
 * }} RequisitionObject
 */


/**
 * @typedef {{
 *  id: string 
 *  reference: string
 *  amount: number
 * account: string
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string,
 *  requisitions: RequisitionObject[]
 * }} RequisitionPaymentObject
 */


/**
 * 
 * @param {RequisitionObject} data 
 * @returns {Promise<{code: number, message: string, requisition: RequisitionObject}>}
 */
const createRequisition = data => postData({
    url: `${APIURL}/requisitions`,
    data
})

/**
 * 
 * @param {RequisitionObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, requisition: RequisitionObject}>}
 */
const updateRequisition = (data, id) => postData({
    url: `${APIURL}/requisitions/${id}`,
    data
})

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, requisitions: RequisitionObject[]}>}
 */
const getRequisitions = (getAll = false) => {

    const obj = { url: `${APIURL}/requisitions` }

    if (getAll) obj.data = { expanded: 1 }

    return getData(obj);
}

/**
 *  
 * @returns {Promise<{code: number, message: string, requisitions: RequisitionObject[]}>}
 */
const getApprovedRequisitions = () => getData({ url: `${APIURL}/requisitions/approved` });



/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, requisition: RequisitionObject}>}
 */
const getRequisition = (id, includes = null) => {

    const obj = { url: `${APIURL}/requisitions/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}

/**
 * 
 * @param {string} id 
 * @param {"draft"|"awaiting-approval"|"approved"} status 
 * @returns  {Promise<{code: number, message: string, requisition: RequisitionObject}>}
 */
const changeRequisitionStatus = (id, status) => postData({ url: `${APIURL}/requisitions/${id}/status/${status}` });


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteRequisition = id => fetchData({ url: `${APIURL}/requisitions/${id}`, method: "DELETE" })


/**
 * 
 * @param {{account: string}} data 
 * @returns {Promise<{code: number, message: string}>}
 */
const executePayments = (data) => postData({ url: `${APIURL}/requisitions/process`, data })

/**
 * 
 * @returns {Promise<{code: number, message: string, payments: RequisitionPaymentObject[]}>}
 */
const getPayments = () => fetchData({ url: `${APIURL}/requisitions/payments` })



/**
 * Get details of a requisition payment
 * @param {string} id
 * @returns {Promise<{code: number, message: string, payment: RequisitionPaymentObject[]}>}
 */
const getPayment = (id) => fetchData({ url: `${APIURL}/requisitions/payments/${id}` })


export {
    createRequisition,
    getRequisition,
    getRequisitions,
    updateRequisition,
    deleteRequisition,
    changeRequisitionStatus,
    getApprovedRequisitions,
    executePayments,
    getPayments,
    getPayment
}