/* eslint-disable react-hooks/exhaustive-deps */

import $ from 'jquery';
import 'jquery-datetimepicker/build/jquery.datetimepicker.full';
import 'jquery-datetimepicker/build/jquery.datetimepicker.min.css';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { useRef, useEffect, useCallback } from 'react';

/**
 * Displays a COntrol field with a date and time selector
 * @param {{
 * value: string;
 * onChange: (date: string) => void,
 * required: boolean,
 * placeholder: string,
 * minHour: number, 
 * maxHour: number,
 * timeStep: number,
 * minDate: string
 * }} props 
 */
const DateTimeSelector = ({
    value,
    placeholder,
    onChange,
    required = true,
    minHour = 0,
    maxHour = 24,
    minDate = 0,
    timeStep = 15
}) => {

    const { Control } = Form;
    const inputEl = useRef(null);

    const allowTimes = useCallback(() => {

        const step = timeStep < 10 ? 10 : timeStep,
            min = minHour < 0 ? 0 : (minHour > 23 ? 23 : minHour),
            max = maxHour > 24 ? 24 : (maxHour < min ? min + 1 : maxHour),
            times = [];

        let v = 0, i = min;

        while (i < max) {
            times.push(moment().hours(i).minutes(v).format('HH:mm'));

            v += step;

            while (v >= 60) {
                v -= 60;
                i += 1;
            }
        }
        return times;

    }, [minHour, maxHour, timeStep])

    useEffect(() => {


        const el = inputEl.current;

        $(el).datetimepicker({
            format: 'd F Y, H:i',
            value: value ? moment(value).format('DD MMMM YYYY, HH:mm') : null,
            // format: 'Y-m-d H:i',
            // value: value,
            minDate: minDate || 0,
            timepicker: true,
            dayOfWeekStart: 1,
            allowTimes: allowTimes(),
            onSelectTime: ct => onChange(moment(ct).format('YYYY-MM-DD HH:mm:ss')),
            onSelectDate: ct => onChange(moment(ct).format('YYYY-MM-DD HH:mm:ss'))
        });

        return () => {
            $(el).datetimepicker('destroy');
        }
    }, [inputEl, minDate])

    return (
        <Control ref={inputEl} placeholder={placeholder} required={required} />
    )
}

/**
 * Displays a field with date seelctor
 * @param {{
 * value: string;
 * onChange: (date: string) => void,
 * required: boolean,
 * placeholder: string,
 * minDate: string
 * maxDate: string|number
 * }} props 
 */
const DateSelector = ({
    value,
    placeholder,
    onChange,
    minDate = false,
    maxDate = false,
    required = true
}) => {
    const { Control } = Form;
    const inputEl = useRef(null);

    useEffect(() => {

        const el = inputEl.current;

        $(el).datetimepicker({
            format: 'd F Y',
            value: value ? moment(value).format('DD MMMM YYYY') : null,
            // format: 'Y-m-d H:i',
            // value: value,
            minDate: minDate,
            maxDate: maxDate,
            timepicker: false,
            dayOfWeekStart: 1,
            onSelectTime: ct => onChange(moment(ct).format('YYYY-MM-DD')),
            onSelectDate: ct => onChange(moment(ct).format('YYYY-MM-DD'))
        });

        return () => {
            $(el).datetimepicker('destroy');
        }
    }, [inputEl, minDate, value])

    return (
        <Control ref={inputEl} placeholder={placeholder} required={required} />
    )
}


/**
 * Displays a field with date seelctor
 * @param {{
* value: string;
* onChange: (date: string) => void,
* required: boolean,
* placeholder: string,
* minDate: string
* maxDate: string
* }} props 
*/
const TimeSelector = ({
    value,
    placeholder,
    onChange,
    required = true,
    size = null
}) => {
    const { Control } = Form;
    const inputEl = useRef(null);

    useEffect(() => {

        const el = inputEl.current;

        $(el).datetimepicker({
            format: 'H:i',
            value: value ? moment(value, "HH:mm").format('HH:mm') : null,
            step: 15,
            datepicker: false,
            onSelectTime: ct => onChange(moment(ct).format('HH:mm'))
        });

        return () => {
            $(el).datetimepicker('destroy');
        }
    }, [inputEl, value])

    return (
        <Control size={size} ref={inputEl} placeholder={placeholder} required={required} />
    )
}

export { DateSelector, DateTimeSelector, TimeSelector };
export default DateTimeSelector;