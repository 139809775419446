import fetchData, { APIURL, getData, postData } from "../fetch";


/**
 * @typedef {{
 *  id: string 
 *  maintenace_id: string
 *  part: string
 *  notes: string
 *  action: "repair"|"replace"|"check"
 *  amount: number
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string,
 * }} MaintenanceItemObject
 */

/**
 * @typedef {{
 *  id: string 
 *  reference: string
 *  vehicle_id: string
 *  maintenance_date: string
 *  odometer_reading: number
 *  type: "repair"|"service"
 *  actions: string
 *  observations: number
 *  remarks: string
 *  garage: string
 *  total_cost: number
 *  signoff_date: ?string
 *  signed_off_by: ?string
 *  isPaid: boolean
 *  expense_id: ?string
 *  vehicle: import("./vehicles").VehicleObject
 *  expense: ?import("./expenses").ExpenseObject
 *  signed_off_contact: ?import("./auth").AuthObject
 *  items: MaintenanceItemObject[]
 *  reg_no: string
 *  manufacturer: string
 * }} MaintenanceObject
 */




/**
 * 
 * @param {MaintenanceObject} data 
 * @returns {Promise<{code: number, message: string, maintenance: MaintenanceObject}>}
 */
const createMaintenance = data => postData({
    url: `${APIURL}/maintenance`,
    data
})

/**
 * 
 * @param {MaintenanceObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, maintenance: MaintenanceObject}>}
 */
const updateMaintenance = (data, id) => postData({
    url: `${APIURL}/maintenance/${id}`,
    data
})

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, maintenances: MaintenanceObject[]}>}
 */
const getMaintenances = (getAll = false) => {

    const obj = { url: `${APIURL}/maintenance` }

    if (getAll) obj.data = { expanded: 1 }

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, maintenance: MaintenanceObject}>}
 */
const getMaintenance = (id, includes = null) => {

    const obj = { url: `${APIURL}/maintenance/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteMaintenance = id => fetchData({ url: `${APIURL}/maintenance/${id}`, method: "DELETE" })



/**
 * 
 * @param {string} id 
 * @returns {Promise<{code: number, message: string, maintenance: MaintenanceObject}>}
 */
const signoffMaintenance = (id) => postData({ url: `${APIURL}/maintenance/${id}/signoff` })

/**
 * 
 * @param {{account_id: string, method: string}} data 
 * @param {string} id 
 * @returns {Promise<{code: number, message: string, maintenance: MaintenanceObject}>}
 */
const processMaintenance = (data, id) => postData({ url: `${APIURL}/maintenance/${id}/process`, data })



export {
    createMaintenance,
    getMaintenance,
    getMaintenances,
    updateMaintenance,
    deleteMaintenance,
    signoffMaintenance,
    processMaintenance,
}