import { useContext } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import { Tab } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { ProductPayments } from './productpayments/ProductPayments';
import PageHeader, { Title } from '../../components/pageheader';
import BNav from '../../components/breadcrumb';
import { StaffPayments } from './staff-payments/StaffPayments';
import { WorkDone } from './workdone/WorkDone';
import { Expenses } from './expenses/Expenses';
import { VehicleExpenses } from './vehiclesexpenses/VehicleExpenses';

const Analytics = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 1) {
        return <ErrorLoading>You do not have the permission to access expenses.</ErrorLoading>
    }

    const nav_items = [
        { title: "Analytics" }
    ];

    return (

        <>
            <BNav items={nav_items} />

            <PageHeader maxWidth="1400">
                <Title>
                    Management Analytics
                </Title>
            </PageHeader>

            <div className='max-1400'>

                <Tab.Container>
                    <Nav fill variant="tabs" className='flex-column flex-sm-row'>
                        <Nav.Item>
                            <Nav.Link as={Link} to={`/app/analytics/payments`} eventKey="payments">Product Payments</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} to={`/app/analytics/work-done`} eventKey="work_done">Work Done</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} to={`/app/analytics/expenses`} eventKey="expenses">Expenses</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} to={`/app/analytics/staff-payments`} eventKey="staff_payments">Staff Payments</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} to={`/app/analytics/vehicle-expenses`} eventKey="vehicles">Vehicle Expenses</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className='py-2 py-sm-4'>
                        <Routes>
                            <Route path="payments" element={<ProductPayments />} />
                            <Route path="staff-payments" element={<StaffPayments />} />
                            <Route path="work-done" element={<WorkDone />} />
                            <Route path="expenses" element={<Expenses />} />
                            <Route path="vehicle-expenses" element={<VehicleExpenses />} />
                        </Routes>
                    </Tab.Content>
                </Tab.Container>


            </div>
        </>



    )
};

export default Analytics;