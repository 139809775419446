import { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { Accordion, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Nav from '../../components/breadcrumb';
import cur from '../../components/currency';
import { ErrorLoading, PageLoading } from '../../components/loading';
import PageHeader, { ActionBar, ActionButton as Btn, ButtonToolbar, Title } from '../../components/pageheader';
import { groupBy, sortFunction, UCWords } from '../../components/resources';
import { getPaidPayments } from '../../resources/api/payroll';
import { APIURL } from '../../resources/fetch';


/**
 * handle for /app/expenses/:expenseid
 * @param {Object} props
 * 
 */
const PayrollExpenseDetails = () => {

    const { expenseid } = useParams();

    const nav_items = [
        { title: 'Payroll', href: '/app/payroll' },
        { title: 'Expenses', href: '/app/payroll/expenses' },
        { title: expenseid }
    ];

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();
    const [workers, setWorkers] = useState([]);

    useEffect(() => {
        getPaidPayments(expenseid)
            .then(({ payments }) => {

                let workers = groupBy(
                    payments,
                    "staff_id"
                ),
                    items = [];

                for (let id in workers) {
                    //for each worker group by payroll type
                    let _items = workers[id],
                        type_items = [];
                    _items = groupBy(_items, "expense_type");

                    for (let type in _items) {
                        type_items.push({
                            advance_amount: _items[type].reduce((p, c) => p + parseFloat(c.advance_amount), 0),
                            expense_category: type,
                            cash_amount: _items[type].reduce((p, c) => p + parseFloat(c.cash_amount), 0),
                            total_amount: _items[type].reduce((p, c) => p + parseFloat(c.amount), 0)
                        })
                    }

                    items.push({
                        id: id,
                        display_name: workers[id][0].display_name,
                        items: type_items,
                        total_amount: type_items.reduce((p, c) => p + c.total_amount, 0)
                    });
                }
                setWorkers(items.sort((a, b) => sortFunction(a, b, "display_name")));
            })
            .catch(setError)
            .finally(() => setLoaded(true))
    }, [expenseid])



    const accountingJournal = useMemo(() => {

        const categories = {}, j_categories = [];

        workers.forEach(e => {
            e.items.forEach(i => {
                if (!(i.expense_category in categories)) categories[i.expense_category] = [];

                categories[i.expense_category].push(i.total_amount);

                if (!!i.advance_amount) {
                    if (!("advances" in categories)) categories.advances = [];
                    categories.advances.push(-i.advance_amount);
                }
            })
        })

        for (let cat in categories) {
            j_categories.push({
                title: UCWords(cat),
                credit: categories[cat].filter(r => r > 0).reduce((p, c) => p + parseFloat(c), 0),
                debit: categories[cat].filter(r => r < 0).reduce((p, c) => p + parseFloat(c), 0),
            })
        }

        return j_categories;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(workers)])


    if (!loaded) return <PageLoading>Loading all expenses...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>
                    {expenseid}
                </Title>
                <ActionBar>

                    <ButtonToolbar>
                        <Btn title="Download PDF"
                            href={`${APIURL}/payroll-payments/expenses/${expenseid}/pdf`}
                            isExternalLink
                        >
                            <i className="fas fa-file-pdf" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-900">

                <h4 className='mt-2 mt-sm-5'>Expense Summary</h4>
                <Table>
                    <thead>
                        <tr>
                            <th>Account</th>
                            <th>Credit</th>
                            <th>Debit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {accountingJournal.map((j, idx) => (
                            <tr>
                                <td>{j.title.replace(/-/g, " & ")}</td>
                                <td>{cur(j.credit, 0).format()}</td>
                                <td>{cur(Math.abs(j.debit), 0).format()}</td>
                            </tr>
                        ))}
                        <tr>
                            <td>Cash</td>
                            <td>0</td>
                            <td>{cur(accountingJournal.reduce((p, c) => p + (c.credit + c.debit), 0), 0).format()}</td>
                        </tr>
                    </tbody>
                </Table>

                <h4 className='mt-2 mt-sm-5'>PayOut Breakdown</h4>

                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Cash Amount</th>
                            <th>Advance Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {workers.map(w => (
                            <tr>
                                <td>{w.display_name}</td>
                                <td>{cur(w.items.reduce((p, c) => p + (parseFloat(c.total_amount) - parseFloat(c.advance_amount)), 0), 0).format()}</td>
                                <td>{cur(w.items.reduce((p, c) => p + parseFloat(c.advance_amount), 0), 0).format()}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>

                            <th>Totals</th>
                            <th>
                                {cur(
                                    workers.reduce((p, c) => p + (
                                        c.items.reduce((pp, cc) => pp + (parseFloat(cc.total_amount) - parseFloat(cc.advance_amount)), 0)
                                    ), 0),
                                    0
                                ).format()}
                            </th>
                            <th>{
                                cur(
                                    workers.reduce((p, c) => p + (
                                        c.items.reduce((pp, cc) => pp + parseFloat(cc.advance_amount), 0)
                                    ), 0),
                                    0
                                ).format()
                            }
                            </th>
                        </tr>
                    </tfoot>
                </Table>

                <h4 className='mt-2 mt-sm-5'>Expense Details</h4>
                <Accordion flush>
                    {
                        workers.map(_w => (
                            <Accordion.Item key={_w.id} eventKey={_w.id}>
                                <Accordion.Header>
                                    <h5 className="w-100 fw-normal">{_w.display_name}</h5>
                                    <span className="me-2">
                                        {cur(_w.total_amount, 0).format()}
                                    </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Table hover responsive="md" style={{ minWidth: "500px" }}>

                                        <thead>
                                            <tr>
                                                <th>Expense</th>
                                                <th className="text-end">Cash</th>
                                                <th className="text-end">Advance</th>
                                                <th className="text-end">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {_w.items.map((w, idx) => (
                                                <tr key={idx}>
                                                    <td>{UCWords(w.expense_category.replace(/-/g, " & "))}</td>
                                                    <td className="text-end">{cur(w.cash_amount).format()}</td>
                                                    <td className="text-end">{cur(w.advance_amount).format()}</td>
                                                    <td className="text-end">{cur(w.total_amount).format()}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))
                    }
                </Accordion>
            </div>
        </>
    )
}




export default PayrollExpenseDetails;