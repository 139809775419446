import ViewEvents from "./view-events";
import { Routes, Route } from "react-router-dom";
import { NewEvent } from "./event-manipulation";
import EventDetails from "./event-details";

const Events = () => {

    return (
        <Routes>
            <Route path="/new" element={<NewEvent />} />
            <Route path="/table" element={<ViewEvents view="table" />} />
            <Route path="/calendar" element={<ViewEvents view="calendar" />} />
            <Route path="/:eventid/*" element={<EventDetails />} />
            <Route path="/" element={<ViewEvents />} />
        </Routes>
    )
}

export default Events;