import moment from "moment";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Routes, Route, useParams, Link } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import cur, { UGX } from "../../components/currency";
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { sortFunction } from "../../components/resources";
import { getClient } from "../../resources/api/clients";
import { EditClient } from "./client-manipulation";
import ClientStatement from "./client-statement";
import ViewStatement from "./statement";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/clients").ClientObject} props.details
 */
const ViewDetails = ({ details }) => {

    const nav_items = [
        { title: 'Clients', href: "/app/clients" },
        { title: details.title }
    ]


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.title}</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn href={`/app/clients/${details.id}/generate-statement`} title="Generate Statement">
                            <i className="fas fa-file-pdf" />
                        </Btn>
                        <Btn href={`/app/clients/${details.id}/view-statement`} title="View Statement">
                            <i className="fas fa-list" />
                        </Btn>
                        <Btn href={`/app/events/new?client=${details.id}`} title="New Client Event">
                            <i className="fas fa-calendar-plus" />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href={`/app/clients/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                        <Btn href="/app/clients/new" title="New Client">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <h4>Client Details</h4>
                <dl>
                    <dt>Email</dt>
                    <dd>
                        {details.email ?
                            <a href={`mailto:${details.email}`}>{details.email}</a> :
                            "n/a"}
                    </dd>
                    <dt>Telephone</dt>
                    <dd>
                        {details.telephone ?
                            <a href={`tel:${details.telephone}`}>{details.telephone}</a> :
                            "n/a"}
                    </dd>
                    <dt>No Events</dt>
                    <dd>{details.no_events}</dd>
                    <dt>Total Revenue</dt>
                    <dd>{UGX(details.total_revenue).format()}</dd>
                    <dt>Unpaid Revenue</dt>
                    <dd>{UGX(details.unpaid_revenue).format()}</dd>
                    <dt>Contact Person</dt>
                    <dd>{details.contact_person || `none`}</dd>
                </dl>

                <ViewEvents events={details.events} />

            </div>

        </>
    )

}


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/events").EventObject[]} props.events 
 * @returns 
 */
const ViewEvents = ({ events }) => {

    if (events.length === 0) return <></>

    return (
        <>
            <h4 className="mt-3 mt-md-5">Events</h4>
            <Table responsive hover size="sm" style={{ maxWidth: "600px" }}>
                <thead>
                    <tr>
                        <th>Event</th>
                        <th>Date</th>
                        <th className="text-end">Total Amount</th>
                        <th className="text-end">Balance</th>
                    </tr>
                </thead>
                <tbody>
                    {events.map(e => (
                        <tr key={e.id}>
                            <td>
                                <Link to={`/app/events/${e.id}`} >{e.reference}</Link>
                            </td>
                            <td>{moment(e.event_date).format("DD MMM YYYY")}</td>
                            <td className="text-end">{cur(e.amount, 0).format()}</td>
                            <td className="text-end">{cur(e.balance, 0).format()}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={2}>Total</th>
                        <th className="text-end">{cur(events.reduce((p, c) => p + c.amount, 0), 0).format()}</th>
                        <th className="text-end">{cur(events.reduce((p, c) => p + c.balance, 0), 0).format()}</th>
                    </tr>
                </tfoot>
            </Table>
        </>
    )
}


const ClientDetails = () => {

    const { clientid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();


    useEffect(() => {

        setLoaded(false);
        setError(null);

        const sortFn = (a, b) => sortFunction(a, b, 'event_date', "asc");

        getClient(clientid, ['events'])
            .then(({ client }) => {

                const events = [...client.events];
                events.sort(sortFn);

                setDetails({ ...client, events });
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [clientid])

    //load events details

    if (!isLoaded) {
        return <PageLoading />
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <Routes>
            <Route path="/view-statement" element={<ViewStatement details={details} setDetails={setDetails} />} />
            <Route path="/generate-statement" element={<ClientStatement details={details} setDetails={setDetails} />} />
            <Route path="/edit" element={<EditClient details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewDetails details={details} />} />
        </Routes>
    )

}


export default ClientDetails;