import { useCallback, useEffect, useMemo } from 'react';
import { errorAlert } from './toastr';
import { Button, Col, Form, FormSelect, InputGroup, Row } from 'react-bootstrap';
import cur from './currency';
import { ServiceParts } from '../assets/constants';


/**
 * 
 * @param {{
 * maintenanceitems: {
 *      items: import('../resources/api/maintenance').MaintenanceItemObject[], 
 *      setItems: React.Dispatch<React.SetStateAction<import('../resources/api/maintenance').MaintenanceItemObject[]>>}
 * deleteditems: {
 *      deleted_items: string[], 
 *      setDeletedItems: React.Dispatch<React.SetStateAction<string[]>>}
 * children: React.ReactNode
 * }} param0 
 */
const MaintenanceItems = ({ maintenanceitems, children, deleteditems }) => {

    const { items, setItems } = maintenanceitems;
    const { setDeletedItems } = deleteditems;

    /**
     * add a new all empty invoice item.
     */
    const addItem = useCallback(() => {
        setItems(items => items.concat({
            part: "",
            notes: '',
            action: '',
            amount: '',
            isNew: true
        }));
    }, [setItems])

    /**
     * update an item in the list
     * @param {number} index 
     * @param {import('../resources/api/maintenance').MaintenanceItemObject} value 
     */
    const updateItem = (index, value) => {

        // console.log(value);
        let current_items = [...items];
        value.hasChanged = true;
        current_items.splice(index, 1, value);
        setItems(current_items);
    }

    /**
     * Deletes an item in poisition index from the list.
     * @param {number} index Index of item in the list
     */
    const deleteItem = index => {
        if (items.length < 2) return errorAlert("You must have at least one item on the expense if expense is itemized.");
        const _item = { ...items[index] };

        if (_item.id) setDeletedItems(items => items.concat(_item.id));

        setItems(items.filter((e, i) => i !== index));
    }

    /**
     * 
     */
    const totals = useMemo(() => {

        if (items.length === 0) return {
            tax: 0,
            total: 0
        };

        let _total = [];

        items.forEach(i => {
            _total.push(parseFloat(i.amount) || 0);
        })

        /** @type {number} */
        const __total = _total.reduce((p, t) => p + t, 0);

        return {
            total: __total
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(items)])

    const style = {
        minWidth: '175px',
        textAlign: 'right',
        display: 'inline-block'
    }

    useEffect(() => {
        if (items.length === 0) addItem();
    }, [items.length, addItem])

    return (
        <>
            <Row className="d-none d-sm-flex g-2">
                <Col sm={3} className="py-0">
                    <Form.Label className="form-field-title">Part</Form.Label>
                </Col>
                <Col sm={4} className="py-0">
                    <Form.Label className="form-field-title">Notes</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Action</Form.Label>
                </Col>
                <Col sm={3} className="py-0">
                    <Form.Label className="form-field-title">Amount</Form.Label>
                </Col>
            </Row>
            {items.map((e, i) => <MaintenanceItem key={i} details={e} onChange={value => updateItem(i, value)} onDelete={() => deleteItem(i)} />)}
            <Row>
                <Col sm={12} className="mt-3 mb-2 d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center">
                    <div>
                        <Button variant="secondary" size="sm" className="rounded-pill m-1" onClick={addItem}>
                            <i className="fas fa-plus-circle me-2" />Add Item
                        </Button>
                        {children}
                    </div>

                    <div className="text-end">
                        Total: UGX <span style={style} className="font-weight-bold h4 my-0">{cur(totals.total, 0).format()}</span>
                    </div>

                </Col>
            </Row>
        </>
    )
}

/**
 * 
 * @param {{
 * details: import('../resources/api/maintenance').MaintenanceItemObject,
 * onChange: (details: import('../resources/api/maintenance').MaintenanceItemObject) => void
 * onDelete: () => void
 * }} props 
 */
const MaintenanceItem = ({ details, onChange, onDelete }) => {

    const { Label, Control } = Form;

    return (
        <div className="my-3 py-2 py-sm-0 my-sm-0 border-start border-primary label-sm-hide" style={{ borderLeftWidth: '3px!important' }}>
            <Row className='g-2'>
                <Col sm={3} className="my-1">
                    <Label className="form-field-title">Part</Label>
                    <FormSelect
                        size="sm"
                        value={details.part}
                        onChange={e => onChange(({ ...details, part: e.currentTarget.value }))}
                        required
                    >
                        <option value=""></option>
                        {ServiceParts.map(c => <option value={c} key={c.replace(/ /g, "_")}>{c}</option>)}
                    </FormSelect>
                    <Control.Feedback type="invalid">
                        You must choose a part
                    </Control.Feedback>
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Notes</Label>
                    <Control
                        size="sm"
                        as="textarea"
                        placeholder=""
                        value={details.notes}
                        onChange={e => onChange(({ ...details, notes: e.currentTarget.value }))}
                        rows={1}
                        onFocus={e => e.currentTarget.rows = 5}
                        onBlur={e => e.currentTarget.rows = 2}
                        required
                        maxLength={150}
                    />
                    <Control.Feedback type="invalid">
                        Describe the item. Max No Characters: 150. No Characters: {details.notes.length}
                    </Control.Feedback>
                </Col>
                <Col xs={5} sm={2} className="my-1">
                    <Label className="form-field-title">Action</Label>
                    <FormSelect
                        size="sm"
                        value={details.action}
                        onChange={e => onChange(({ ...details, action: e.currentTarget.value }))}
                        required
                    >
                        <option value=""></option>
                        <option value="replace">Replace</option>
                        <option value="repair">Repair</option>
                        <option value="check">Check</option>
                    </FormSelect>
                    <Control.Feedback type="invalid">
                        Must be provided
                    </Control.Feedback>
                </Col>
                <Col xs={7} sm={3} className="my-1">
                    <Label className="form-field-title">Cost</Label>
                    <InputGroup>
                        <Control
                            size="sm"
                            value={details.amount}
                            onChange={e => onChange(({ ...details, amount: e.currentTarget.value }))}
                            type="number"
                            step="50"
                            required
                            min="50"
                        />
                        <Button onClick={onDelete} size="sm" variant="link" className="text-danger" >
                            <i className="fas fa-times" />
                        </Button>
                        <Control.Feedback type="invalid">
                            Multiples of 50 and cannot be 0
                        </Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )
}


export {
    MaintenanceItems,
    MaintenanceItem
}