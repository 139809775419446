
import Nav from '../../components/breadcrumb';
import PageHeader, { Title } from '../../components/pageheader';
import { useNavigate } from 'react-router-dom';
import ScheduleForm from './schedule-form';
import { useMemo } from 'react';
import { useState } from 'react';
import { PageLoading } from '../../components/loading';
import moment from 'moment';

const scheduleObject = {
    schedule_date: "", no_vehicles: "", no_events: "", items: []
}

const NewSchedule = () => {

    const nav_items = [
        { title: 'Schedules', href: '/app/schedules' },
        { title: 'New Schedule' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {

        navigate(`/app/schedules/${details.id}`);
    }


    return (
        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <ScheduleForm
                    details={scheduleObject}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/schedules').ScheduleObject} props.details 
 */
const EditSchedule = ({ details }) => {

    const [loaded, setLoaded] = useState(false);

    const nav_items = [
        { title: 'Schedules', href: '/app/schedules' },
        { title: moment(details.schedule_date).format("ddd DD/MM/YYYY"), href: `/app/schedules/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => navigate(`/app/schedules/${details.id}`);


    const scheduleObject = useMemo(() => {

        if (!details) return;

        const vehicles = []

        details.products.forEach(i => {
            if (vehicles.indexOf(i.vehicle_id) === -1) vehicles.push(i.vehicle_id);
        })

        let _items = [];

        vehicles.forEach(v => {
            let items = details.products.filter(i => i.vehicle_id === v);

            let combinations = Array.from((new Set(
                items.map(i => `${i.event_id};${i.eta};${i.action}`)
            )));

            let s_items = [];


            combinations.forEach(c => {
                let [eid, eta, action] = c.split(";");

                let _events = items.filter(i => ((i.event_id === eid) && (i.eta === eta) && (i.action === action)));

                s_items.push({
                    eta,
                    action,
                    event_id: eid,
                    location: _events[0].event_location,
                    title: _events[0].client_title,
                    id: _events[0].schedule_item_id,
                    products: _events.map(p => ({
                        event_product_id: p.event_product_id,
                        product: p.product,
                        max_quantity: p.product_quantity,
                        quantity: p.quantity,
                        id: p.id
                    }))
                });

            })

            _items.push({
                vehicle_id: items[0].vehicle_id,
                reg_no: items[0].reg_no,
                events: s_items
            })


        })

        setLoaded(true);

        return {
            schedule_date: details.schedule_date,
            items: _items,
            id: details.id
        }

    }, [details])



    if (!loaded) return <PageLoading />;




    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>{details.title}</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <ScheduleForm
                    details={scheduleObject}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewSchedule, EditSchedule };