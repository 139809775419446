import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import { Routes, Route, useParams, Link } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import cur from "../../components/currency";
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { ActionBar, ActionButton, ButtonToolbar, Title } from '../../components/pageheader';
import { sortFunction, UCWords } from "../../components/resources";
import { deleteTransfer, getAccount } from "../../resources/api/accounts";
import { TransferForm } from "./accounts-form";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import PagePagination, { usePagination } from '../../components/pagination';

const form_defaults = {
    amount: "",
    direction: "out",
    from_account_id: "",
    to_account_id: "",
    transfer_date: ""
}

/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/accounts").AccountObject} props.details
 * @param {(details: import("../../resources/api/accounts").AccountTransferObject, action: "edit"|"create")=>void} props.onTransferUpload
 * @param {(id: string)=>void} props.onTransferDelete
 */
const ViewDetails = ({ details, details: { transactions: items }, onTransferUpload, onTransferDelete }) => {

    const nav_items = [
        { title: 'Accounts', href: "/app/accounts" },
        { title: details.title }
    ]

    const [show, setShow] = useState(false);
    const [edit_action, setEditAction] = useState("create");

    const [transfer_details, setTransferDetails] = useState(form_defaults);


    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);


    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);

    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])



    const onClickNewIn = () => {
        setTransferDetails({ ...form_defaults, direction: "in", to_account_id: details.id });
        setEditAction("create");
        setShow(true);
    }

    const onClickNewOut = () => {
        setTransferDetails({ ...form_defaults, direction: "out", from_account_id: details.id });
        setEditAction("create");
        setShow(true);
    }

    const handleTransferDelete = (e, id) => {
        e.preventDefault();
        infoAlert("Deleting transfer...");

        deleteTransfer(id)
            .then(({ message }) => {
                successAlert(message);
                onTransferDelete(id);
            }).catch(errorAlert)


    }

    const [key, setKey] = useState('satement');


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.title}</Title>

                <ActionBar>
                    <ButtonToolbar>
                        <ActionButton onClick={onClickNewIn} title="Inward Transfer">
                            <i className='fas fa-arrow-right-to-bracket' />
                        </ActionButton>
                        <ActionButton onClick={onClickNewOut} title="Outward Transfer">
                            <i className='fas fa-arrow-right-from-bracket' />
                        </ActionButton>
                    </ButtonToolbar>
                </ActionBar>

            </PageHeader>

            <div className="max-1000">

                <dl style={{ columnCount: 4 }}>
                    <dt>Opening Date</dt>
                    <dd>{moment(details.opening_date).format("DD MMM YYYY")}</dd>
                    <dt>Opening Balance</dt>
                    <dd>{cur(details.opening_balance, 0).format()}</dd>
                    <dt>Available Balance</dt>
                    <dd>{cur(details.available_balance, 0).format()}</dd>
                    <dt>Status</dt>
                    <dd>{details.isActive ? "Active" : "Inactive"}</dd>
                </dl>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >

                    <Tab eventKey="satement" title="Statement">

                        <Table style={{ minWidth: "600px" }} hover size="sm" responsive>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Reference</th>
                                    <th>Date</th>
                                    <th className="text-end">Credit</th>
                                    <th className="text-end">Debit</th>
                                    <th className="text-end">Running Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayItems.map(t => (
                                    <tr key={t.ref} >
                                        <td>{UCWords(t.type.replace(/-/g, " "))}</td >
                                        <td>
                                            {(t.type !== "transfer") ?
                                                <Link to={`/app/${t.type}/${t.id}`}>
                                                    {t.ref}
                                                </Link>
                                                :
                                                t.ref
                                            }

                                        </td>
                                        <td>{moment(t.date).format("DD MMM YYYY")}</td>
                                        <td className="text-end">{cur(t.credit, 0).format()}</td>
                                        <td className="text-end">{cur(t.debit, 0).format()}</td>
                                        <td className="text-end">{(t.date < details.opening_date) ? "-" : cur(t.running_balance, 0).format()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                            <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                                <Col className="me-2 text-nowrap">No Items In View:</Col>
                                <Col>
                                    <Form.Select
                                        value={noPageItems}
                                        onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                                        size="sm"
                                        style={{ maxWidth: '100px' }}
                                    >
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </Form.Select>
                                </Col>

                                <Col className="ms-2 text-nowrap">of {items.length} total items</Col>
                            </Row>
                            <PagePagination
                                page={PAGE}
                                no_pages={NOPAGES}
                                setPage={setPage}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="transfers" title="Transfers">

                        <Table style={{ maxWidth: "550px" }} hover size="sm">
                            <thead>
                                <tr >
                                    <th>Date</th>
                                    <th>Direction</th>
                                    <th>Account</th>
                                    <th className="text-end">Amount</th>
                                    <th>...</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.transfers
                                    .map(p => (
                                        <tr key={p.id} >
                                            <td>{moment(p.transfer_date).format("DD MMM YYYY")}</td>
                                            <td>{p.direction.toUpperCase()}</td>
                                            <td>{(p.direction === "in") ? p.from_account : p.to_account}</td>
                                            <td className="text-end">{cur(p.amount, 0).format()}</td>
                                            <td>
                                                <a href="#." className="text-danger" onClick={(e) => handleTransferDelete(e, p.id)}><i className="fas fa-trash-alt" /></a>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </Tab>
                </Tabs>

            </div>

            <TransferForm
                transferDetails={{ details: transfer_details, setDetails: setTransferDetails }}
                action={edit_action}
                show={{ show, setShow }}
                direction={transfer_details.direction}
                onUpload={onTransferUpload}
            />

        </>
    )

}


const AccountDetails = () => {

    const { accountid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    const organiseTransactions = (transactions, o_date, o_bal = 0,) => {

        transactions.sort((a, b) => sortFunction(a, b, "date", "asc"));

        let prev_balance = parseFloat(o_bal);

        transactions = transactions.map(t => {
            prev_balance += (t.credit - t.debit);
            return {
                ...t,
                running_balance: prev_balance
            };
        }).reverse();

        transactions.push({
            id: "",
            type: "transfer",
            ref: "Opening Balance",
            debit: 0,
            credit: 0,
            date: 0,
            running_balance: parseFloat(o_bal)
        })

        return transactions;
    }

    useEffect(() => {

        setLoaded(false);
        setError();

        getAccount(accountid, ['transfers', 'expenses', 'payments', 'bill_payments'])
            .then(({ account }) => {
                // staff.payments = staff.payments.sort((a, b) => sortFunction(a, b, "date_added"));
                let transactions = [];
                account.expenses.forEach(e => {
                    transactions.push({
                        id: e.id,
                        type: "expenses",
                        ref: e.reference,
                        debit: parseFloat(e.total_amount),
                        credit: 0,
                        date: e.expense_date
                    })
                })

                account.payments.forEach(e => {
                    transactions.push({
                        id: e.id,
                        type: "payments",
                        ref: e.reference,
                        credit: parseFloat(e.total_amount),
                        debit: 0,
                        date: e.payment_date
                    })
                })

                account.bill_payments.forEach(e => {
                    transactions.push({
                        id: e.id,
                        type: "bill-payments",
                        ref: e.receipt_no,
                        credit: 0,
                        debit: parseFloat(e.total_amount),
                        date: e.payment_date
                    })
                })

                account.transfers.forEach(e => {
                    transactions.push({
                        id: e.id,
                        type: "transfer",
                        ref: (e.direction === "in") ? e.from_account : e.to_account,
                        credit: (e.direction === "in") ? parseFloat(e.amount) : 0,
                        debit: (e.direction === "out") ? parseFloat(e.amount) : 0,
                        date: e.transfer_date
                    })
                })

                transactions = organiseTransactions(transactions, account.opening_date, account.opening_balance);

                let transfers = account.transfers.sort((a, b) => sortFunction(a, b, 'transfer_date', "desc"));

                setDetails({ ...account, transfers, transactions });
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [accountid])

    const handleTransferUpload = (transfer, action = "edit") => {
        if (action === "edit") {
            let transactions = [...details.transactions],
                transfers = [...details.transfers];

            transactions.splice(
                transactions.findIndex(i => i.id === transfer.id),
                1,
                {
                    id: transfer.id,
                    type: "transfer",
                    ref: (transfer.direction === "in") ? transfer.from_account : transfer.to_account,
                    credit: (transfer.direction === "in") ? parseFloat(transfer.amount) : 0,
                    debit: (transfer.direction === "out") ? parseFloat(transfer.amount) : 0,
                    date: transfer.transfer_date
                }
            );

            transactions = organiseTransactions(transactions, details.opening_date, details.opening_balance);

            transfers.splice(
                transfers.findIndex(i => i.id === transfer.id),
                1,
                transfer
            );

            setDetails(d => ({ ...d, transactions, transfers }));

        } else {

            let transactions = [...details.transactions],
                transfers = [...details.transfers];

            transactions = transactions.concat({
                id: transfer.id,
                type: "transfer",
                ref: (transfer.direction === "in") ? transfer.from_account : transfer.to_account,
                credit: (transfer.direction === "in") ? parseFloat(transfer.amount) : 0,
                debit: (transfer.direction === "out") ? parseFloat(transfer.amount) : 0,
                date: transfer.transfer_date
            })

            transactions = organiseTransactions(transactions, details.opening_date, details.opening_balance);
            transfers = transfers.concat(transfer).sort((a, b) => sortFunction(a, b, 'transfer_date', "desc"));

            setDetails(d => ({ ...d, transfers, transactions }));
        }
    }

    const handleTransferDelete = id => {
        let transactions = [...details.transactions],
            transfers = [...details.transfers];

        transactions = transactions.filter(i => i.id !== id);
        transactions = organiseTransactions(transactions, details.opening_date, details.opening_balance);
        transfers = transfers.filter(i => i.id !== id);
        setDetails(d => ({ ...d, transactions, transfers }));
    }



    if (!isLoaded) return <PageLoading>Loading account details...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>

    return (
        <Routes>
            <Route
                path="/"
                element={<ViewDetails
                    details={details}
                    onTransferUpload={handleTransferUpload}
                    onTransferDelete={handleTransferDelete}
                />} />
        </Routes>
    )

}


export default AccountDetails;