import { useRef, useState } from "react";
import { Col, Row, Form, Modal } from "react-bootstrap";
import { CancelButton, SubmitButton } from "../../components/btns";
import { errorAlert, successAlert } from "../../components/toastr";
import { ActionButton } from "../../components/pageheader";
import { useEffect } from "react";
import { paymentMethods } from "../../assets/constants";
import { UCWords } from "../../components/resources";
import { getAccounts } from "../../resources/api/accounts";
import { processMaintenance } from "../../resources/api/maintenance";

const { Label } = Form;

/**
 * 
 * @param {Object} props
 * @param {string} props.maintenance_id ID of t
 * @param {(details: import("../../resources/api/maintenance").MaintenanceObject) => void} props.onUpload
 */
const ExecutePayment = ({ onUpload, maintenance_id }) => {

    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [handlingSubmit, setHandlingSubmit] = useState(false);


    const [accounts, setAccounts] = useState([]);

    const [account_id, setAccount] = useState("");
    const [method, setMethod] = useState("");

    const ref = useRef();

    useEffect(() => {

        getAccounts()
            .then(({ accounts }) => {
                setAccounts(accounts.filter(a => a.isActive));
            })
            .catch(errorAlert)
    }, [])


    /**
     * 
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = ref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have some errors in your form.");
            return;
        }

        setValidated(false);
        setHandlingSubmit(true);

        processMaintenance(
            { account_id, method },
            maintenance_id
        )
            .then(({ message, maintenance }) => {
                successAlert(message);
                onUpload(maintenance);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => {
                form.reset();
                setHandlingSubmit(false);
            })

    }

    const handleExit = () => {
        setValidated(false);
        setAccount("");
        setMethod("");
    }

    return (

        <>
            <ActionButton
                variant="outline-secondary"
                size="sm"
                title="Execute Payments"
                onClick={() => setShow(true)}
            >
                <i className="fas fa-file-invoice" />
            </ActionButton>

            <Modal show={show} onExit={handleExit} centered size="sm">
                <Modal.Body>
                    <p>By executing the payment, expense records shall be created for this maintenance activity. The details will not be editable again and the process cannot be reveresed.</p>
                    <Form noValidate validated={validated} ref={ref}>
                        <Row>

                            <Col sm={12} className="my-1">
                                <Label className="form-field-title">Payment Method</Label>
                                <Form.Select
                                    as="select"
                                    value={method}
                                    onChange={e => setMethod(e.currentTarget.value)}
                                    required
                                >
                                    <option value=""></option>
                                    {paymentMethods.map(c => <option key={c} value={c}>{UCWords(c.replace(/-/g, " "))}</option>)}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    You must select a method for payment used for this transaction.
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm={12} className="my-1">
                                <Label className="form-field-title">Account</Label>
                                <Form.Select
                                    as="select"
                                    value={account_id}
                                    onChange={e => setAccount(e.currentTarget.value)}
                                    required
                                >
                                    <option value=""></option>
                                    {accounts.map(c => <option key={c.id} value={c.id}>{c.title}</option>)}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    You must select the account this payment went into.
                                </Form.Control.Feedback>
                            </Col>
                        </Row>



                        <Row className="mt-3 text-end">
                            <Col>
                                <SubmitButton
                                    onClick={handleSubmit}
                                    isSubmitting={handlingSubmit}
                                >
                                    Execute
                                </SubmitButton>
                                <CancelButton
                                    isSubmitting={handlingSubmit}
                                    onClick={() => setShow(false)}
                                >
                                    Cancel
                                </CancelButton>
                            </Col>
                        </Row>
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}


export { ExecutePayment };