import React, { useRef, useState } from "react";
import { Col, Form, FormSelect, InputGroup, Modal, Row } from "react-bootstrap";
import { CancelButton, SubmitButton } from "../../components/btns";
import { errorAlert, successAlert } from "../../components/toastr";
import cur from "../../components/currency";
import { createHire, updateHire } from "../../resources/api/vehicle-hire";
import { DateSelector } from "../../components/datetimepicker";


const { Control, Label, Control: { Feedback } } = Form;

/**
 * @param {Object} props
 * @param {"create"|"edit"} props.action
 * @param {(details: import("../../resources/api/vehicle-hire").HireObject) => void} props.onUpload
 * @param {{
 *  show: boolean
 *  setShow: React.Dispatch<React.SetStateAction<boolean>>
 * }} props.show
 * @param {{
 *  details: import("../../resources/api/vehicle-hire").HireObject,
 *  setDetails: React.Dispatch<React.SetStateAction<import("../../resources/api/vehicle-hire").HireObject>>
 * }} props.hireDetails
 */
const HireForm = ({ onUpload, action = "create", hireDetails, show: toShow, vehicle_id }) => {

    const inputref = useRef();

    const { show, setShow } = toShow;
    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [validated, setValidated] = useState(false);

    const { details, setDetails } = hireDetails;



    /**
     * Handles the submission of the form
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = inputref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("Some errors have been detected in the form. These have been highlighted for you.", "Invalid Fields");
            return;
        }

        setHandlingSubmit(true);
        setValidated(false);

        let promise;

        if (action === "create") {
            promise = createHire({ ...details, vehicle_id });
        } else {
            promise = updateHire(details, details.id);
        }

        promise
            .then(({ hire, message }) => {
                form.reset();
                successAlert(message);
                onUpload(hire);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => setHandlingSubmit(false))
    }

    return (
        <Modal show={show} onExit={() => setValidated(false)} centered size="lg">
            <Modal.Body>
                <Form noValidate validated={validated} ref={inputref}>
                    <Row>
                        <Col sm={6} className="my-1">
                            <Label className="form-field-title">Date</Label>
                            <DateSelector
                                value={details.bill_date}
                                onChange={date => setDetails(d => ({ ...d, bill_date: date }))}
                                placeholder="-"
                                maxDate={0}
                                required
                            />
                            <Feedback type="invalid">
                                You must select a date.
                            </Feedback>
                        </Col>
                    </Row>


                    <Row className="g-1">
                        <Col sm={4} className="my-1">
                            <Label className="form-field-title">No Units per Unit</Label>
                            <InputGroup>
                                <Control
                                    style={{ flexGrow: '2!important' }}
                                    type="number"
                                    value={details.no_units}
                                    onChange={e => setDetails({ ...details, no_units: e.currentTarget.value })}
                                    step={0.1}
                                    required
                                />
                                <FormSelect
                                    value={details.unit}
                                    onChange={e => setDetails({ ...details, unit: e.currentTarget.value })}
                                    required
                                >
                                    <option value="hour">hours</option>
                                    <option value="day">days</option>
                                    <option value="week">weeks</option>
                                    <option value="month">months</option>
                                    <option value="km">kms</option>
                                </FormSelect>
                                <Control.Feedback type="invalid">
                                    Required
                                </Control.Feedback>
                            </InputGroup>
                        </Col>
                        <Col sm={5} className="my-1">
                            <Label className="form-field-title">Unit Cost</Label>
                            <Control
                                type="number"
                                value={details.unit_cost}
                                onChange={e => setDetails({ ...details, unit_cost: e.currentTarget.value })}
                                step={0.01}
                                required
                            />
                            <Control.Feedback type="invalid">
                                Required
                            </Control.Feedback>
                        </Col>
                        <Col sm={3} className="my-1">
                            <Label className="form-field-title">Total</Label>
                            <Control
                                type="text"
                                readOnly
                                value={
                                    cur(
                                        parseFloat(details.unit_cost || 0) * parseFloat(details.no_units || 0),
                                        0).format()
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-4 mb-3 text-end">
                            <SubmitButton isSubmitting={handlingSubmit} onClick={handleSubmit}>
                                {action === "edit" ? "Update" : "Submit"}
                            </SubmitButton>
                            <CancelButton isSubmitting={handlingSubmit} onClick={() => setShow(false)}>
                                Cancel
                            </CancelButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}


export default HireForm;