// import { useContext } from 'react';
import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import Staff from '../staff/staff';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import ApprovePayroll from './approve';
import PayrollExpenseDetails from './expense-details';
import ProcessPayroll from './process-payroll';
import ViewExpenses from './view-expenses';
import ViewStats from './view-stats';

const Payroll = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to access the payroll module.</ErrorLoading>
    }


    return (
        <Routes>
            <Route path="/staff/*" element={<Staff />} />
            <Route path="/expenses" element={<ViewExpenses />} />
            <Route path='/expenses/:expenseid' element={<PayrollExpenseDetails />} />
            <Route path="/process" element={<ProcessPayroll />} />
            <Route path="/approve" element={<ApprovePayroll />} />
            <Route path="/" element={<ViewStats />} />
        </Routes>
    )
};

export default Payroll;