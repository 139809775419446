import Nav from '../../components/breadcrumb';
import PageHeader, { ActionBar, ActionButton, Title } from './../../components/pageheader';
import { Form, Table, Card, Row, Col, Button } from 'react-bootstrap';
import { SortButton } from './../../components/btns';
import { useState, useEffect } from 'react';
import PagePagination, { usePagination } from './../../components/pagination';
import moment from 'moment';
import currency, { UGX } from './../../components/currency';
import { Link } from 'react-router-dom';
// import { UCWords } from './../../components/resources';
import { useScreenType } from './../../components/custom-hooks';
import { ErrorLoading, PageLoading } from '../../components/loading';
import { getAllStaff } from '../../resources/api/payroll';
import StaffForm from './staff-form';
import { UCWords } from '../../components/resources';


const form_defaults = {
    last_name: "",
    other_names: "",
    email: "",
    telephone: "",
    pay_rate: "",
    pay_unit: "month",
    staff_type: ""
}


/**
 * View table items on screens larger than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/payroll').StaffObject[]
 * currentPage: number
 * noPageItems: number
 * }} param0 
 */
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems, onClickEdit }) => {
    return (
        <Table responsive hover style={{ minWidth: '700px' }}>
            {/* <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "17%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "15%" }} />
            </colgroup> */}
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name <SortButton field="display_name" {...srt} handleSort={handleSort} /></th>
                    <th>Type <SortButton field="staff_type" {...srt} handleSort={handleSort} /></th>
                    <th>Email <SortButton field="email" {...srt} handleSort={handleSort} /></th>
                    <th>Telephone <SortButton field="telephone" {...srt} handleSort={handleSort} /></th>
                    <th style={{ textAlign: 'right' }}>Wage <SortButton field="pay_rate" {...srt} handleSort={handleSort} /></th>
                    <th>Per <SortButton field="pay_unit" {...srt} handleSort={handleSort} /></th>
                    <th>Date Added <SortButton field="date_added" {...srt} handleSort={handleSort} /></th>
                    <th>...</th>
                </tr>

            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td><Link to={`/app/payroll/staff/${e.id}`}>{e.display_name}</Link></td>
                        <td>{UCWords(e.staff_type?.replace(/-/g, " "))}</td>
                        <td>{e.email}</td>
                        <td>{e.telephone}</td>
                        <td style={{ textAlign: 'right' }}>{currency(e.pay_rate).format()}</td>
                        <td>{e.pay_unit}</td>
                        {/* <td style={{ textAlign: 'right' }}>{currency(e.amount).format()}</td>
                        <td style={{ textAlign: 'right' }}>{currency(e.amount).format()}</td> */}
                        <td>{moment(e.date_added).format("DD MMM YYYY")}</td>
                        <td>
                            <Button variant="link" size="sm" className='text-danger p-0 me-2' onClick={() => onClickEdit(e.id)}>
                                <i className="fas fa-pencil-alt" />
                            </Button>
                            <Button variant="link" size="sm" className='text-danger p-0' onClick={() => onClickEdit(e.id, 'user')}>
                                <i className="fas fa-user-edit" />
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

/**
 * view items on screens smaller than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/payroll').StaffObject[]
 * }} param0 
 */
const ViewMobile = ({ srt, handleSort, items, onClickEdit }) => {
    return (
        <div className="my-3">
            <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Name <SortButton field="display_name" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Email <SortButton field="email" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Telephone <SortButton field="telephone" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Wage <SortButton field="pay_rate" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Date Added <SortButton field="date_added" {...srt} handleSort={handleSort} />
                </span>
            </div>
            {items.map((e, i) => (
                <Card className="my-2 shadow-sm" key={i}>
                    <Card.Body className="px-3">
                        <div>
                            <h4 className="h4 d-flex justify-content-between">
                                <Link to={`/app/payroll/staff/${e.id}`}>
                                    {e.display_name}
                                </Link>
                                <span>
                                    <Button variant="link" size="sm" className="p-0 text-danger me-1" onClick={() => onClickEdit(e.id)}>
                                        <i className='fas fa-pencil-alt' />
                                    </Button>
                                    <Button variant="link" size="sm" className="p-0 text-danger" onClick={() => onClickEdit(e.id, 'user')}>
                                        <i className='fas fa-user-edit' />
                                    </Button>
                                </span>

                            </h4>
                            <p className="mb-1"><i className='fas fa-phone fa-fw me-1' /> {e.telephone}</p>
                            <p className="mb-1"><i className='fas fa-envelope fa-fw me-1' /> {e.email}</p>
                            <p className="mb-1"><i className='fas fa-coins fa-fw me-1' /> {UGX(e.pay_rate).format()} per {e.pay_unit}</p>
                            <p className="mb-1"><i className='fas fa-calendar-plus fa-fw me-1' /> {moment(e.date_added).format("DD MMM YYYY")}</p>
                        </div>

                        {/* <div className="d-flex justify-content-between mt-3">
                            <span></span>
                            <span className="text-muted font-weight-normal">{e.status.replace(/-/g, " ").toLowerCase()}</span>
                        </div> */}
                    </Card.Body>
                </Card>
            ))}
        </div>
    )
}


const ViewStaff = () => {

    const navItems = [{
        title: 'Payroll',
        href: "/app/payroll"
    }, {
        title: 'Staff'
    }];

    const [srt, setSrt] = useState({
        currentField: 'display_name',
        currentOrder: 'asc'
    });

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'display_name' });

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    const screenType = useScreenType();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);

    const [show, setShow] = useState(false);
    const [edit_action, setEditAction] = useState("create");

    const [staffDetails, setStaffDetails] = useState(form_defaults);


    const onClickEdit = (id, type = "salary") => {
        let details = orginalItems.find(i => i.id === id);
        setStaffDetails(details);
        setEditAction(type || "salary");
        setShow(true);
    }

    const onClickNew = () => {
        setStaffDetails(form_defaults);
        setEditAction("create");
        setShow(true);
    }

    const onUploadStaff = details => {
        if (edit_action === "create") {
            setOriginalItems(items => items.concat(details));
        } else {
            let items = [...orginalItems];

            items.splice(
                items.findIndex(i => i.id === staffDetails.id),
                1,
                details
            );
            setOriginalItems(items);
        }
    }




    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {
        const v = search.val, f = search.field;
        if (v.length < 2 || f === "") return setItems(orginalItems);
        setItems([...orginalItems.filter(i => i[f].toLowerCase().indexOf(v.toLowerCase()) !== -1)]);
    }, [search.val, search.field, orginalItems]);


    /**
     * get the items
     */
    useEffect(() => {

        getAllStaff(true)
            .then(({ staff }) => {
                setOriginalItems(staff);
                setItems(staff);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [])


    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => {
            if (srt.currentOrder === 'asc') {
                return (a[srt.currentField] > b[srt.currentField]) ? 1 : ((a[srt.currentField] < b[srt.currentField]) ? -1 : 0);
            }
            return (a[srt.currentField] < b[srt.currentField]) ? 1 : ((a[srt.currentField] > b[srt.currentField]) ? -1 : 0);
        }

        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }

    if (!loaded) return <PageLoading>Loading all staff members...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;


    return (
        <>
            <Nav items={navItems} />

            <PageHeader maxWidth="1000">
                <Title>Company Staff</Title>
                <ActionBar>
                    <ActionButton onClick={onClickNew}>
                        <i className='fas fa-plus-circle me-2' />New Staff
                    </ActionButton>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">

                <Row xs={{ cols: "auto" }} className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0">
                    <Col>

                        <Form.Control placeholder="Search For" size="sm"
                            value={search.val}
                            onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                        />
                    </Col>
                    <Col>
                        <span className="mx-1">in</span>
                    </Col>
                    <Col>
                        <Form.Select as="select" size="sm"
                            value={search.field}
                            onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                        >
                            <option value="display_name">Name</option>
                            <option value="email">Email</option>
                            <option value="telephone">Telephone</option>
                        </Form.Select>
                    </Col>

                </Row>

                {screenType === 'desktop' ?
                    <ViewDesktop
                        srt={srt}
                        handleSort={handleSort}
                        items={displayItems}
                        noPageItems={noPageItems}
                        currentPage={PAGE}
                        onClickEdit={onClickEdit}
                    /> :
                    <ViewMobile
                        srt={srt}
                        handleSort={handleSort}
                        items={displayItems}
                        onClickEdit={onClickEdit}
                    />
                }

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                        <Col className="me-2 text-nowrap">No Items:</Col>
                        <Col>
                            <Form.Select
                                value={noPageItems}
                                onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                                size="sm"
                                style={{ maxWidth: '100px' }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </Col>

                        <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                    </Row>
                    <PagePagination
                        page={PAGE}
                        no_pages={NOPAGES}
                        setPage={setPage}
                    />
                </div>
            </div>

            <StaffForm
                staffDetails={{ details: staffDetails, setDetails: setStaffDetails }}
                action={edit_action}
                show={{ show, setShow }}
                onUpload={onUploadStaff}
            />

        </>
    )
}


export default ViewStaff;