
import { Routes, Route } from 'react-router-dom';
import { useContext } from "react";
import { AppUserContext } from "../../App";
import { ErrorLoading } from "../../components/loading";
import { NewVehicle } from "./vehicle-manipulation";
import ViewVehicles from "./view-vehicles";
import VehicleDetails from "./vehicle-details";

const Vehicles = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 3) {
        return <ErrorLoading>You do not have the permission to access clients.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/" element={<ViewVehicles />} />
            <Route path="/new" element={<NewVehicle />} />
            <Route path="/:vehicleid/*" element={<VehicleDetails />} />
        </Routes>
    )
}

export default Vehicles;