import fetchData, { APIURL, getData, postData } from "../fetch";


/**
 * 
 * @typedef {{
 *  id: string 
 *  payment_id: string
 *  event_product_id: string
 *  amount: number
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string,
 *  product: string
 *  total_amount: number
 *  payment_amount: number
 *  balance: number
 * }} PaymentItemObject
 */

/**
 * 
 * @typedef {{
 *  id: string 
 *  reference: string
 *  event_id: string
 *  client_id: string
 *  client: string
 *  payment_date: string
 *  method: string
 *  account: string|import("./accounts").AccountObject
 *  account_id: string
 *  amount: number
 *  payee: string
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string 
 *  event: import("./events").EventObject
 *  items: PaymentItemObject[]
 * }} PaymentObject
 */


/**
 * 
 * @param {PaymentObject} data 
 * @returns {Promise<{code: number, message: string, payment: PaymentObject}>}
 */
const createPayment = data => {
    return postData({
        url: `${APIURL}/payments`,
        data
    })
}

/**
 * 
 * @param {PaymentObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, payment: PaymentObject}>}
 */
const updatePayment = (data, id) => {
    return postData({
        url: `${APIURL}/payments/${id}`,
        data
    })
}

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, payment: PaymentObject[]}>}
 */
const getPayments = (getAll = false) => {

    const obj = { url: `${APIURL}/payments` }

    if (getAll) obj.data = { expanded: 1 }

    return getData(obj)

}


/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, payment: PaymentObject}>}
 */
const getPayment = (id, includes = null) => {

    const obj = { url: `${APIURL}/payments/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}

/**
 * 
 * @returns  {Promise<{code: number, message: string, payments: PaymentObject}>}
 */
const getEPPayments = () => getData({ url: `${APIURL}/payments/event-product-payments` });


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deletePayment = id => fetchData({ url: `${APIURL}/payments/${id}`, method: "DELETE" })


export {
    createPayment,
    getPayment,
    getPayments,
    updatePayment,
    deletePayment,
    getEPPayments
}